<template>
    <v-app id="app">
      <v-navigation-drawer
        v-model="drawer"
        :clipped="$vuetify.breakpoint.lgAndUp"
        app
        v-if="logueado"
      >
        <v-list dense>
            <template v-if="!logueado" >
          <v-list-group>
            <v-list-item slot="activator">
              <v-list-item-content>
                <v-list-item-title>
                  Solicitud Servicios ICTS
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{name: 'solicitud'}">
              <v-list-item-action>
                <v-icon>question_answer</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                 Primera Solicitud 
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
             <v-list-item :to="{name: 'registrado'}">
              <v-list-item-action>
                <v-icon>person</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                 Usuario Registrado
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
           
          </v-list-group>
        </template>
          <template v-if="esUsuario || esIP" >
            <v-list-item :to="{name: 'solicitudesusuario'}">
              <v-list-item-action>
                <v-icon>home</v-icon>
              </v-list-item-action>
              <v-list-item-title>
                Inicio
              </v-list-item-title>
            </v-list-item>
          </template>
         
          <template v-if="esUsuario || esIP">
            <v-list-group>
              <v-list-item slot="activator">
                <v-icon>menu</v-icon>
                <v-list-item-content>                 
                  <v-list-item-title>                    
                    Servicios ICTS_RBD
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{name: 'colecciones'}">
              <v-list-item-action>
                <v-icon>collections</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                 Colecciones
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item >
            <v-list-item :to="{name: 'anillamiento'}">
              <v-list-item-action>
                <v-icon>circle</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                 Anillamiento
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
             <v-list-item :to="{name: 'rbd'}">
              <v-list-item-action>
                <v-icon>nature</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                 Acceso RBD/END
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
           <v-list-item :to="{name: 'apoyo'}">
              <v-list-item-action>
                <v-icon>hiking</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                 Apoyo técnico de campo
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
             <v-list-item :to="{name: 'icts'}">
              <v-list-item-action>
                <v-icon>data_exploration</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                 Acceso Datos de ICTS
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{name: 'tic'}">
              <v-list-item-action>
                <v-icon>sensors</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                Acceso infraestructura TIC
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          <!--   <v-list-item :to="{name: 'solicitudes'}">
              <v-list-item-action>
                <v-icon>done</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                 Todas las solicitudes
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item > -->
            </v-list-group>
          </template>
          <v-divider></v-divider>
          <template v-if="esUsuario || esIP">
            <v-list-group  expand color="indigo" :value="true" >
              <v-list-item slot="activator">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon>mdi-account</v-icon>
                    {{this.$store.state.usuario.apellidos}},  {{this.$store.state.usuario.nombre}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{name: 'solicitudesusuario'}" >
              <v-list-item-action>
                <v-icon size="20">task</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                Mis solicitudes
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item >
            <v-list-item :to="{name: 'misproyectos'}" >
              <v-list-item-action>
                <v-icon size="20">science</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                   Mis Proyectos
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item> 
           
            <v-list-item :to="{name: 'miperfil'}">
              <v-list-item-action>
                <v-icon>password</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Cambiar mi contraseña
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{name: 'proyectousuario'}" >
              <v-list-item-action>
                <v-icon size="20">add_circle</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                   Añadir Proyecto Nuevo
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{name: 'manual'}" >
              <v-list-item-action>
                <v-icon>menu_book</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                 Manual de usuario
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item >
           
            </v-list-group>
          </template>
       
          <template v-if="esAdministrador" >
           
         <v-list-group expand color="indigo" :value="true" >
           <v-list-item  slot="activator">           
                  
             <v-list-item-content>
               <v-list-item-title>
                <v-icon>admin_panel_settings</v-icon>
                 Administración
               </v-list-item-title>
             </v-list-item-content>
           </v-list-item>
            <v-list-item :to="{name: 'proyectos'}" active>
             <v-list-item-action>
               <v-icon>science</v-icon>
             </v-list-item-action>
             <v-list-item-content>
               <v-list-item-title>
                Proyectos
               </v-list-item-title>
             </v-list-item-content>
           </v-list-item>
            <v-list-item :to="{name: 'usuarios'}" active>
             <v-list-item-action>
               <v-icon>person</v-icon>
             </v-list-item-action>
             <v-list-item-content>
               <v-list-item-title>
                Usuarios
               </v-list-item-title>
             </v-list-item-content>
           </v-list-item>
            <v-list-item :to="{name: 'solicitudes'}" active>
             <v-list-item-action>
               <v-icon>task</v-icon>
             </v-list-item-action>
             <v-list-item-content>
               <v-list-item-title>
                Solicitudes
               </v-list-item-title>
             </v-list-item-content>
           </v-list-item>
         </v-list-group>
       </template>
       <template v-if="esServicios" >
        <v-list-group expand color="indigo" :value="true">
           <v-list-item slot="activator" >
             <v-list-item-content>
               <v-list-item-title>
                <v-icon>admin_panel_settings</v-icon>
                 Administración de Servicios
               </v-list-item-title>
             </v-list-item-content>
           </v-list-item>
           <v-list-item :to="{name: 'usuarios'}" >
             <v-list-item-action>
               <v-icon>person</v-icon>
             </v-list-item-action>
             <v-list-item-content>
               <v-list-item-title>
                Usuarios
               </v-list-item-title>
             </v-list-item-content>
           </v-list-item>
            <v-list-item :to="{name: 'solicitudes'}" >
             <v-list-item-action>
               <v-icon>task</v-icon>
             </v-list-item-action>
             <v-list-item-content>
               <v-list-item-title>
                Solicitudes
               </v-list-item-title>
             </v-list-item-content>
           </v-list-item>
          </v-list-group>
       
        </template>
        <template v-if="esAnillamiento" >
        <v-list-group expand color="indigo" :value="true">
           <v-list-item slot="activator" >
             <v-list-item-content>
               <v-list-item-title>
                <v-icon>admin_panel_settings</v-icon>
                 Administración de Servicios
               </v-list-item-title>
             </v-list-item-content>
           </v-list-item>
           <v-list-item :to="{name: 'usuarios'}" >
             <v-list-item-action>
               <v-icon>person</v-icon>
             </v-list-item-action>
             <v-list-item-content>
               <v-list-item-title>
                Usuarios
               </v-list-item-title>
             </v-list-item-content>
           </v-list-item>
            <v-list-item :to="{name: 'solicitudes'}" >
             <v-list-item-action>
               <v-icon>task</v-icon>
             </v-list-item-action>
             <v-list-item-content>
               <v-list-item-title>
                Solicitudes Datos
               </v-list-item-title>
             </v-list-item-content>
           </v-list-item>
           <v-list-item :to="{name: 'solicitudesanillas'}" >
             <v-list-item-action>
               <v-icon>task</v-icon>
             </v-list-item-action>
             <v-list-item-content>
               <v-list-item-title>
                Solicitudes Anillas
               </v-list-item-title>
             </v-list-item-content>
           </v-list-item>

          </v-list-group>
       
        </template>
        </v-list>
      </v-navigation-drawer>
  
      <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        app
        color="#1C564B"
        dark
      >
       
        <v-toolbar-title
          style="width: 300px"
          class="ml-0 pl-12"
        >
      
          <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
          <span class="hidden-sm-and-down">Servicios ICTS-RBD </span>
        </v-toolbar-title>
       
        <v-spacer></v-spacer>
      
       <v-flex  >
        <v-btn class="d-flex justify-right"   @click="salir()" icon v-if="logueado">  <v-icon>logout</v-icon>Salir {{this.$store.state.usuario.apellidos}},  {{this.$store.state.usuario.nombre}} -- {{ this.$store.state.rol }} </v-btn>
        <v-btn :to="{name: 'registrado'}" icon v-else><v-icon>apps</v-icon> Login </v-btn> 
       
      </v-flex>
        
        
      </v-app-bar>
      <v-content>
      <v-container
        fluid
        fill-height
      >
        <v-slide-y-transition mode="out-in">
          <router-view/>
        </v-slide-y-transition>
              </v-container>
    </v-content>
 
    <v-footer
      color="primary lighten-1"
      padless
    >
      <v-row
        justify="center"
        no-gutters
      >
      <!--   <v-btn
          v-for="link in links"
          :key="link"
          color="white"
          text
          rounded
          class="my-2"
        >
          {{ link }}
        </v-btn> -->
        <v-col
          class="primary  py-2 text-center white--text"
          cols="12"
        > 
        <v-img
            :src="require('./assets/logo_ICTS.png')"
            class="my-3"
            contain
            height="50"
          />      
          {{ new Date().getFullYear() }} — <strong>Servicios ICTS-RBD</strong>
          <v-spacer></v-spacer>
          <strong>coordinacion@ebd.csic.es</strong>
        </v-col>
      </v-row>
    </v-footer> 
  </v-app>
  </template>
  
  <script>

    export default {
      name: 'App',
      data () {
        return {
          drawer: true,
          roll:'',
          links: [
            'Home', 
            'About Us',
            'Contact Us',
          ],
        }
      },
       computed: {
              logueado() {
              return this.$store.state.usuario
              },             

    
              esAdministrador() {
              return this.$store.state.usuario && (this.$store.state.rol == 'Administrador')
              },   
              esServicios() {
              return this.$store.state.usuario && (this.$store.state.rol == 'Colecciones' ||  this.$store.state.rol == 'RBD/END' ||  this.$store.state.rol == 'Apoyo_Tecnico' ||  this.$store.state.rol == 'Datos_ICTS' ||  this.$store.state.rol == 'TIC' )
              },   
              esAnillamiento() {
              return this.$store.state.usuario && (this.$store.state.rol == 'Anillamiento')
              },   
              esUsuario() {
              return this.$store.state.usuario && this.$store.state.rol == 'Usuario'
              },
              esIP() {
              return this.$store.state.usuario && this.$store.state.rol == 'IP'
              },
             /*  esAnillamiento() {
              return this.$store.state.servicio  == 'anillamiento'
              }, */
             
         },
          created() {
           this.$store.dispatch("autoLogin");
       },
       methods: {
         salir(){   
            this.$store.dispatch("salir"); 
            }   
         }
       
    };
    </script>
    <<style>
    .ex2 {
      margin: 40px;
      
      
    }
    </style>