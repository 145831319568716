<template>
   <div class="box1">
   
      <v-row >
      

        <v-col class="mb-12">
          <h1 >
            APOYO TÉCNICO
          </h1>
          <br>
        
            <h2>Seguimiento en Doñana</h2>         
          
          <p >
                       
            </p>         
            <p >
            El Equipo de Seguimiento de Procesos y Recursos Naturales de la Estación Biológica de Doñana tiene encomendada, desde 2003, la realización del Programa de Seguimiento en el Espacio Natural Doñana, labor que lleva a cabo en colaboración con el equipo técnico del  Espacio Natural de Doñana (Junta de Andalucia, Consejería de Medio Ambiente). 
            Además presta apoyo a los proyectos de investigación poniendo a disposición de los mismos la experiencia y el conocimiento acumulados durante años de trabajo en Doñana (mas información en este enlace). Por último, realiza una labor formativa al recibir estudiantes en prácticas de diferentes niveles educativos, principalmente de la comarca de Doñana.
                                 
          </p>
           <p >
           Solicitud de apoyo a proyectos: <a href="https://docs.google.com/forms/d/e/1FAIpQLSfKx4gfZDiFW0r5uJKOFbUNpWBXDF7pQh5qSB-7VSQRPMHJzw/viewform" target="blank">Solicitud</a>   
            
          </p>
          
          <p >
            <a href="http://icts.ebd.csic.es/seguimiento-donana" target="blank">Consulta su página web</a>   
            
          </p>
        </v-col>    

      

      

      
      </v-row>
    
   </div>
  
</template>

<script>
  export default {
    name: 'Anillamiento',

    data: () => ({
     
     
     
    }),
  }
</script>
<style>
  .box1{
    position: absolute;
    top: 50px;
    border: 3px solid green;
    padding: 2%;
    width: 800px;    
    left: 30%;
    margin-left: -100px;
   }
</style>
