<template>
   <div class="box1">
   
     <v-row >
      

        <v-col class="mb-12">
          <h1 >
            ACCESO ICTS - RBD 
          </h1>
          <br>
        
            <h2>Solicitud </h2>         
          
          <p >
            Antes de acceder al portal de servicios debe cumplimentar un formulario de entrada: "Solicitud Servicios ICTS".            
            </p>         
            <p >
              Si es la primera vez que utiliza nuestros servicios debe de cumplimentar el formulario  "Primera Solicitud" 
            
            <br><br>            
                  <v-btn       
                    color="primary"
                    
                    class="mr-4"
                    @click="nuevasolicitud()"
                    >
                    Primera Solicitud
                    </v-btn>
          </p>
          <p >
             <v-divider></v-divider>           
             <br><br>
               
              Si ya accedió anteriormente a nuestro portal debe de autenticarse con su mail y contraseña.
             
                 <br><br>
                 
                <v-btn       
                    color="primary"
                    depressed
                    class="mr-4"
                    @click="registrado()"
                    >
                    Usuario Registrado
                    </v-btn>
          </p>
        </v-col>   
        
       
      </v-row>
      <v-spacer></v-spacer>
     
    
   </div>
  
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({
     
  
     
    }),
     methods: {
      nuevasolicitud(){
              let me=this;
              me.reset;
              me.$router.push({ name: 'solicitud' }).catch(error => {
      if (
        error.name !== 'NavigationDuplicated' &&
        !error.message.includes('Avoided redundant navigation to current location')
      ) {
        console.log(error)
      }});
            },
       registrado(){
              let me=this;
              me.reset;
              me.$router.push({ name: 'registrado' }).catch(error => {
      if (
        error.name !== 'NavigationDuplicated' &&
        !error.message.includes('Avoided redundant navigation to current location')
      ) {
        console.log(error)
      }});
            },     
   },  
  }
</script>
<style>
  .box1{
    position: absolute;
    top: 50px;
    border: 3px solid green;
    padding: 2%;
    width: 500px;    
    left: 30%;
    margin-left: -100px;
   }
</style>
