<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar text color="white">                
                   <v-btn @click="crearPDF()">
                    <v-icon
                     small
                    class="mr-2"                   
                    >
                    mdi-printer
                    </v-icon> 
                   </v-btn>     
                   <vue-excel-xlsx
                    :data="proyectos"
                    :columns="columns"
                    :file-name="'proyectos'"
                    :file-type="'xlsx'"
                    :sheet-name="'proyectos'"
                    >
                    <v-icon
                    color="green"
                     large
                    class="mr-2"                   
                    >
                    mdi-file-excel-box-outline
                    </v-icon> 
                    
                </vue-excel-xlsx> 
         
                <v-toolbar-title>Proyectos</v-toolbar-title>
                <v-divider
                class="mx-2"
                inset
                vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-text-field class="text-xs-center" v-model="search" append-icon="search" 
                label="Búsqueda" placeholder="Filtrar por proyecto, responsable, financiador." single-line hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark class="mb-2" v-on="on">Nuevo proyecto</v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                        </v-card-title>            
                        <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>                                
                               
                                <v-flex xs12 sm12 md12>
                                    <v-text-field v-model="nombreentidadproyecto" 
                                    :rules="nameRules"
                                    label="Título">                                        
                                    </v-text-field>
                                </v-flex>
                                  <v-flex xs12 sm12 md12>
                                    <v-text-field v-model="responsable"
                                    :rules="nameRules"
                                    label="Responsable">                                        
                                    </v-text-field>
                                </v-flex>     
                                <v-flex xs12 sm12 md12>
                                    <v-text-field
                                     v-model="email" 
                                     :rules="emailRules"  required
                                     label="Correo">                                        
                                    </v-text-field>
                                </v-flex>                   
                                <v-flex xs12 sm12 md12>
                                    <v-text-field v-model="financiador" 
                                    :rules="nameRules"
                                    label="Financiador">                                        
                                    </v-text-field>
                                </v-flex>  
                                 <v-flex xs12 sm12 md12>
                                    <v-text-field v-model="vigencia" 
                                    :rules="nameRules"
                                    label="Vigencia">                                        
                                    </v-text-field>
                                </v-flex>                                    
                                  <v-flex xs12 sm12 md12>
                                    <v-text-field v-model="resumen" label="Resumen">                                        
                                    </v-text-field>
                                </v-flex>    
                                  <v-flex xs12 sm12 md12>
                                    <v-text-field v-model="objetivos" label="Objetivos">                                        
                                    </v-text-field>
                                </v-flex>                         
                                <v-flex xs12 sm12 md12 v-show="valida">
                                    <div class="orange--text" v-for="v in validaMensaje" :key="v" v-text="v">

                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-container>
                        </v-card-text>            
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">Cerrar</v-btn>
                        <v-btn color="blue darken-1" text @click="guardar">Guardar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog
                        v-model="dialogM"
                        width="350"
                    >
                    
                
                        <v-card>
                        <v-card-title class="text-h8 green lighten-2">
                            Aviso
                        </v-card-title>
                
                        <v-card-text>
                            <v-flex xs12 sm12 md12 v-show="valida">
                                <div  v-for="v in validaMensaje" :key="v" v-text="v">

                                </div>
                            </v-flex>   
                        </v-card-text>
                
                        <v-divider></v-divider>
                
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                            color="primary"
                            
                            text
                            @click="closeS"
                            >
                            Cerrar
                            </v-btn>
                        </v-card-actions>
                        </v-card>
                    
                    </v-dialog>
                <v-dialog v-model="adModal" max-width="290">
                    <v-card>
                        <v-card-title class="headline" v-if="adAccion==1">
                            Activar Item
                        </v-card-title>
                        <v-card-title class="headline" v-if="adAccion==2">
                            Desactivar Item
                        </v-card-title>
                        <v-card-title class="headline" v-if="adAccion==3">
                            Aprobar Item
                        </v-card-title>
                        <v-card-text>
                            Estás a punto de <span v-if="adAccion==1">Activar </span>
                            <span v-if="adAccion==2">desactivar </span>
                            <span v-if="adAccion==3">Aprobar </span>
                            
                            el item {{adNombre}}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="activarDesactivarCerrar()" color="green darken-1" text="text">
                                Cancelar
                            </v-btn>
                            <v-btn v-if="adAccion==1" @click="activar()" color="orange darken-4" text="text">
                                Activar
                            </v-btn>
                            <v-btn v-if="adAccion==2" @click="desactivar()" color="orange darken-4" text="text">
                                Desactivar
                            </v-btn>
                            <v-btn v-if="adAccion==3" @click="aprobar()" color="orange darken-4" text="text">
                                aprobar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="proyectos"
                :search="search"
                class="elevation-1"
            >
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-for="item in items" :key="item._id"> 
                                                
                     
                <td>{{ item.nombreentidadproyecto }}</td>
                <td>{{ item.responsable }}</td>
                <td>{{ item.financiador }}</td>
                <td>{{ item.vigencia }}</td>
                
                
                
                <td>
                    <div v-if="item.estado == 1">
                        <span class="blue--text">Vigente</span>
                    </div>
                    <div v-if="item.estado == 0">
                        <span class="orange--text">No Vigente</span>
                    </div>
                    <div v-if="item.estado == 2">
                        <span class="red--text">Pendiente de aprobación</span>
                    </div>
                    <div v-if="item.estado == 3">
                        <span class="blue--text">Aprobado</span>
                    </div>
                   <!--  <div v-else>
                        <span class="red--text">Pendiente de aprobación</span>
                    </div> -->
                </td>
                <td> 
                    <v-tooltip slot="append" location="top"> 
                    <template v-slot:activator="{ on }">   
                    <v-icon
                    v-on="on"
                    small
                    class="mr-2"
                    large color="blue darken-2"
                    @click="editItem(item)"
                    >
                    mdi-pencil
                    </v-icon>
                </template>
                <span>Editar / Modificar proyecto</span>
                </v-tooltip>
                    <template v-if="item.estado==1">
                        <v-tooltip slot="append" location="top"> 
                    <template v-slot:activator="{ on }">   
                        <v-icon
                        v-on="on"
                        small
                        class="mr-2"
                        large color="orange darken-2"
                        @click="activarDesactivarMostrar(2,item)"
                        >
                        mdi-block-helper
                        </v-icon>
                    </template>
                <span>Cambiar proyecto a no vigente</span>
                </v-tooltip>
                    </template>
                    <template v-if="item.estado==0 || item.estado==3">
                        <v-tooltip slot="append" location="top"> 
                    <template v-slot:activator="{ on }">  
                        <v-icon
                        v-on="on"
                        small
                        class="mr-2"
                        large color="teal darken-2"
                        @click="activarDesactivarMostrar(1,item)"
                        >
                        mdi-check-bold
                        </v-icon>  
                    </template>
                <span>Cambiar proyecto a vigente</span>
                </v-tooltip> 
                     </template>   
                     <template v-if="item.estado==2">
                        <v-tooltip slot="append" location="top"> 
                    <template v-slot:activator="{ on }">  
                        <v-icon
                        v-on="on"
                        small
                        class="mr-2"
                        large color="teal darken-2"
                        @click="activarDesactivarMostrar(3,item)"
                        >
                        mdi-check-bold
                        </v-icon>   
                    </template>
                <span>Aprobar proyecto</span>
                </v-tooltip> 
                     </template> 
                    </td>    
                 </tr>

                  </tbody>                 
                </template>
                <template v-slot:no-data>
                <v-btn color="primary" @click="listar()">Resetear</v-btn>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
    import axios from 'axios'    
    import jsPDF from 'jspdf'
    import moment from 'moment'
    import autoTable from 'jspdf-autotable'
   
    export default {
        data(){
            return{
                dialog: false,
                search:'',
                proyectos:[],
                headers: [
                   
                    { text: 'Nombre',value: 'nombreentidadproyecto', sortable: true},
                    { text: 'Responsable',value: 'responsable', sortable: true},
                    { text: 'Financiador', value: 'financiador', sortable: true },
                    { text: 'Vigencia',value: 'vigencia', sortable: false},                                  
                    { text: 'Estado', value: 'estado', sortable: true },
                    { text: 'Opciones', value: 'opciones', sortable: false }             
                    
                ],
                columns : [
                {
                        label: "Fecha creacción",
                        field: "createdAt",
                        dataFormat: this.getHumanDateC

                    },
                    {
                        label: "Nombre",
                        field: "nombreentidadproyecto",
                    },
                    {
                        label: "Investigador Principal",
                        field: "responsable",
                        
                    },                  
                    {
                        label: "Financiador",
                        field: "financiador",
                    },
                    {
                        label: "Vigencia",
                        field: "vigencia",
                    },
                    {
                        label: "Estado",
                        field: "estado",
                        dataFormat: this.getEstado
                        }, 
                   
                
                ],
                editedIndex: -1,
                _id:'',
               
                nombreentidadproyecto:'',
                responsable:'',
                email:'',
                financiador:'',
                vigencia:'',
                resumen:'',
                vigencia:'',  
                objetivos:'',              
                valida:0,                
                validaMensaje:[],
                adModal:0,
                adAccion:0,
                verCantidad:0,
                adNombre:'',
                dialogM:0,
                emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                nameRules: [
                v => !!v || 'required field',
                
                ],
                adId:''
            }
        },
        computed: {
            formTitle () {
            return this.editedIndex === -1 ? 'Nuevo proyecto' : 'Editar proyecto'
            }
        },
        watch: {
            dialog (val) {
            val || this.close()
            }
        },
        created () {
            this.listar();
           
        },
        methods: {
            crearPDF(){
                var columns =[
                    {title: "Nombre", dataKey: "nombreentidadproyecto"},
                    {title: "Responsable", dataKey: "responsable"},
                    {title: "Financiador", dataKey: "financiador"},  
                    {title: "Vigencia", dataKey: "vigencia"}
                   
                ];
                var rows=[];

                this.proyectos.map(function(x){
                    rows.push(
                        {nombreentidadproyecto:x.nombreentidadproyecto,
                        responsable:x.responsable,
                        financiador:x.financiador,
                        vigencia:x.vigencia
                        }
                    );
                });                
                var doc = new jsPDF('l','pt');
                doc.autoTable(columns,rows,{
                    margin: {top: 60},
                    didDrawPage: function(data) {
                        doc.text("Lista de Proyectos", 40, 30);
                    }
                });

                doc.save('Proyectos.pdf');
            },
            getHumanDateC : function (date) {
                        return moment(date).local().format('DD/MM/YYYY');
            },
            getEstado : function (value) {
               switch (value) {
                case 0:
                    return 'No Vigente'
                   
                case 1:
                    return 'Vigente'
                   
                case 2:
                    return 'Pendiente de aprobación'
                    
                case 3:
                  return 'Aprobado'                   
              
                   
                default:
                 return 'N/A'
                    
                }
                       
            },
            listar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                          
                axios.get('proyecto/list',configuracion).then(function (response){
                    me.proyectos=response.data;                    
                }).catch(function(error){
                    console.log(error);
                });

            },
            limpiar(){
                this._id='';
                this.nombreentidadproyecto='';                
                this.responsable='';
                this.email='';
                this.vigencia='';
                this.financiador='';
                this.objetivos='';
                this.resumen='';
                this.valida=0;
                this.dialogM=0;
                this.validaMensaje=[];
                this.editedIndex=-1;
            },
            validar(){
                this.valida=0;
                this.validaMensaje=[];
                             
                if(this.nombreentidadproyecto.length<1){
                    this.validaMensaje.push('Debe de indicar un nombre para el proyecto.');
                }
                if(this.responsable.length<1){
                    this.validaMensaje.push('Debe de indicar un responsable.');
                }
                if(this.email.length<1 || this.email.length>50){
                    this.validaMensaje.push('El email del usuario debe tener entre 1-50 caracteres.');
                
                }
                if(this.vigencia.length<1){
                    this.validaMensaje.push('Debe de indicar la vigencia del proyecto.');
                }
                 if(this.financiador.length<1){
                    this.validaMensaje.push('Debe de indicar un financiador del proyecto.');
                }
                return this.valida;
            },
            guardar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                
                if (this.validar()){
                    return;
                }
                if (this.editedIndex >-1){
                    //Código para editar
                    axios.put('proyecto/update',
                    {
                        '_id':this._id,
                        'nombreentidadproyecto':this.nombreentidadproyecto,
                        'responsable':this.responsable,
                        'email':this.email,
                        'financiador':this.financiador,                                                
                        'vigencia':this.vigencia,
                        'resumen':this.resumen,
                        'objetivos':this.objetivos

                    },configuracion)
                    .then(function(response){
                      
                       /*  me.limpiar();
                        me.close(); */
                        //me.listar();
                        me.validaMensaje=['El proyecto se modifcó correctamente.'];
                        me.dialogM=1;
                        me.valida=1;
                        me.listar();
                    })
                    .catch(function(error){
                        console.log(error);
                    });
                }else{
                    //Código para guardar
                    axios.post('proyecto/add',
                    {
                        'nombreentidadproyecto':this.nombreentidadproyecto,
                        'responsable':this.responsable,
                        'email':this.email,
                        'financiador':this.financiador,                                                
                        'vigencia':this.vigencia,
                        'resumen':this.resumen,
                        'objetivos':this.objetivos
                    },configuracion)
                    .then(function(response){
                        me.listar();
                        me.sendEmailAvisoP();
                        me.validaMensaje=['El proyecto se añadió correctamente. '];
                        me.valida=1;
                        me.dialogM=1;
                      /*   me.limpiar();
                        me.close(); */
                        
                    })
                    .catch(function(error){
                        me.validaMensaje.push('Ya existe un proyecto con ese mismo nombre. ');
                       
                        me.valida=1;
                        return me.valida;
                        console.log(error);
                    });
                }
            },
            editItem (item) {
                this._id=item._id;
                this.nombreentidadproyecto=item.nombreentidadproyecto;                
                this.responsable=item.responsable;
                this.email=item.email;
                this.financiador=item.financiador;
                this.vigencia=item.vigencia;
                this.resumen=item.resumen;
                this.objetivos=item.objetivos;               
                this.dialog = true;
                this.editedIndex=1;
            },
            activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adNombre=item.nombreentidadproyecto;
                this.adId=item._id;
                if (accion==1){
                    this.adAccion=1;
                } else if(accion==2){
                    this.adAccion=2;
                } else if(accion==3){
                    this.adAccion=3;
                } else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
            },
            activar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
               
                axios.put('proyecto/activate',{'_id':this.adId})
                    .then(function(response){
                        me.adModal=0;
                        me.adAccion=0;
                        me.adNombre='';
                        me.adId='';
                        me.listar();
                    },configuracion)
                    .catch(function(error){
                        console.log(error);
                    });
            },
            sendEmailAvisoP(){
                
                  
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};

                         
                axios.post('proyecto/sendmailP',{
                    
                    'nombreentidadproyecto':this.nombreentidadproyecto,                        
                    'responsable':this.responsable,
                    'email':this.email,
                    'financiador':this.financiador,                   
                    'vigencia':this.vigencia,                 
                    'quien':  this.$store.state.usuario.apellidos + ', ' +  this.$store.state.usuario.nombre 
                     
                  },configuracion                
                  ).then(function (response){
                         
                  }).catch(function(error){
                     
                      console.log(error);
                  });
                
                
              },
            aprobar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
               
                axios.put('proyecto/aprobar',{'_id':this.adId})
                    .then(function(response){
                        me.adModal=0;
                        me.adAccion=0;
                        me.adNombre='';
                        me.adId='';
                        me.listar();
                    },configuracion)
                    .catch(function(error){
                        console.log(error);
                    });
            },
            desactivar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                
                axios.put('proyecto/deactivate',{'_id':this.adId})
                    .then(function(response){
                        me.adModal=0;
                        me.adAccion=0;
                        me.adNombre='';
                        me.adId='';
                        me.listar();
                    },configuracion)
                    .catch(function(error){
                        console.log(error);
                    });
            },
            closeS () {
                
               
                // this.valid=false;
                 this.dialogM=0;
                 this.dialog = false;
                
             },
            close () {
                this.limpiar();
                this.dialog = false;
            }
        }
    }
</script>
