<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar text color="white">                
                   <v-btn @click="crearPDF()">
                    <v-icon
                     small
                    class="mr-2"                   
                    >
                    mdi-printer
                    </v-icon> 
                   </v-btn>              
                <v-toolbar-title>Usuarios</v-toolbar-title>
                <v-divider
                class="mx-2"
                inset
                vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-text-field class="text-xs-center" v-model="search" append-icon="search" 
                label="Búsqueda" single-line hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="mb-2"  @click="mostrarNuevo()" ><v-icon>mdi-account-plus</v-icon>Nuevo usuario</v-btn>
                <v-dialog v-model="dialog" max-width="700px">
                    <v-form
      
                        ref="form"
                        v-model="valid"
                        value
                        >                 
                    
                    <v-card>
                        <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                        </v-card-title>            
                        <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>                                
                               
                                <v-flex xs12 sm6 md6>
                                    <v-text-field 
                                    v-model="apellidos" 
                                    prepend-icon="mdi-account"
                                    :rules="nameRules"
                                    label="Apellidos *"
                                    required                                      
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md6>
                                    <v-text-field  v-model="nombre" 
                                    :counter="100"
                                    prepend-icon="mdi-account"
                                    :rules="nameRules"
                                    label="Nombre *"
                                    required
                                >                                        
                                    </v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md6>
                                    <v-text-field  v-model="num_documento"
                                        prepend-icon="mdi-account-card"
                                        :rules="customRules"
                                        required
                                        label="Número Documento *">                                        
                                    </v-text-field>
                                </v-flex>
                                 <v-flex xs12 sm6 md6 v-if="editedIndex === -1">
                                    <v-select v-if="esAdministrador==1" v-model="rol"
                                    prepend-icon="mdi-account"
                                    :rules="rolrules"
                                    required
                                    :items="roless" label="Rol">
                                    </v-select>
                                </v-flex>                      
                                <v-flex xs12 sm6 md6>
                                    <v-text-field  v-model="email"
                                    prepend-icon="mdi-mail"
                                    :rules="emailRules"  required
                                    label="Correo electrónico *">                                        
                                    </v-text-field>
                                </v-flex>  
                                 <v-flex xs12 sm6 md6>
                                <v-text-field  label="Contraseña *" 
                                    v-model="password" 
                                    prepend-icon="mdi-lock"
                                    :rules="passwordRules" required
                                    type="password" >                                        
                                </v-text-field>
                                 </v-flex>
                                 <v-flex xs12 sm12 md12>
                                 <v-text-field 
                                    v-model="actividad"       
                                    :rules="nameRules" required   
                                    label="Actividad * "
                                   
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm12 md12>
                                <v-text-field 
                                    v-model="centro_procedencia" 
                                    :counter="100"                               
                                    :rules="nameRules" required   
                                    label="Centro de Procedencia *"
                                    
                                ></v-text-field> 
                            </v-flex>                               
                                 <v-flex xs12 sm12 md12>
                                     <v-autocomplete  
                                        :items="proyectos"                         
                                        v-model="proyecto" 
                                        dense
                                        chips
                                        clearable
                                        label="Añada un Proyecto: Escriba parte del nombre del Proyecto o IP"                        
                                        @change="listarProyecto(proyecto)" 
                                        solo                                         
                                        >
                                     </v-autocomplete >
                                </v-flex>                       
                              
                           <v-flex xs12 sm12 md12 lg12 x12>
                            <v-flex xs12 sm12 md12 v-show="valida">
                                    <div class="orange--text" v-for="v in validaMensaje" :key="v" v-text="v">

                                    </div>
                                </v-flex>
                            </v-flex>
                            <v-flex xs12 sm12 md12 lg12 x12>
                            <v-data-table 
                                :headers="cabeceraDetalles"
                                :items="detalles"
                                disable-pagination
                                hide-default-footer
                                class="elevation-1"
                            >
                              
                                <template v-slot:body="{ items }" >
                                    <tbody>
                                        <tr v-for="item in items" :key="item.nombreentidadproyecto"> 
                                            <td>
                                           <v-icon
                                            small
                                            class="mr-2"
                                            @click="eliminarDetalle(detalles,item)"
                                            >
                                            mdi-delete
                                            </v-icon>
                                        </td>
                                            <td class="text-xs-center">{{ item.nombreentidadproyecto }}</td>
                                            
                                        </tr>

                                   </tbody> 
                              
                                </template>
                                <template slot="no-data">
                                    <h3>No hay proyectos agregados al detalle.</h3>
                                </template>
                                               
                           
                            </v-data-table>
                            </v-flex>
                            </v-layout>
                        </v-container>
                        </v-card-text>            
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">Cerrar</v-btn>
                        <v-btn  :disabled="!valid"
                          color="success"
                          class="mr-4"                                                 
                          @click="guardar()">Guardar</v-btn>
                        </v-card-actions>
                    </v-card>
                    </v-form>
                </v-dialog>
                <v-dialog v-model="dialogRol"  width="650">
                    <v-card>
                    <v-card-title class="text-h8 green lighten-2">
                        Asignar roles
                    </v-card-title>
                    <v-card-text>
                       
                        <v-container grid-list-md>
                            <v-layout wrap>                                
                               
                                <v-flex xs12 sm6 md6>
                                    <v-text-field 
                                    v-model="apellidos" 
                                    readonly
                                    prepend-icon="mdi-account"
                                    label="Apellidos"
                                                                       
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md6>
                                    <v-text-field  v-model="nombre" 
                                    :counter="100"
                                    readonly
                                    prepend-icon="mdi-account"                                    
                                    label="Nombre"
                                   
                                >                                        
                                    </v-text-field>
                                </v-flex>
                                 <v-container fluid v-if="esAdministrador==1" :rules="[v => !!v || 'Item is required']" required>
                                  
                                   <!--  <v-flex xs12 sm6 md6>  
                                       
                                    <v-checkbox  input-value="1"    
                                   
                                        v-for="item in roles"                                             
                                                                     
                                            :key="item.roles"                                                               
                                            :label="`${item.rol}`"
                                            :value="item.roles"
                                            readonly
                                        ></v-checkbox>
                                    </v-flex>   -->
                                    <v-flex xs12 sm6 md6>
                                        
                                    <v-checkbox v-model="selected"
                                        v-for="item in aroles"                                                                                                                     
                                            :key="item"                                                               
                                            :label="`${item}`"
                                            :value="item"
                                            
                                        ></v-checkbox> 
                                    </v-flex>       
                                 
                                </v-container>
                            
                             
                           
                         </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                        color="primary"
                        
                        text
                        @click="close()"
                        >
                         Cerrar
                        </v-btn>
                        <v-btn
                        color="primary"
                        
                        text
                        @click="asignarroles()"
                        >
                         Asignar Roles
                        </v-btn>
                    </v-card-actions>
                </v-card>    
                </v-dialog>
                <v-dialog v-model="dialogM"  width="350" >
                                   
        
                    <v-card>
                    <v-card-title class="text-h8 green lighten-2">
                        Aviso 
                    </v-card-title>
            
                    <v-card-text>
                        <v-flex xs12 sm12 md12 v-show="valida">
                            <div  v-for="v in validaMensaje" :key="v" v-text="v">

                            </div>
                        </v-flex>   
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                        color="primary"
                        
                        text
                        @click="close()"
                        >
                         Cerrar
                        </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
                <v-dialog v-model="adModal" max-width="290">
                    <v-card>
                        <v-card-title class="headline" v-if="adAccion==1">
                            Activar Item
                        </v-card-title>
                        <v-card-title class="headline" v-if="adAccion==2">
                            Desactivar Item
                        </v-card-title>
                        <v-card-text>
                            Estás a punto de <span v-if="adAccion==1">activar </span>
                            <span v-if="adAccion==2">desactivar </span> el item {{adNombre}}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="activarDesactivarCerrar()" color="green darken-1" text="text">
                                Cancelar
                            </v-btn>
                            <v-btn v-if="adAccion==1" @click="activar()" color="orange darken-4" text="text">
                                Activar
                            </v-btn>
                            <v-btn v-if="adAccion==2" @click="desactivar()" color="orange darken-4" text="text">
                                Desactivar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="usuarios"
                :search="search"
                class="elevation-1"
            >
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-for="item in items" :key="item._id"> 
                                                
                     
                <td>{{ item.apellidos }}</td>
                <td>{{ item.nombre }}</td>
                <td>{{ item.roles[0].rol }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.actividad }}</td>
                <td>{{ item.centro_procedencia }}</td>
                
                <td>
                    <div v-if="item.estado == 1">
                        <span class="blue--text">Activo</span>
                    </div>
                    <div v-else>
                        <span class="red--text">Inactivo</span>
                    </div>
                </td>
                <td> 
                    <v-tooltip slot="append" location="top"> 
                    <template v-slot:activator="{ on }">   
                    <v-icon
                    v-on="on"
                    small
                    class="mr-2"
                    large color="blue darken-2"
                    @click="editItem(item)"
                    >
                    mdi-pencil
                    </v-icon>
                </template>
                <span>Editar / Modificar usuario</span>
                </v-tooltip>
                <template v-if="esAdministrador==1" >
                <v-tooltip slot="append" location="top"> 
                    <template v-slot:activator="{ on }">   
                     <v-icon 
                    v-on="on"
                    small
                    class="mr-2"
                    large color="orange darken-2"
                    @click="editItemRol(item)"
                    >
                    mdi-account-key
                    </v-icon>
                </template>
                <span>Asignar roles al usuario</span>
                </v-tooltip>
            </template>
                    <template v-if="item.estado">
                        <v-tooltip slot="append" location="top"> 
                    <template v-slot:activator="{ on }">   
                        <v-icon
                        v-on="on"
                        small
                        class="mr-2"
                        large color="teal darken-2"
                        @click="activarDesactivarMostrar(2,item)"
                        >
                        mdi-block-helper
                        </v-icon>
                    </template>
                <span>Desactivar al usuario</span>
                </v-tooltip>
                    </template>
                    <template v-else>
                        <v-tooltip slot="append" location="top"> 
                    <template v-slot:activator="{ on }">   
                        <v-icon
                        v-on="on"
                        small
                        class="mr-2"
                        large color="teal darken-2"
                        @click="activarDesactivarMostrar(1,item)"
                        >
                        mdi-check-bold
                        </v-icon> 
                    </template>
                <span>Activar al usuario</span>
                </v-tooltip>
             
                     </template>   
                    </td>    
                 </tr>

                  </tbody>                 
                </template>
                <template v-slot:no-data>
                <v-btn color="primary" @click="listar()">Resetear</v-btn>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
    import axios from 'axios'    
    import jsPDF from 'jspdf'
    import autoTable from 'jspdf-autotable'
    
    export default {
        data(){
            return{
                dialog: false,
                search:'',
                usuarios:[],
                headers: [
                   
                    { text: 'Apellidos',value: 'apellidos', sortable: true},
                    { text: 'Nombre',value: 'nombre', sortable: true},
                    { text: 'Rol', value: 'roles[0].rol' },
                    { text: 'Mail',value: 'email'},  
                    { text: 'Actividad',value: 'actividad'},  
                    { text: 'Centro de Procedencia',value: 'centro_procedencia'},                                
                    { text: 'Estado', value: 'estado'},
                    { text: 'Opciones', value: 'opciones'}             
                    
                ],
                editedIndex: -1,
                _id:'',
                cabeceraDetalles:[
                    { text: 'Proyecto', value: 'nombreentidadproyecto', sortable: false }
                                    
                ], 
              
                apellidos:'',
                nombre:'',
                num_documento:'',
                actividad:'',
                centro_procedencia:'',
                rol:'',
                roldef:'',
                roless: ['Administrador','IP','Anillamiento','Colecciones','RBD/END','Apoyo_Tecnico','Datos_ICTS','TIC'],
                aroles:[],
                tipou: ['Administración', 'Investigador', 'Otros'], 
                email:'',
                tipouso:'',
                password:'',   
                cabeceraDetalles:[
                    { text: 'Proyectos', value: 'nombreentidadproyecto', sortable: false }                    
                ], 
                detalles:[],  
                _idproyecto:'',
                nombreproyecto:'',              
                valida:0,  
                valid: true,              
                validaMensaje:[],
                detalles:[],
                roles:[],
                selected:[],
                selectedr:[],
                proyectos:[],               
                proyecto:'', 
                adModal:0,
                esAdministrador:0,
                esServicios:0,
                verActividad:0,
                dialog:0,
                dialogRol:0,
                dialogM:0, 
                valid:true,
                adAccion:0,
                verCantidad:0,
                adNombre:'',
                nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 100) || 'Name must be less than 100 characters',
                ],
                rolrules: [ v => !!v || 'rol is required'],
                emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                passwordRules:[
                    v => !!v || "La contraseña es requerida",
                    v => v.length >= 8  || "La contraseña debe de contener al menos 8 caracteres"],
                    customRules: [
                v => !!v || 'número de documento es requerido',
                v => (v.length >8 && v.length <= 12 ) || 'el numero de documento tiene que ser válido',
                ],
                adId:''
            }
        },
        computed: {
            formTitle () {
            return this.editedIndex === -1 ? 'Nuevo usuario' : 'Editar usuario'
            }
        },
        watch: {
            dialog (val) {
            val || this.close()
            }
        },
        created () {
            /* this.listar();
            this.selectProyectos();
 */
            this.roldef=this.$store.state.rol; 
           // this.roldef=this.$store.state.usuario.rol;
            this.id=this.$store.state.usuario._id; 
            console.log(this.roldef);
            if (this.roldef=="Administrador"){
                this.esAdministrador=1;
                this.listar();
                //this.selectProyectos();
            } else {
                 this.esServicios=1;
                 this.listarrol();                               
                 //this.selectProyectos();
            }     
          this.selectProyectos();  
          console.log(this.esAdministrador);
          console.log(this.esServicios);
        },
        methods: {
           
            validate () {
            this.$refs.form.validate()
            },
            reset () {
                this.verNuevo=0;
                this.$refs.form.reset()
                },
            resetValidation () {
                this.$refs.form.resetValidation()
                },
          
            crearPDF(){
                var columns =[
                    {title: "Apellidos", dataKey: "apellidos"},
                    {title: "Nombre", dataKey: "nombre"},
                    {title: "Rol", dataKey: "rol"},  
                    {title: "Mail", dataKey: "email"}
                   
                ];
                var rows=[];

                this.usuarios.map(function(x){
                    rows.push(
                        {apellidos:x.apellidos,
                        nombre:x.nombre,
                        rol:x.rol,
                        email:x.email}
                    );
                });                
                var doc = new jsPDF('p','pt');
                doc.autoTable(columns,rows,{
                    margin: {top: 60},
                    didDrawPage: function(data) {
                        doc.text("Lista de Usuarios", 40, 30);
                    }
                });

                doc.save('Usuarios.pdf');
            },
            selectProyectos(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};

                let proyectosArray=[];
                
                           
                axios.get('proyecto/listactivos',configuracion).then(function (response){
                    proyectosArray=response.data;
                    proyectosArray.map(function(x){
                        me.proyectos.push({text:x.nombreentidadproyecto.substr(0,80) + ' ... / ' + ' - ' + x.responsable, value:x._id});
                        
                    });
                }).catch(function(error){
                    console.log(error);
                });
            },
           
                agregarDetalleProyecto(proyecto){
                let me=this;
               
                
                 this.detalles.push(
                        {
                            _idproyecto:proyecto._id,
                            nombreproyecto:proyecto.nombreentidadproyecto,
                            
                        }); 
                            
              
            },
            eliminarDetalle(arr,item){
                let i=arr.indexOf(item);
                if (i!= -1){
                    arr.splice(i,1);
                }
            },     
            mostrarNuevo() {    
                    
                                   
                    this.dialog=1; 
                    
                 
                          
                                  
                },
     
            listar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
 
                          
                axios.get('usuarios/list',configuracion).then(function (response){
                    me.usuarios=response.data;   
                    console.log(me.usuarios);                 
                }).catch(function(error){
                    console.log(error);
                });

            },
            listarrol(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};

                console.log(me.roldef);
                          
                axios.get('usuarios/listrol?valor='+me.roldef,configuracion).then(function (response){
                    me.usuarios=response.data;                    
                }).catch(function(error){
                    console.log(error);
                });

            },
            listarProyecto(id){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};

                            
                axios.get('proyecto/query?_id='+id,configuracion).then(function (response){
                   
                   // me.nombre = response.data.nombre;  
                   // me.proyecto=response.data;   
                     me.detalles.push(
                        {
                            _idproyecto:response.data._id,
                            nombreentidadproyecto:response.data.nombreentidadproyecto,
                            
                            
                        });                                    
                         
                }).catch(function(error){
                    console.log(error);
                });
            },
        
            limpiar(){
                this._id='';
                this.apellidos='';                
                this.nombre='';
                this.num_documento=''; 
                this.roles=[];              
                this.email='';
                this.password='';
                this.tipouso='';                
                this.valida=0;
                this.dialog=0;
                this.detalles=[];
                this.proyecto='';
                this.aroles=[];  
                this.selected=[];             
                // this.proyectos=[];
                this.validaMensaje=[];
                this.actividad='';
                this.verActividad=0;
                this.centro_procedencia='';
                this.dialogM=0;
                this.editedIndex=-1;
            },
            validar(){
                this.valida=0;
                this.validaMensaje=[];
                             
                if(this.apellidos.length<1){
                    this.validaMensaje.push('Debe de indicar los apellidos.');
                }
                if(this.nombre.length<1){
                    this.validaMensaje.push('Debe de indicar un nombre.');
                }
                if(this.rol.length<1){
                    this.validaMensaje.push('Debe de indicar el rol.');
                }
                if(this.password.length<8){
                        this.validaMensaje.push('Debe de indicar una contraseña con 8 caracteres como mínimo y confirmarla.');
                    }
                if(this.num_documento.length<8 || this.num_documento.length>20){
                     this.validaMensaje.push('El documento no debe tener más de 20 caracteres ni menos de 8.');                              
                    }
                 if(this.email.length<1){
                    this.validaMensaje.push('Debe de indicar un correo electrónico.');
                }
                console.log(this.rol);
                return this.valida;
            },
            buscardni(){                    
                     
                     let me=this;
                     let header={"Token" : this.$store.state.token};
                     let configuracion= {headers : header};
                     
                      me.valida=0;
                      me.validaMensaje=[];               
                       
                               
                     axios.get('usuarios/list?valor='+me.num_documento,configuracion).then(function (response){
                        
                        console.log(response.data);  
                       
                         
                          if (response.data.length == 0) {
                            me.validaMensaje=[''];
                            me.valida=0;
                         } else { me.validaMensaje=['Hay un usuario registrado con el mismo dni. Introduzca otro dni.'];
                          me.valida=1;
                        // me.dialogM=1;
                       } 
                            
                                 
                     }).catch(function(error){
                         console.log(error);
                     });
                     
                   
                   return me.valida;
                   },   

            guardar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};

                
                if (this.validar()){
                    return;
                }
                if (me.buscardni()) {
                   //me.limpiar();
                   return;
               
                 }  
                if (!me.esAdministrador) {
                        this.rol=this.roldef;

                    }
                if (this.editedIndex >-1){
                    //Código para editar
                   
                    
                    axios.put('usuarios/update',
                    {
                        '_id':this._id,
                        'apellidos':this.apellidos,
                        'nombre':this.nombre,
                        'num_documento':this.num_documento,
                        'roles':this.roles,                                                
                        'email':this.email,
                        'password':this.password,
                        'actividad':this.actividad,
                        'centro_procedencia':this.centro_procedencia,
                       //'tipouso':"Otros",
                        'detalles':this.detalles

                    },configuracion)
                    .then(function(response){
                        
                        if (me.roldef=="Administrador"){
                        // me.limpiar();
                        // me.close();                       
                       
                         me.validaMensaje=['El registró se modificó correctamente.'];
                         me.valida=1;
                         me.dialogM=1;
                         me.listar();
                        } else {
                           // me.limpiar();
                           // me.close();
                            me.validaMensaje=['El registró se modificó correctamente.'];
                            me.valida=1;
                            me.dialogM=1;
                            me.listarrol(); 

                        }
                    })
                    .catch(function(error){
                       
                        
                        me.valida=1;
                       
                        console.log(error);
                    });
                }else{
                  // usuario nuevo se añade por defecto rol de usuario y el elegido.
                  this.roles.push({
                           rol:this.rol,                          
                      }); 
                    this.roles.push({
                           rol:'Usuario',
                          
                      });
                   

                    axios.post('usuarios/add',
                    {
                        'apellidos':this.apellidos,
                        'nombre':this.nombre,
                        'num_documento':this.num_documento,
                        'roles':this.roles,                                                
                        'email':this.email,
                        'password':this.password,  
                        'actividad':this.actividad,
                        'centro_procedencia':this.centro_procedencia,                     
                        'detalles':this.detalles
                       
                    },configuracion)
                    .then(function(response){
                      if (me.roldef=="Administrador"){
                        //me.limpiar();
                       // me.close();
                       
                       
                       me.listar();
                       me.validaMensaje=[''];       
                       me.validaMensaje=['Se completó el registro.'];                       
                       me.valida=1;
                       me.dialogM=1;
                      
                        
                    } else {
                           // me.limpiar();
                           // me.close();
                           me.validaMensaje=['']; 
                           me.validaMensaje=['Se completó el registro.'];
                           me.valida=1;
                           me.dialogM=1;
                           me.listarrol(); 
                            
                        }
                    })
                    .catch(function(error){
                       
                        
                       
                        return me.valida;
                        console.log(error);
                    });
                 }                              
            
                           
                   
            },

            editItem (item) {
                this._id=item._id;
                this.apellidos=item.apellidos;                
                this.nombre=item.nombre;
                this.num_documento=item.num_documento;
                this.roles=item.roles;
                this.email=item.email;
                this.password=item.password;
               // this.tipouso=item.tipouso;  
                this.detalles=item.detalles;  
                  if (item.actividad)  {     
                  this.actividad=item.actividad;  
                //  this.verActividad=1;
                   } else {
                     this.actividad='';
                  }              
                  
                  if (item.centro_procedencia) {
                  this.centro_procedencia=item.centro_procedencia;
                 // this.verActividad=1;
                }
                  else {
                  this.centro_procedencia='';
                  }    
                
                this.dialog = true;
                this.editedIndex=1;
            },
            editItemRol (item) {
                this._id=item._id;
                this.apellidos=item.apellidos;                
                this.nombre=item.nombre;
                this.num_documento=item.num_documento;
                this.roles=item.roles;
                console.log(this.roless);
                console.log(this.roles);
                this.restar();
                console.log(this.aroles);
                this.email=item.email;
                this.password=item.password;
               // this.tipouso=item.tipouso;  
                this.detalles=item.detalles; 
                 if (item.actividad)  {     
                  this.actividad=item.actividad;  
                  this.verActividad=1;
                   } else {
                     this.actividad='';
                  }              
                  
                  if (item.centro_procedencia) {
                  this.centro_procedencia=item.centro_procedencia;
                  this.verActividad=1;

                }
                  else {
                  this.centro_procedencia='';
                  }    
                            
                this.dialogRol = true;
                this.editedIndex=1;
               
            },
            restar(){
                var pos = 0
                    for(var j=0;j<this.roless.length;j++){
                        var esta = false
                        for(var k=0;k<this.roles.length;k++){
                            if(this.roles[k].rol==this.roless[j]){
                                esta = true
                            }
                        }
                        if (esta==false){
                            this.aroles[pos]=this.roless[j];
                            pos = pos+1
                        }
                    }
            },
            asignarroles() {
               let me=this;
               let header={"Token" : this.$store.state.token};
               let configuracion= {headers : header};

                for(var k=0;k<this.selected.length;k++){
                    this.roles.push(
                        {
                            
                            rol:this.selected[k],
                            
                        });  
                          
                  }
              // guardar roles
              axios.put('usuarios/update',
                    {
                        '_id':this._id,
                        'apellidos':this.apellidos,
                        'nombre':this.nombre,
                        'num_documento':this.num_documento,
                        'roles':this.roles,                                                
                        'email':this.email,
                        'password':this.password,
                        'actividad':this.actividad,
                        'centro_procedencia':this.centro_procedencia,                    
                        'detalles':this.detalles

                    },configuracion)
                    .then(function(response){
                           me.validaMensaje=['']; 
                           me.validaMensaje=['Se completó el registro.'];
                           me.valida=1;
                           me.dialogM=1; 
                           me.listar();   
                        
                       
                       
                    })
                    .catch(function(error){
                       
                        
                        me.valida=1;
                       
                        console.log(error);
                    });
            },
           
            activarDesactivarMostrar(accion,item){
                this.adModal=1;            
                this.adNombre=item.apellidos;
                this.adId=item._id;
                if (accion==1){
                    this.adAccion=1;
                } else if(accion==2){
                    this.adAccion=2;
                } else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
            },
            activar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};

               
                axios.put('usuarios/activate',{'_id':this.adId})
                    .then(function(response){
                        me.adModal=0;
                        me.adAccion=0;
                        me.adNombre='';
                        me.adId='';
                       if (me.roldef=="Administrador"){
                        me.listar();}else{
                             me.listarrol();  
                        }
                       
                    },configuracion)
                    .catch(function(error){
                        console.log(error);
                    });
            },
            desactivar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};

                
                axios.put('usuarios/deactivate',{'_id':this.adId},configuracion)
                    .then(function(response){
                        me.adModal=0;
                        me.adAccion=0;
                        me.adNombre='';
                        me.adId='';
                         if (me.roldef=="Administrador"){
                        me.listar();}else{
                             me.listarrol();  
                        }
                    })
                    .catch(function(error){
                        console.log(error);
                    });
            },
            close () {
                 this.limpiar();
                this.dialog = 0;
                this.dialogM=0;
                this.dialogRol=0;
                this.actividad='';
                this.verActividad=0;
                this.centro_procedencia='';
            }
        }
    }
</script>
