<template>

    <v-layout align-start>
      
        <v-flex>
            <v-toolbar text color="white">                
                   
                   
                <v-toolbar-title> <v-alert outlined small type="info">
                Pulse en nuevo proyecto para solicitar la incorporación de un nuevo proyecto al sistema.</v-alert></v-toolbar-title>
                <v-divider
                class="mx-2"
                inset
                vertical
                ></v-divider>
               
                        
                <v-btn color="primary"   class="mb-2"  @click="mostraNuevo">Nuevo Proyecto</v-btn>
                <v-spacer></v-spacer>  
               
                                    
                <v-dialog v-model="adModalNuevo" max-width="500px">
                   
                 
                    <v-card>
                        <v-card-title>
                        <span class="headline">Añadir Proyecto Nuevo</span>
                        </v-card-title>            
                        <v-card-text>
                            <v-container grid-list-md>
                           <v-layout wrap>                                
                              
                               <v-flex xs12 sm12 md12>
                                   <v-text-field v-model="nombreentidadproyecto" label="Título *">                                        
                                   </v-text-field>
                               </v-flex>
                                 <v-flex xs12 sm12 md12>
                                   <v-text-field v-model="responsable" label="Responsable - Investigador Principal *">                                        
                                   </v-text-field>
                               </v-flex>   
                               <v-flex xs12 sm12 md12>
                                    <v-text-field
                                     v-model="emailip" 
                                     :rules="emailRules"  required
                                     label="Correo del IP *">                                        
                                    </v-text-field>
                                </v-flex>                      
                               <v-flex xs12 sm12 md12>
                                   <v-text-field v-model="financiador" label="Financiador *">                                        
                                   </v-text-field>
                               </v-flex>  
                                <v-flex xs12 sm12 md12>
                                   <v-text-field v-model="vigencia" label="Vigencia *">                                        
                                   </v-text-field>
                               </v-flex>                                    
                                 <v-flex xs12 sm12 md12>
                                   <v-text-field v-model="resumen" label="Resumen">                                        
                                   </v-text-field>
                               </v-flex>    
                                 <v-flex xs12 sm12 md12>
                                   <v-text-field v-model="objetivos" label="Objetivos">                                        
                                   </v-text-field>
                               </v-flex>                         
                               <v-flex xs12 sm12 md12 v-show="valida">
                                   <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">

                                   </div>
                               </v-flex>
                           <!--    <v-flex xs12 sm12 md12 v-show="validaP">
                               <div class="green--text" v-for="v in validaMensajeP" :key="v" v-text="v">

                               </div>
                               </v-flex>  -->
                           </v-layout>
                       </v-container>
                        </v-card-text>            
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close()">Cerrar</v-btn>
                        <v-btn v-if="verEnviar==1" color="blue darken-1" text @click="guardar">Añadir Proyecto</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
        <v-dialog v-model="adModalPro"  max-width="400px">
            <v-container grid-list-md>
            <v-card>
              <v-card-title>
              <span class="headline">AÑADIR PROYECTO</span>
              </v-card-title>            
              <v-card-text>
             
               <v-layout wrap>
   
              
          <v-container grid-list-md>
              
                <v-text-field
                  v-model="nombreentidadproyecto" 
                  :counter="100"
                  outlined
                  filled
                  readonly
                  label="Nombre Proyecto o Entidad *"
                  
                ></v-text-field>
                <v-text-field
                  v-model="responsable" 
                  :counter="100"
                  outlined
                  filled
                  readonly
                  label="Nombre Responsable del Proyecto o Entidad *"
                  
                ></v-text-field>
                <v-text-field
                  v-model="financiador" 
                  outlined
                  filled
                  readonly
                
                  label="Financiador"
                  
                ></v-text-field>
                <v-text-field
                  v-model="vigencia" 
                  outlined
                  filled
                  readonly
                
                  label="Vigencia"
                  
                ></v-text-field>
               
                  <!--   <v-flex xs12 sm12 md12 v-show="validaP">
                    <div class="green--text" v-for="v in validaMensajeP" :key="v" v-text="v">

                    </div>
                    </v-flex> -->
                    </v-container> 
                 </v-layout>
                 
                  </v-card-text>            
                  <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeP()">Cerrar</v-btn>
                 
                  <v-btn color="blue darken-1" v-if="verAnadir==1" text @click="guardarPusuario()">Añadir</v-btn>
                 
                  </v-card-actions>
                </v-card>
                </v-container>
              </v-dialog>  
              <v-dialog
                        v-model="dialogM"
                        width="350"
                    >
                    
                
                        <v-card>
                        <v-card-title class="text-h8 green lighten-2">
                           Aviso
                        </v-card-title>
                
                        <v-card-text>
                            <v-flex xs12 sm12 md12 v-show="validaP">
                                <div  v-for="v in validaMensajeP" :key="v" v-text="v">

                                </div>
                            </v-flex>   
                        </v-card-text>
                
                        <v-divider></v-divider>
                
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                            color="primary"
                            
                            text
                            @click="closeP"
                            >
                            Cerrar
                            </v-btn>
                        </v-card-actions>
                        </v-card>
                    
                    </v-dialog>
                <v-dialog v-model="adModal" max-width="290">
                    <v-card>
                        <v-card-title class="headline" v-if="adAccion==1">
                            Activar Item
                        </v-card-title>
                        <v-card-title class="headline" v-if="adAccion==2">
                            Desactivar Item
                        </v-card-title>
                        <v-card-text>
                            Estás a punto de <span v-if="adAccion==1">activar </span>
                            <span v-if="adAccion==2">desactivar </span> el item {{adNombre}}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                           
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="proyectos"
                :search="search"
                class="elevation-1"
            >
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-for="item in items" :key="item.nombreentidadproyecto"> 
                                                
                     
                <td>{{ item.nombreentidadproyecto }}</td>
                <td>{{ item.responsable }}</td>
                <td>{{ item.financiador }}</td>
                <td>{{ item.vigencia }}</td>
                
                
                
                <td>
                    <div v-if="item.estado == 0">
                        <span class="blue--text">No Vigente</span>
                    </div>
                    <div v-if="item.estado == 1">
                        <span class="blue--text">Vigente</span>
                    </div>
                    <div v-if="item.estado == 3">
                        <span class="blue--text">Aprobado</span>
                    </div>
                    <div v-if="item.estado == 2">
                        <span class="red--text">Pendiente de aprobación</span>
                    </div>
                   <!--  <div v-else>
                        <span class="red--text">Pendiente de aprobación</span>
                    </div> -->
                </td>
                <td> 
                    <v-tooltip
            v-model="show"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                    <v-icon
                    v-if="item.estado == 3"
                    medium
                    class="mr-2"                    
                    @click="addProject(item)"
                    >                     
                     add_circle_outline
                 </v-icon>
                 </v-btn>
                    </template>
                    <span>Añadir este proyecto a su lista de proyectos</span>
                </v-tooltip>
                    </td>    
                 
                 </tr>

                  </tbody>                 
                </template>
                <template v-slot:no-data>
                <v-btn color="primary" @click="listar()">Resetear</v-btn>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
    import axios from 'axios'    
    import jsPDF from 'jspdf'
    import autoTable from 'jspdf-autotable'
    
    export default {
        data(){
            return{
                dialog: false,
                search:'',
                proyectos:[],
                headers: [
                   
                    { text: 'Nombre',value: 'nombreentidadproyecto', sortable: true},
                    { text: 'Responsable',value: 'responsable', sortable: true},
                    { text: 'Financiador', value: 'financiador', sortable: false },
                    { text: 'Vigencia',value: 'vigencia', sortable: false},                                  
                    { text: 'Estado', value: 'estado', sortable: false },
                    { text: 'Opciones', value: 'opciones', sortable: false }             
                    
                ],
               
                _id:'',
               
                nombreentidadproyecto:'',
                responsable:'',
                financiador:'',
                vigencia:'',
                resumen:'',
                vigencia:'', 
                objetivos:'',
                email:'', 
                apellidos:'',
                nombre:'',
                proyectosus:[],
                detalles:[],               
                detallesUsuario:[],              
                valida:0,                
                validaMensaje:[],              
                validaMensajeP:[],
                validaP:[],
                adModal:0,
                adModalPro:0,
                emailip:'',
                adAccion:0,
                verAnadir:0,
                verCantidad:0,
                verEnviar:0,
                sw:false,   
                adNombre:'',
                dialogM:0,
                adModalNuevo:0,
                adId:''
            }
        },
       // computed: {
           /*  formTitle () {
            return this.editedIndex === -1 ? 'Nuevo registro' : 'Editar registro'
            }
        }, */
       /*  watch: {
            dialog (val) {
            val || this.close()
            }
        }, */
        created () {
            this.listar();
            this.obtenerusuario();
           
           
            
        },
        methods: {
           
            listar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                          
                axios.get('proyecto/listanoactivos',configuracion).then(function (response){
                    me.proyectos=response.data;                    
                }).catch(function(error){
                    console.log(error);
                });

            },
            mostraNuevo() {
               this.verEnviar=1;
               this.adModalNuevo=1; 
               this.validaMensajeP=[];
            },
            obtenerusuario() {
                 this.usuario=this.$store.state.usuario._id;
                this.nombre=this.$store.state.usuario.nombre;
                this.rol=this.$store.state.rol;
                console.log(this.$store.state.usuario.apellidos);
                this.apellidos=this.$store.state.usuario.apellidos; 
                this.email=this.$store.state.usuario.email;
               // this._idproyecto=this.usuario.detalles[0]._id;
               this.detalles=this.$store.state.usuario.detalles;
               console.log(this.detalles);
               console.log(this._idusuario);
               this._idproyecto=this.$store.state.usuario.detalles[0]._id
                this._idusuario=this.$store.state.usuario._id;
                this.detallesUsuario=this.$store.state.usuario.detalles;
                console.log(this.detalles);
                console.log(this._idusuario);
            },
            
            limpiar(){
                this._id='';
                this.nombreentidadproyecto='';                
                this.responsable='';
                this.vigencia='';
                this.financiador='';
                this.objetivos='';
                this.resumen='';
                this.email='';
                this.valida=0;
                this.adModalPro=0;
                this.validaMensaje=[];                
                this.validaMensajeP=[];
                this.detallesUsuario=[];
                this.verAnadir=0;
                this.verEnviar=1;
                this.dialogM=0;              
                this.sw=false;
               
            },
            validar(){
                this.valida=0;
                this.validaMensaje=[];
                             
                if(this.nombreentidadproyecto.length<1){
                    this.validaMensaje.push('Debe de indicar un nombre para el proyecto.');
                }
                if(this.responsable.length<1){
                    this.validaMensaje.push('Debe de indicar un responsable.');
                }
                if(this.vigencia.length<1){
                    this.validaMensaje.push('Debe de indicar la vigencia del proyecto.');
                }
                 if(this.financiador.length<1){
                    this.validaMensaje.push('Debe de indicar un financiador del proyecto.');
                }
                if (this.validaMensaje.length){
                    this.valida=1;
                }

                return this.valida;
            },
            guardar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};

                
                 if (this.validar()){
                    return;
                } 
               
                    //Código para guardar
                    axios.post('proyecto/add',
                    {
                        'nombreentidadproyecto':this.nombreentidadproyecto,
                        'responsable':this.responsable,
                        'email':this.emailip,
                        'financiador':this.financiador,                                                
                        'vigencia':this.vigencia,
                        'resumen':this.resumen,
                        'estado':2,
                        'objetivos':this.objetivos
                    },configuracion)
                    .then(function(response){
                        me.sendEmailAvisoP();
                        me.limpiar();
                       // me.close();
                        me.listar();
                        
                        me.validaMensajeP=['Se enviaron los datos del proyecto. Hay que esperar a que el administrador lo apruebe.'];
                        me.verEnviar=0;
                        me.validaP=1;
                        me.dialogM=1;
                    })
                    .catch(function(error){
                        me.validaMensaje.push('Ya existe un proyecto con ese mismo nombre. ');
                        
                        me.valida=1;
                        return me.valida;
                        console.log(error);
                    });
                
                
            },
            sendEmailAvisoP(){
                
                  
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};

                         
                axios.post('proyecto/sendmailP',{
                    
                    'nombreentidadproyecto':this.nombreentidadproyecto,                        
                    'responsable':this.responsable,
                    'email':this.emailip,
                    'financiador':this.financiador,                   
                    'vigencia':this.vigencia,                 
                    'quien':this.apellidos + ', ' + this.nombre  + '. mail: '  + this.email   
                     
                  },configuracion                
                  ).then(function (response){
                         
                  }).catch(function(error){
                     
                      console.log(error);
                  });
                
                
              },    
             
            addProject (item) {
                this._id=item._id;
                this.nombreentidadproyecto=item.nombreentidadproyecto;                
                this.responsable=item.responsable;
                this.financiador=item.financiador;
                this.vigencia=item.vigencia;
                this.resumen=item.resumen;
                this.objetivos=item.objetivos;  
                this.verAnadir=1;             
                this.adModalPro = true;
                
            },
            guardarPusuario() {
                  
                  let me=this;
                  let header={"Token" : this.$store.state.token};
                  let configuracion= {headers : header};

                   console.log(me._idusuario);    
                   me.sw=false;
                   console.log(me._id);
                   console.log(me.sw);
                   console.log(me.detallesUsuario);
                   console.log(me.detalles);
                   me.buscarProUs(me._id);
                     console.log(me.sw);
                       if (me.sw) {
                         console.log('proyecto duplicado');
                         me.validaMensajeP=['El proyecto elegido ya se encuentra en su lista de proyectos. Proyecto duplicado'];
                         me.verAnadir=0;
                         me.validaP=1;
                         me.dialogM=1;
                         
                         return;
                       }else{     
                          me.detalles.push(
                          {
                              _idproyecto:me._id, 
                              nombreentidadproyecto:me.nombreentidadproyecto
     
                          });     
                        console.log(me.detalles);  
                        console.log(me._idusuario);                   
                        me.activar();
                       
                        console.log(me._id); 
                         
                          axios.put('usuarios/updatep',{
                      
                          '_id':me._idusuario,                     
                          'detalles': me.detalles,                
                        
                          },configuracion).then(function(response){
                             
                            // me.listarDetallesUsuario(me.usuario._id);                           
                            // me.listarProyecto(me.proyecto._id);
                           // me.listarProyecto(me.proyecto);
                           
                               me.activar();
                               me.validaMensajeP=['El proyecto se añadió a su lista de proyectos. Ya está disponible en su lista de selección para poder hacer la solicitud.'];
                               me.verAnadir=0;
                               me.validaP=1;
                               me.dialogM=1;
                               me.listar();
                          
                      })
                      .catch(function(error){
                          console.log(error);
                      });
                      }
                },
                buscarProUs(){
                  let me=this;

                   me.sw=false;
                   console.log(me.detallesUsuario[0]._idproyecto);
                   console.log(me._id);
                      for (var i=0;i<me.detallesUsuario.length;i++){
                          if(me.detallesUsuario[i]._idproyecto==me._id){
                              me.sw=true;
                          }
                      }
                return me.sw;


                },   
              
            activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adNombre=item.nombreentidadproyecto;
                this.adId=item._id;
                if (accion==1){
                    this.adAccion=1;
                } else if(accion==2){
                    this.adAccion=2;
                } else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
            },
            activar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};

               
                axios.put('proyecto/activate',{'_id':this._id})
                    .then(function(response){
                        me.adModal=0;
                        me.adAccion=0;
                        me.adNombre='';
                        me.adId='';
                        me.listar();
                    },configuracion)
                    .catch(function(error){
                        console.log(error);
                    });
            },
         
           
            desactivar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};

                
                axios.put('proyecto/deactivate',{'_id':this.adId})
                    .then(function(response){
                        me.adModal=0;
                        me.adAccion=0;
                        me.adNombre='';
                        me.adId='';
                        me.listar();
                    },configuracion)
                    .catch(function(error){
                        console.log(error);
                    });
            },
            closeP() {
               this.adModalPro=0; 
              
               this.nombreentidadproyecto='';
               this.descripcion='';
               this.responsable='';
               this.vigencia='';
               this.financiador='';
               this.resumen='';
               this.objetivos='';  
               this.validaMensajeP=[];
               this.dialogM=0;
               this.adModalNuevo = 0;
               // this.validaMensajeP='';
              
               this.proyecto=null;
              
               
               
            },
            close () {
                //this.limpiar();
                this.adModalNuevo = 0;
                this.dialogM=0;
            }
        }
    }
</script>
