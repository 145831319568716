<template>
   <div class="box1">
   
      <v-row >
      

        <v-col class="mb-12">
          <h1 >
            ANILLAMIENTO
          </h1>
          <br>
        
            <h2>¿Que es la oficina de anillamiento?</h2>         
          
          <p >
                       
            </p>         
            <p >
            La oficina de anillamiento, adscrita al Equipo de Seguimiento de Procesos Naturales de la Estación Biológica de Doñana, gestiona la documentación necesaria para realizar los trabajos de anillamiento a los investigadores, ayudantes de investigación y personas asociadas a los proyectos vinculados al instituto. Así mismo tramita todo lo relativo a los resultados de dichos anillamientos. Por otro lado, también coordina los marcajes con anillas legibles a distancia que se realizan en España.
            A la hora de registrar los datos tanto de anillamientos como de controles, es importante observar las normas de transcripción de marcas de lectura a distancia (rules for transcription of birds long distance reading marks), asi como el programa de marcaje de aves incluido en esta oficina, como las recomendaciones de uso de las anillas de metal, según la Oficina de Especies Migratorias.
            Para acceder a funcionalidades avanzadas de consulta y carga de datos, solicite el registro en la aplicación.
                     
          </p>
           <p >
           Registro en la aplicación: <a href="http://anillamiento.ebd.csic.es/DetalleAltaUsuarioAction.do" target="blank">Registro</a>   
            
          </p>
          <p >
           Observaciones: <a href="http://anillamiento.ebd.csic.es/IrInsertarObservacionAction.do" target="blank">Formulario</a>   
            
          </p>
         
          <p >
            <a href="http://anillamiento.ebd.csic.es/Estatico.do?id=anillamiento_home" target="blank">Consulta su página web</a>   
            
          </p>
        </v-col>    

      

      

      
      </v-row>
    
   </div>
  
</template>

<script>
  export default {
    name: 'Anillamiento',

    data: () => ({
     
     
     
    }),
  }
</script>
<style>
  .box1{
    position: absolute;
    top: 50px;
    border: 3px solid green;
    padding: 2%;
    width: 800px;    
    left: 30%;
    margin-left: -100px;
   }
</style>
