<template>
   <div class="box1">
   
      <v-row >
      

        <v-col class="mb-12">
          <h1 >
           ICTS DOÑANA
          </h1>
          <br>
        
            <h2> Infraestructura Científico-Técnica Singular de Doñana (ICTS-RBD)</h2>         
          
          <p >
                       
            </p>         
            <p >
            Bienvenido a la ICTS de Doñana, una Infraestructura Científico-Técnica Singular orientada a proporcionar  acceso y apoyo a las investigaciones científicas desarrolladas en las áreas protegidas de Doñana.
            En este sitio web se puede explorar los diversos servicios e instalaciones que se ofrecen a la comunidad científica para realizar estudios observacionales, experimentales y comparativos sobre la ecología, la evolución y el manejo de la diversidad biológica en el área de Doñana. 
            Estos incluyen:
                                 
          </p>
           <p >
           Instalaciones de campo, soporte técnico y logístico: <a href="http://icts.ebd.csic.es/es/estacion-de-campo-y-laboratorios" target="blank">Instalaciones</a>   
            
          </p>
          <p >
           Programas de seguimiento de la biodiversidad: <a href="http://icts.ebd.csic.es/es/seguimiento-de-la-biodiversidad" target="blank">Programa de Seguimiento</a>   
            
          </p>
          <p >
           Infraestructuras de acceso a datos, servicios y herramientas TIC: <a href="http://icts.ebd.csic.es/es/infraestructura-de-datos" target="blank">Acceso a Datos</a>   
            
          </p>
          <p >
           Observatorio para el Cambio Global: <a href="http://icts.ebd.csic.es/es/web/icts-ebd/observatorio-para-el-cambio-global" target="blank">Observatorio</a>   
            
          </p>
          <p >
            <a href="http://icts.ebd.csic.es/es/inicio" target="blank">Consulta su página web</a>   
            
          </p>
        </v-col>    

      

      

      
      </v-row>
    
   </div>
  
</template>

<script>
  export default {
    name: 'Anillamiento',

    data: () => ({
     
     
     
    }),
  }
</script>
<style>
  .box1{
    position: absolute;
    top: 50px;
    border: 3px solid green;
    padding: 2%;
    width: 800px;    
    left: 30%;
    margin-left: -100px;
   }
</style>
