<template>
   <div class="box1">
   
      <v-row >
      

        <v-col class="mb-12">
          <h1 >
            COLECCIONES
          </h1>
          <br>
        
            <h2>Información General</h2>         
          
          <p >
                       
            </p>         
            <p >
            Las colecciones científicas constituyen un recurso de gran valor para la investigación, la divulgación y la educación. Existen otros usos menores como los relativos a aspectos artísticos (fotografía, pintura, etc.), forenses (identificación de animales o sus restos decomisados en aduanas o procedentes de denuncias del SEPRONA, etc.), etc.
            El objetivo fundamental del servicio de colecciones es poner a disposición de los usuarios los fondos existentes, así como toda su información asociada.
            Desarrolla además otras tareas que son imprescindibles para cumplir con su objetivo principal y asegurar su buen funcionamiento, entre las que se encuentran:

               Mantenimiento de las colecciones (registro de nuevos fondos, revisión, etiquetado y ordenación de fondos, informatización, control de plagas y desinsectación, revisión de niveles de fluidos, etc.).
               Gestión del laboratorio de preparación, en el que se llevan a cabo el procesamiento de la mayor parte del material que se incorpora a la colección y la restauración del que resulta deteriorado.
               Gestión de la cámara congeladora en la que se guarda el material que posteriormente pasará a la colección, así como muestras de diferentes investigadores. 
                     
          </p>
           <p >
           Catálogo de Servicios: <a href="http://www.ebd.csic.es/web/colecciones/catalogo-de-servicios" target="blank">Inscripción</a>   
            
          </p>
          <p >
           Solicitud de Muestras: <a href="http://www.ebd.csic.es/web/colecciones/solicitud" target="blank">Formulario</a>   
            
          </p>
           <p >
           Visita Guiada: <a href="http://www.ebd.csic.es/web/colecciones/visita-guiada" target="blank">Inscripción</a>   
            
          </p>
          <p >
            <a href="http://www.ebd.csic.es/web/colecciones" target="blank">Consulta su página web</a>   
            
          </p>
        </v-col>    

      

      

      
      </v-row>
    
   </div>
  
</template>

<script>
  export default {
    name: 'colecciones',

    data: () => ({
     
     
     
    }),
  }
</script>
<style>
  .box1{
    position: absolute;
    top: 50px;
    border: 3px solid green;
    padding: 2%;
    width: 800px;    
    left: 30%;
    margin-left: -100px;
   }
</style>
