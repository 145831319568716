<template grid-list-xl   >
          
     <div class="box" >
           
    <v-form 
      ref="form"
      v-model="valid"
      value
    >
      
     <v-card >
        <v-card-title>         
          Usuario Registrado   
               
    </v-card-title> 
     
   
      <v-text-field
        v-model="num_documento"
        :rules="customRules"
        
        label="dni o nie *"
        required
      ></v-text-field>
     
        <v-text-field
        v-model="password"
      
        type="password"
        label="Contraseña *"
        required
      ></v-text-field>
      <v-btn       
        color="success"
        
        class="mr-4"
        @click="obtenerRol()"
      >
        Ingresar
      </v-btn>
      <v-flex xs12 sm12 md12 v-show="valida">
          <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">

          </div>
      </v-flex>     
                   
     </v-card>  
     <v-dialog
                        v-model="dialogRol"
                        width="350"
                    >
                    
                
                        <v-card>
                        <v-card-title class="text-h8 green lighten-2">
                            Elija Rol
                        </v-card-title>
                        <v-card-text>  
                          <v-radio-group v-model="rol" :rules="[v => !!v || 'Item is required']" required>
                              <v-radio
                              v-for="item in roles" 
                                :mandatory="false" 
                                :key="item.rol"                                
                                :label="`${item.rol}`"
                                :value="item.rol"
                              ></v-radio>
                            </v-radio-group>
                        </v-card-text>
                
                        <v-divider></v-divider>
                
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                            :disabled="valid==false"
                            color="success"
                            class="mr-4"
                            @click="validarusuario()"
                           
                            >
                            Aceptar
                            </v-btn>
                        </v-card-actions>
                        </v-card>
                    
                    </v-dialog>                     
              
          
    </v-form>
 
      <v-spacer></v-spacer>
     
   </div>
      
 </template>

 <script>
     import axios from 'axios'      
   

     export default {
        data(){
            return{
                dialog: false,
                search:'',
                
                editedIndex: -1,
                _id:'',
                _idproyecto:'',
                _idusuario:'',
                tipouso:'',
                apellidos:'',
                email:'',
                nombre:'',
                num_documento:'',
                password:'',               
                tipouso:'',  
                rol:'',             
               
                quien:'',
                valida:0,
                validaP:0,
                validaMensaje:[],
                validaMensajeP:[],
                roles:[],
                token:'',
                verNuevo:0,
                dialogRol:0,
                // verDatos:0,
               
                pp:0,
                sw:false,             
               
                valid: true,
               
                nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 255) || 'Name must be less than 255 characters',
                ],
               descRules: [
                v => !!v || 'indique una breve descripción',
                v => (v && v.length <= 255) || 'Name must be less than 255 characters',
                ],
                peRules: [
                  v => !!v || 'Elija un proyecto de la lista desplegable',
                  
                ],
                emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                customRules: [
                v => !!v || 'número de documento es requerido',
                v => (v.length >8 && v.length <= 12 ) || 'el numero de documento tiene que ser válido',
                ],
                select: null,
                items: [
                'Item 1',
                'Item 2',
                'Item 3',
                'Item 4',
                ],
                checkbox: false,
                itemsb: [
                  {
                    text: 'Usuario Registrado',
                    disabled: true,
                    href: 'Registrado',
                  },
                  
                  {
                    text: 'Usuario Nuevo (primera solicitud)',
                    disabled: false,
                    href: 'Solicitudnueva',
                  },
                ],
               
                }
                
            
                   
               
                   
         },
          
        created () {
          

         },
          methods: {

              validate () {
                this.$refs.form.validate();
                },
              reset () {
                this.verDatos=0;
                this.$refs.form.reset();
                },
              resetValidation () {
                this.verDatos=0;
                this.$refs.form.resetValidation();
                },           
           
           
            limpiar(){
                   
                    
                    this.rol='';
                   
                   // this.roles=[];
                    this._idusuario='';
                    this.password='';
                    this.num_documento='';
                    this.verNuevo=0;
                    this.pp=0;
                    this.dialogRol=0;
                    this.sw=false;
                    validaMensajeP=[];
                    validaP=0;
                   // this.verDatos=0;
                    
                /*  this.valida=0;
                    this.validaMensaje=[];  
                    this.editedIndex=-1;    */         
                },
           
           
                obtenerRol() {

                  let me=this;
                       
                       me.valida=0;
                       me.validaMensaje=[];               
                        
                                
                      axios.post('usuarios/login', {num_documento: me.num_documento, password: me.password})
                      
                      .then(function (response){
                         
                                             
                           if (response.data.user) {
                              
                             me.token= response.data.tokenReturn;
                              me.usuario=response.data.user;
                              me.nombre=response.data.user.nombre;
                             
                              me.roles=response.data.user.roles;
                             
                              
                              me.apellidos=response.data.user.apellidos;
                              /* if (response.data.user.detalles.length>0) {
                              me._idproyecto=response.data.user.detalles[0]._id;
                               } */
                              me._idusuario=response.data.user._id;
                            //  me.tipouso=response.data.user.tipouso;
                              if (me.usuario.estado==0) {
                                me.validaMensaje.push('Este usuario no está actvo en el sistema');
                                me.valida=1;
                                return;
                              }

                            } else { 
                            me.validaMensaje.push('Este nif no está registrado o la contraseña es incorrecta. Si es un usuario nuevo vaya a la opción: Primera Solicitud');
                            me.valida=1;
                           console.log(me.valida);
                           } 
                           if (me.roles.length==1) {
                                me.rol=me.roles[0].rol;
                                if (me.rol=="Usuario") {
                                 console.log(me.rol);
                                // me.$store.dispatch("guardarTokenU",me.usuario);
                                // me.$store.dispatch("guardarToken", me.token);
                                 me.$store.dispatch("guardarToken",me.token);
                                 me.$store.dispatch("guardarTokenRol", me.rol);
                                 me.reset;
                                 me.$router.push({ name: 'solicitudesusuario' });
                                  
                                } 
                            
                               
                              } else {
                                                                
                                me.dialogRol=1;
                              };  
                                  
                      }).catch(function(error){
                            me.validaMensaje.push('Este nif no está registrado o la contraseña es incorrecta. Si es un usuario nuevo vaya a la opción: Primera Solicitud');
                            me.valida=1;
                          
                           console.log(error);
                      });
                    
                              
             
                 },
                 
                 validarusuario(){
                    
                     

                    let me=this;
                     
                     me.valida=0;
                     me.validaMensaje=[];               
                     console.log(me.rol); 
                     if (me.rol=="Usuario" || me.rol=="IP") {
                            console.log(me.rol);
                          //  me.$store.dispatch("guardarTokenU", me.usuario);
                          //  me.$store.dispatch("guardarToken", me.token);
                          me.$store.dispatch("guardarToken",me.token);
                            me.$store.dispatch("guardarTokenRol", me.rol);
                            me.reset;
                            me.$router.push({ name: 'solicitudesusuario' });
                              
                            } else {
                          
                            console.log(me.rol);
                         //   me.$store.dispatch("guardarTokenU", me.usuario);
                          // me.$store.dispatch("guardarToken", me.token);
                          me.$store.dispatch("guardarToken",me.token);
                            me.$store.dispatch("guardarTokenRol", me.rol);                           
                            me.reset;
                            me.$router.push({ name: 'solicitudes' });
                          }         
                   
                  // return me.valida;
                  },   

           
                  validarusuariocopia(){
                    
                     

                      let me=this;
                       
                       me.valida=0;
                       me.validaMensaje=[];               
                        
                                
                      axios.post('usuarios/login', {num_documento: me.num_documento, password: me.password})
                      
                      .then(function (response){
                         
                        
                                                  
                           if (response.data.user) {
                              console.log(response.data.user); 
                              
                              
                             // console.log(response.data.tokenReturn);   
                              me.usuario=response.data.user;
                              me.nombre=response.data.user.nombre;
                             
                              me.roles=response.data.user.roles;
                              if (me.roles.length==1) {
                                me.rol=me.roles[0].rol;
                                console.log(me.roles)
                              } else {
                                console.log(me.roles);
                                me.dialogRol=1;
                              };
                              
                              
                              me.apellidos=response.data.user.apellidos;
                              /* if (response.data.user.detalles.length>0) {
                              me._idproyecto=response.data.user.detalles[0]._id;
                               } */
                              me._idusuario=response.data.user._id;
                              me.tipouso=response.data.user.tipouso;
                              console.log(me.rol);
                              if (me.usuario.estado==0) {
                                me.validaMensaje.push('Este usuario no está actvo en el sistema');
                                me.valida=1;
                                return;
                              }
                               if (me.rol=="Usuario") {
                                 console.log(me.rol);
                               //  me.$store.dispatch("guardarToken", me.token);
                                // me.$store.dispatch("guardarTokenU",me.usuario);
                                me.$store.dispatch("guardarToken",me.token);
                                 me.reset;
                                 me.$router.push({ name: 'solicitudesusuario' }).catch(error => {
                                    if (
                                      error.name !== 'NavigationDuplicated' &&
                                      !error.message.includes('Avoided redundant navigation to current location')
                                    ) {
                                      console.log(error)
                                    }});
                                  
                              } else {
                               
                                 console.log(me.rol);
                               //  me.$store.dispatch("guardarToken", me.token);
                               //  me.$store.dispatch("guardarTokenU",me.usuario);
                               me.$store.dispatch("guardarToken",me.token);
                                 me.reset;
                                 me.$router.push({ name: 'solicitudes' }).catch(error => {
                                    if (
                                      error.name !== 'NavigationDuplicated' &&
                                      !error.message.includes('Avoided redundant navigation to current location')
                                    ) {
                                      console.log(error)
                                    }});;
                              }
                           } else { 
                            me.validaMensaje.push('Este nif no está registrado o la contraseña es incorrecta. Si es un usuario nuevo vaya a la opción: Primera Solicitud');
                            me.valida=1;
                           console.log(me.valida);
                           } 
                             
                                  
                      }).catch(function(error){
                            me.validaMensaje.push('Este nif no está registrado o la contraseña es incorrecta. Si es un usuario nuevo vaya a la opción: Primera Solicitud');
                            me.valida=1;
                          
                           console.log(error);
                      });
                      
                    
                    return me.valida;
                    },   

                
            
          
            close() {
                    this.dialog = false;
                   
            }
          }
    }
    
</script>
<style>
.box{
   position:relative;
   top: 20px;
   border: 33px solid green;
   padding: 22%;
   width: 500px;    
   left: 35%;
   margin-left: -100px;
}
</style>