<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar text color="white">                
                <v-icon
                     large
                    class="mr-3"                   
                    >
                    science
                    </v-icon> 
                           
                <v-toolbar-title>Mis Proyectos</v-toolbar-title>
                <v-divider
                class="mx-2"
                inset
                vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-text-field class="text-xs-center" v-model="search" append-icon="search" 
                label="Búsqueda" single-line hide-details></v-text-field>
                <v-spacer></v-spacer>
               
                <v-dialog v-model="adModal" max-width="290">
                    <v-card>
                        <v-card-title class="headline" v-if="adAccion==1">
                            Activar Item
                        </v-card-title>
                        <v-card-title class="headline" v-if="adAccion==2">
                            Desactivar Item
                        </v-card-title>
                        <v-card-text>
                            Estás a punto de <span v-if="adAccion==1">activar </span>
                            <span v-if="adAccion==2">desactivar </span> el item {{adNombre}}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="activarDesactivarCerrar()" color="green darken-1" text="text">
                                Cancelar
                            </v-btn>
                            <v-btn v-if="adAccion==1" @click="activar()" color="orange darken-4" text="text">
                                Activar
                            </v-btn>
                            <v-btn v-if="adAccion==2" @click="desactivar()" color="orange darken-4" text="text">
                                Desactivar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="detallesUsuario"
                :search="search"
                :pagination.sync="pagination"              
                 sortBy="nombreentidadproyecto"
                
                class="elevation-1"
            >
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-for="item in items" :key="item.nombreentidadproyecto"> 
                                                
                     
                <td>{{ item.nombreentidadproyecto }}</td>            
                 
                 </tr>

                  </tbody>                 
                </template>
                <template v-slot:no-data>
                <v-btn color="primary" @click="listar()">Resetear</v-btn>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
    import axios from 'axios'    
    import jsPDF from 'jspdf'
    import autoTable from 'jspdf-autotable'
    
    export default {
        data(){
            return{
                dialog: false,
                search:'',
                proyectos:[],
                proyectosus:[],
                detallesUsuario:[],
                headers: [
                   
                    { text: 'Nombre',value: 'nombreentidadproyecto', sortable: true},
                   /*  { text: 'Responsable',value: 'responsable', sortable: true},
                    { text: 'Financiador', value: 'financiador', sortable: false },
                    { text: 'Vigencia',value: 'vigencia', sortable: false},                                  
                    { text: 'Estado', value: 'estado', sortable: false }, */
                   // { text: 'Opciones', value: 'opciones', sortable: false }             
                    
                ],
                editedIndex: -1,
                _id:'',
                
                nombreentidadproyecto:'',
                responsable:'',
                financiador:'',
                vigencia:'',
                resumen:'',
                vigencia:'',                
                valida:0,                
                validaMensaje:[],
                adModal:0,
                adAccion:0,
                verCantidad:0,
                adNombre:'',
                adId:''
            }
        },
        computed: {
            formTitle () {
            return this.editedIndex === -1 ? 'Nuevo registro' : 'Editar registro'
            }
        },
        watch: {
            dialog (val) {
            val || this.close()
            }
        },
        created () {
           // this.listar();
           this.obtenerusuario();           
          // this.listarDetalles();
            this.listarDetallesUsuario(this._idusuario);  
           
        },
        methods: {
          
           
          /*   listar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};  
                          
                axios.get('proyecto/list',configuracion).then(function (response){
                    me.proyectos=response.data;                    
                }).catch(function(error){
                    console.log(error);
                });

            }, */
            listarDetallesUsuario(id){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header}; 
                      let proyectosArray=[];
                      me.proyectosus=[];          
                      axios.get('usuarios/query?_id='+id,configuracion).then(function (response){
                          me.detallesUsuario=response.data.detalles;                                                               
                          proyectosArray=me.detallesUsuario;
                          console.log(me.detallesUsuario);
                          proyectosArray.map(function(x){
                              me.proyectosus.push({text:x.nombreentidadproyecto,  value:x._idproyecto});
                      });
                          
                      }).catch(function(error){
                          console.log(error);
                      });
                  },
            /*     listarDetalles(id){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};             
                axios.get('usuario/query?_id='+id,configuracion).then(function (response){
                    me.detalles=response.data.detalles;
                    console.log(me.detalles);
                }).catch(function(error){
                    console.log(error);
                });
            },  */
             obtenerusuario() {
                this.usuario=this.$store.state.usuario._id;
                    this.nombre=this.$store.state.usuario.nombre;
                    this.rol=this.$store.state.rol;
                    this.apellidos=this.$store.state.usuario.apellidos; 
                   // this.apellidos=this.usuario.apellidos;
                    this._idproyecto=this.$store.state.usuario.detalles[0]._id;
                    this._idusuario=this.$store.state.usuario._id;
                    console.log(this._idusuario);
                    console.log(this.apellidos);
                    this.listarDetallesUsuario(this._idusuario);
                },
              
           /*  limpiar(){
                this._id='';
                this.nombreentidadproyecto='';                
                this.responsable='';
                this.vigencia='';
                this.financiador='';
                this.objetivos='';
                this.resumen='';
                this.valida=0;
                this.validaMensaje=[];
                this.editedIndex=-1;
            },
            validar(){
                this.valida=0;
                this.validaMensaje=[];
                             
                if(this.nombreentidadproyecto.length<1){
                    this.validaMensaje.push('Debe de indicar un nombre para el proyecto.');
                }
                if(this.responsable.length<1){
                    this.validaMensaje.push('Debe de indicar un responsable.');
                }
                if(this.vigencia.length<1){
                    this.validaMensaje.push('Debe de indicar la vigencia del proyecto.');
                }
                 if(this.financiador.length<1){
                    this.validaMensaje.push('Debe de indicar un financiador del proyecto.');
                }
                return this.valida;
            },
            guardar(){
                let me=this;
                
                
                if (this.validar()){
                    return;
                }
                if (this.editedIndex >-1){
                    //Código para editar
                    axios.put('proyecto/update',
                    {
                        '_id':this._id,
                        'nombreentidadproyecto':this.nombreentidadproyecto,
                        'responsable':this.responsable,
                        'financiador':this.financiador,                                                
                        'vigencia':this.vigencia,
                        'resumen':this.resumen,
                        'objetivos':this.objetivos

                    })
                    .then(function(response){
                        me.limpiar();
                        me.close();
                        me.listar();
                    })
                    .catch(function(error){
                        console.log(error);
                    });
                }else{
                    //Código para guardar
                    axios.post('proyecto/add',
                    {
                        'nombreentidadproyecto':this.nombreentidadproyecto,
                        'responsable':this.responsable,
                        'financiador':this.financiador,                                                
                        'vigencia':this.vigencia,
                        'resumen':this.resumen,
                        'objetivos':this.objetivos
                    })
                    .then(function(response){
                        me.limpiar();
                        me.close();
                        me.listar();
                    })
                    .catch(function(error){
                        me.validaMensaje.push('Ya existe un artículo con ese mismo nombre. ');
                        
                        me.valida=1;
                        return me.valida;
                        console.log(error);
                    });
                }
            },
            editItem (item) {
                this._id=item._id;
                this.nombreentidadproyecto=item.nombreentidadproyecto;                
                this.responsable=item.responsable;
                this.financiador=item.financiador;
                this.vigencia=item.vigencia;
                this.resumen=item.resumen;
                this.objetivos=item.objetivos;               
                this.dialog = true;
                this.editedIndex=1;
            },
            activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adNombre=item.nombreentidadproyecto;
                this.adId=item._id;
                if (accion==1){
                    this.adAccion=1;
                } else if(accion==2){
                    this.adAccion=2;
                } else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
            },
            activar(){
                let me=this;
                
               
                axios.put('proyecto/activate',{'_id':this.adId})
                    .then(function(response){
                        me.adModal=0;
                        me.adAccion=0;
                        me.adNombre='';
                        me.adId='';
                        me.listar();
                    })
                    .catch(function(error){
                        console.log(error);
                    });
            },
            desactivar(){
                let me=this;
               
                
                axios.put('proyecto/deactivate',{'_id':this.adId})
                    .then(function(response){
                        me.adModal=0;
                        me.adAccion=0;
                        me.adNombre='';
                        me.adId='';
                        me.listar();
                    })
                    .catch(function(error){
                        console.log(error);
                    });
            }, 
             close () {
                this.limpiar();
                this.dialog = false;
            } */ 
        }
    }
</script>
