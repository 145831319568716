import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import vuetify from './plugins/vuetify'
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import VueFormGenerator from "vue-form-generator"
import "vue-form-generator/dist/vfg.css" 
import VueExcelXlsx from "vue-excel-xlsx";



Vue.use(VueFormWizard)
Vue.use(VueFormGenerator)
Vue.use(VueExcelXlsx)

Vue.config.productionTip = false
// axios.defaults.baseURL='http://localhost:4000/api/'
axios.defaults.baseURL='https://solicitudes.icts.ebd.csic.es/api/'
 
new Vue({
  router,
  store,
  vuetify,
  
  
  render: h => h(App)
}).$mount('#app')
