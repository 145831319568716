<template>
   <div class="box1">
   
      <v-row >
      

        <v-col class="mb-12">
          <h1 >
            INFRAESTRUCTURA TIC
          </h1>
          <br>
        
            <h2>Internacionalización  de  la  ICTS  de  la  Reserva 
                Biológica  de  Doñana:  apoyo  a  la  investigación 
                desde una arquitectura orientada a servicios.</h2>         
          
          <p >
                       
            </p>         
            <p >
            4 Actuaciones, 2 años:<BR></BR><BR></BR>
            A1 Desarrollo de una e-Infraestructura TIC de soporte a los 
            procesos de seguimiento implicados en el cambio global de la 
            ICTS-Reserva Biológica de Doñana.<BR></BR>
            A2 Elaboración de una red distribuida de microsensores, como 
            parte del desarrollo del Observatorio de Cambio Global 
            ICTS-RBD.<BR></BR>
            A3 Adecuación de las Instalaciones de la ICTS-RBD para dar 
            soporte físico y técnico a la e-infraestructura y sus usuarios.<BR></BR>
            A4 Actuaciones de demostración para el desarrollo de los 
            primeros demostradores de los laboratorios virtuales.
                                 
          </p>
          
          
          <p >
            <a href="http://icts.ebd.csic.es/documents/52509/277180/ICTS+y+Productos.pdf/8d161eab-d98a-45c5-8d4f-2c35b2d6f62e?version=1.0" target="blank">Consulta pdf</a>   
            
          </p>
        </v-col>    

      

      

      
      </v-row>
    
   </div>
  
</template>

<script>
  export default {
    name: 'Anillamiento',

    data: () => ({
     
     
     
    }),
  }
</script>
<style>
  .box1{
    position: absolute;
    top: 50px;
    border: 3px solid green;
    padding: 2%;
    width: 800px;    
    left: 30%;
    margin-left: -100px;
   }
</style>
