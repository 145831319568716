<template grid-list-xl class="pa-4 white" >
  <div class="box">
    <v-stepper v-model="step">
						<v-stepper-header>
						  <v-stepper-step :complete="step > 1" step="1">
						   
							<span class="headline">Datos personales</span>                      
							
							
						  </v-stepper-step>
						  <v-divider></v-divider>
						  <v-stepper-step :complete="step > 2" step="2">
						  Datos del Proyecto
						  
						  </v-stepper-step>
						</v-stepper-header>                               
                                            
                                                                                         
                               <v-stepper-items>
								  <v-stepper-content step="1">
									<v-form ref="form1" v-model="valid1" @submit.prevent="validateStep1" >                                      
        
                               
															
                  <v-card >                             
                                                       
                   <v-container grid-list-md>                        
                                                                 
                  <v-text-field
                      v-model="apellidos" 
                      :counter="150"
                      prepend-icon="mdi-account"
                      :rules="nameRules"
                      label="Apellidos *"
                      required
                    ></v-text-field>
                  <v-text-field
                      v-model="nombre" 
                      :counter="100"
                      prepend-icon="mdi-account"
                      :rules="nameRules"
                      label="Nombre  *"
                      required
                    ></v-text-field>
                    <v-switch
                      v-model="model"
                      color="primary"
                      hide-details
                      true-value="DNI"
                      false-value="Passport"
                      :label="`Tipo de documento: ${model}`"
                    ></v-switch>
                    <v-text-field
                      v-model="num_documento"
                      prepend-icon="mdi-account-card"
                      :rules="numDocumentoregla"
                      required
                      label="Número de Documento - Document number *"
                      
                    ></v-text-field>
                    <v-text-field
                      v-model="email"
                      prepend-icon="mdi-mail"
                      :rules="emailRules"  required
                      label="Correo electrónico  *"
                      
                    ></v-text-field>
                    <v-text-field 
                        v-model="centro_procedencia" 
                        prepend-icon="mdi-home-outline"
                        :counter="100"                   
                        :rules="nameRules" 
                        label="Centro de Procedencia *"
                        required
                    ></v-text-field>  
                    <v-text-field 
                        v-model="actividad"   
                        prepend-icon="mdi-run"    
                        :rules="nameRules"     
                        label="Actividad *"
                        autocomplete="new-text"
                        required
                    ></v-text-field>  
                    <v-text-field 
                      label="Contraseña *" 
                      v-model="password" 
                      prepend-icon="mdi-lock"
                      :rules="passwordRules" required
                      type="password" 
                      autocomplete="new-password"
                      
                      
                  ></v-text-field>
                  <v-text-field 
                      label="Confirmar contraseña *" 
                      v-model="confirmPassword" 
                      prepend-icon="mdi-lock"
                      :rules="[confirmPasswordRules,passwordConfirmationRule]"  
                      type="password" 
                      required
                  ></v-text-field>    
                    
                           
                 
                                                             
                                                        
                                                                              
                    <v-card-actions>   
								  					
                    <v-spacer></v-spacer>
                                       
                                       
                    <v-btn :disabled="valid1==false" color="primary" @click="step++">Siguiente</v-btn>
									  <v-btn color="blue darken-1" text @click="closeS">Cerrar</v-btn> 
										
                                  
                               
                    </v-card-actions> 
                    </v-container>         
                     </v-card>   
                                                 
                 </v-form>
							  </v-stepper-content>	                 
                                <v-stepper-content step="2">
                                <v-form ref="form2"   v-model="valid"
                                    value @submit.prevent="validateStep2">   
                                           
                                    <v-card >   
                                       
								
                  <v-autocomplete v-if="verProyectos==0"
                      :items="proyectos"                         
                      v-model="proyecto" 
                      :rules="[v => !!v || 'Elija un proyecto de la lista desplegable.']" required  
                      prepend-icon="mdi-flask-empty" 
                      dense
                      chips
                      clearable                                                             
                      label="Busque su Proyecto. Escriba  parte del nombre del IP o parte del proyecto."                      
                      @change="listarProyecto(proyecto)" 
                      solo                      
                                                          
                      ></v-autocomplete>
                
                      <v-alert v-if=" verProyectos==0" outlined small type="info">
                          Busque su proyecto de investigación en la lista desplegable de arriba. Escriba parte del nombre del proyecto o parte del nombre del investigador principal del mismo.</v-alert>
              <v-spacer></v-spacer>
              <v-flex xs12 sm12 md12 lg12 x12>
                                    <v-btn small color="primary"
                                    v-if="verProyectos==0"
                                    @click.native="mostrarPro()"  >
                                        Añadir Proyecto
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-alert v-if=" verProyectos==0" outlined small type="info">
                                        Si su proyecto no se encuentra en la lista de selección. Pulse añadir proyecto para añadirlo a la lista de selección.
                                      El proyecto será revisado por la Oficina de Coordinación de la EBD para su aprobación.</v-alert>
                                    </v-flex>
                                    <v-spacer></v-spacer>
                <v-dialog v-model="adModalNuevo" max-width="500px">
                  
                 
                   <v-card>
                       <v-card-title>
                       <span class="headline">Añadir Proyecto Nuevo</span>
                       </v-card-title>            
                       <v-card-text>
                           <v-container grid-list-md>
                           <v-layout wrap>                                
                              
                               <v-flex xs12 sm12 md12>
                                   <v-text-field v-model="nombreentidadproyecto" label="Título *">                                        
                                   </v-text-field>
                               </v-flex>
                                 <v-flex xs12 sm12 md12>
                                   <v-text-field v-model="responsable" label="Responsable - Investigador Principal *">                                        
                                   </v-text-field>
                               </v-flex>   
                               <v-flex xs12 sm12 md12>
                                    <v-text-field
                                     v-model="emailip" 
                                     :rules="emailRules"  required
                                     label="Correo del IP *">                                        
                                    </v-text-field>
                                </v-flex>                      
                               <v-flex xs12 sm12 md12>
                                   <v-text-field v-model="financiador" label="Financiador *">                                        
                                   </v-text-field>
                               </v-flex>  
                                <v-flex xs12 sm12 md12>
                                   <v-text-field v-model="vigencia" label="Vigencia *">                                        
                                   </v-text-field>
                               </v-flex>                                    
                                 <v-flex xs12 sm12 md12>
                                   <v-text-field v-model="resumen" label="Resumen">                                        
                                   </v-text-field>
                               </v-flex>    
                                 <v-flex xs12 sm12 md12>
                                   <v-text-field v-model="objetivos" label="Objetivos">                                        
                                   </v-text-field>
                               </v-flex>                         
                               <v-flex xs12 sm12 md12 v-show="valida">
                                   <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">

                                   </div>
                               </v-flex>
                           <!--    <v-flex xs12 sm12 md12 v-show="validaP">
                               <div class="green--text" v-for="v in validaMensajeP" :key="v" v-text="v">

                               </div>
                               </v-flex>  -->
                           </v-layout>
                       </v-container>
                       </v-card-text>            
                       <v-card-actions>
                       <v-spacer></v-spacer>
                       <v-btn color="blue darken-1" text @click="closeNuevo()">Cerrar</v-btn>
                       <v-btn v-if="verEnviar==1" color="blue darken-1" text @click="guardar">Añadir Proyecto</v-btn>
                       </v-card-actions>
                   </v-card>
               </v-dialog>
              <v-checkbox
                v-model="checkbox1"
                label="No tengo proyecto de investigación. Algunos servicios de la ICTS-RBD pueden no estar disponibles"
                color="success"
                small
                @click="check(checkbox1)"
            ></v-checkbox>
                    <v-flex xs12 sm12 md12 lg12 x12>
                    <v-alert outlined small type="info">
                        Si no tiene proyecto de investigación marque la casilla 'No tengo proyecto de investigación'.
                      La oficina de coordinación revisará si se puede atender la solicitud o en su caso pedirle más información</v-alert>
                    </v-flex> 
                  
                               <v-row>
                                  <v-col> 

                                  <v-btn color="blue darken-1" text @click="closeS">Cerrar</v-btn> 
                                    </v-col>
                                 <v-col>
                                    <v-btn color="primary" @click="previousStep()">Anterior</v-btn>
                                    </v-col>
                                  
                                  <v-col>     
                                     <v-btn
                                    :disabled="valid==false"
                                                                        
                                    color="success"
                                    class="mr-4"
                                    @click="registrarUsuario()"
                                    >
                                    Registrarse
                                    </v-btn>
                                    </v-col>
                                 
                                    </v-row>
                                     </v-card>                               
                                   
								
								
                                
								 
                                 </v-form>
									  </v-stepper-content>
									</v-stepper-items>
								  </v-stepper>
            <v-dialog
                v-model="dialogM"
                width="350"
            >
            
        
                <v-card>
                <v-card-title class="text-h8 green lighten-2">
                    Aviso 
                </v-card-title>
        
                <v-card-text>
                    <v-flex xs12 sm12 md12 v-show="valida">
                        <div  v-for="v in validaMensaje" :key="v" v-text="v">

                        </div>
                    </v-flex>   
                </v-card-text>
        
                <v-divider></v-divider>
        
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    color="primary"
                    
                    text
                    @click="closeR"
                    >
                    Ir a Usuario registrado
                    </v-btn>
                </v-card-actions>
                </v-card>
                    
     </v-dialog>
     <v-dialog
                v-model="dialogMP"
                width="350"
            >
            
        
                <v-card>
                <v-card-title class="text-h8 green lighten-2">
                    Aviso 
                </v-card-title>
        
                <v-card-text>
                    <v-flex xs12 sm12 md12 v-show="validaP">
                        <div  v-for="v in validaMensajeP" :key="v" v-text="v">

                        </div>
                    </v-flex>   
                </v-card-text>
        
                <v-divider></v-divider>
        
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    color="primary"
                    
                    text
                    @click="closeP()"
                    >
                   Cerrar
                    </v-btn>
                </v-card-actions>
                </v-card>
                    
     </v-dialog>
    </div>
 </template>

 <script>

    import axios from 'axios'       
   

    export default {
        data(){
            return{
                dialog: false,
                search:'',
                step: 1,
                checkbox1: false,
                editedIndex: -1,
                _id:'',
                _idproyecto:'',
                _idusuario:'',
                apellidos:'',
                email:'',
                emailip:'',
                nombre:'',
                num_documento:'',
                password:'',               
                verEnviar:0, 
                //servicioicts: ['Colecciones', 'Anillamiento', 'RBD/END', 'Apoyo_Logistico', 'Datos_ICTS', 'TIC'],
                servicioicts:'',
                cabeceraDetalles:[
                    { text: 'Proyecto', value: 'nombre', sortable: false }                    
                ], 
                detalles:[],  
               
                nombreproyecto:'',    
                proyecto:'',
                usuario:'',
                actividad:'',
                centro_procedencia:'',
                proyectos:[], 
                detalles:[],
                roles:[],
                solicitudes:[],
                nombreentidadproyecto:'',
                descripcion:'',
                responsable:'',
                financiador:'',
                resumen:'',
                objetivos:'',
                quien:'',
                presupuesto:0,
                vigencia:'',
                valida:0,
                pp:0,
                numsolicitud:0,
                validaMensaje:[],
                validaMensajeP:[],
                validaP:[],
                adModal:0,                
                adAccion:0, 
                adModalPro:0,
                verProyectos:0,              
                verNuevo:0,
                adModalNuevo:0,
                dialogM:0,
                dialogMP:0,
                valid: true,
                model:'DNI',
                password: '',
                confirmPassword: '',
                passwordRules: [v => !!v || "Password is required"],
                confirmPasswordRules: [v => !!v || "Password is required"],
                valid:true,
                valid1:false,
                
              
               
               nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 100) || 'Name must be less than 100 characters',
                ],
               descRules: [
                v => !!v || 'indique una breve descripción',
             //   v => (v && v.length <= 255) || 'Name must be less than 255 characters',
                ],
              
                emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                numDocumentoregla: [
               
                v => !!v || 'Este campo es obligatorio',
                  v => /^[0-9]{8}[A-Za-z]$/.test(v) || v.length >= 8  || 'Por favor ingrese un DNI español o un pasaporte válido (mínimo 8 caracteres)',
                  v => {
                    const isDni = /^[0-9]{8}[A-Za-z]$/.test(this.num_documento);
                  //  const isPassport = /^[A-Za-z]{1,}[0-9]{3,12}$/.test(this.num_documento);
                  const isPassport = v.length >= 8  || "El pasaporte debe de contener al menos 8 caracteres";
  
                    if (this.model == 'DNI' && isDni) {
                      return this.validateDNI(this.num_documento) || 'El número de DNI es inválido';
                    } else if (this.model == 'Passport' && isPassport) {
                      return true;
                    } else {
                      return 'Por favor ingrese un DNI español o un pasaporte válido (debe de empezar por una letra)';
                    }
                  }
                ],
               /*  customRules: [
                 v => !!v || 'número de documento es requerido',
               // v => (v.length >8 && v.length <= 12 ) || 'el numero de documento tiene que ser válido',
                  v =>  /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i.test(v) ||  'el numero de documento tiene que ser válido',
               //  validateDNI(num_documento) || 'el numero de documento tiene que ser válido',
                ],
                 */
                passwordRules:[
                    v => !!v || "La contraseña es requerida",
                    v => v.length >= 8  || "La contraseña debe de contener al menos 8 caracteres"],
                select: null,
                items: [
                'Item 1',
                'Item 2',
                'Item 3',
                'Item 4',
                ],
                checkbox: false,
                itemsb: [
                 {
                    text: 'Usuario Nuevo (primera solicitud)',
                    disabled: true,
                    href: 'Solicitudnueva',
                  },
                  {
                    text: 'Usuario Registrado',
                    disabled: false,
                    href: 'Registrado',
                  },
                  
                 
                ],
               
                }
        
          
                    
               
                   
         },
                 

         created () {
            let me=this;
              
            me.limpiar();   
            me.selectProyectos();
           
            me._idproyecton='60c88317e86ff200901dc1ee';

         },
         
         computed: {
            passwordConfirmationRule() {
                return () => (this.password === this.confirmPassword  && this.confirmPassword != '') || 'Password must match'
            },
          
        },
        
        methods: {

           validate () {
            this.$refs.form.validate()
            },
          reset () {
            this.verNuevo=0;
            this.$refs.form.reset()
            },
            previousStep() {
                 this.step--
                },
            validateStep1() {
                console.log(this.valid1);
                this.$refs.form1.validate().then(valid1 => {
                    if (valid1) {
                    this.step++
                    }
                })
                },

                validateStep2() {
                this.$refs.form2.validate().then(valid => {
                    if (valid) {
                    // this.guardarsolicitud();
                    }
                })
                },
              resetValidation () {
                  this.$refs.form.resetValidation()
                  },
            validateDNI(num_documento) {
             
                var numero, lett, letra;
                var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;
                var dni=num_documento; 
                dni = dni.toUpperCase();

                if(expresion_regular_dni.test(dni) === true){
                    numero = dni.substr(0,dni.length-1);
                    numero = numero.replace('X', 0);
                    numero = numero.replace('Y', 1);
                    numero = numero.replace('Z', 2);
                    lett = dni.substr(dni.length-1, 1);
                    numero = numero % 23;
                    letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
                    letra = letra.substring(numero, numero+1);
                    if (letra != lett) {
                        //alert('Dni erroneo, la letra del NIF no se corresponde');
                        return false;
                    }else{
                        //alert('Dni correcto');
                        return true;
                    }
                }else{
                    //alert('Dni erroneo, formato no válido');
                    return false;
                }
              },
         
          check: function(e) {
              console.log(this.checkbox1,e);
              if (this.checkbox1==true) {
                  
                  this.listarnoProyecto(this._idproyecton);
                  this.verProyectos=1;
              } else {
                  this.proyecto=null; 
                  this.verProyectos=0;
              }
              
          },
          mostrarPro() {
            
               this.verEnviar=1;
               this.adModalNuevo=1; 
               this.validaMensajeP=[];

            },
          listarnoProyecto(id){
                let me=this;
                    console.log(id);          
                    axios.get('proyecto/query?_id='+id).then(function (response){
                        me._idproyecto=response.data._id;                                           
                        me.verNuevo=0;
                        console.log(me._idproyecto);
                    }).catch(function(error){
                        console.log(error);
                    });
            },
         
       
          validar(){
                this.valida=0;
                this.validaMensaje=[];
                   /*   if(this.tipouso.length<1){
                        this.validaMensaje.push('Debe indicar el tipo de uso');
                    } */
                    /*  if(this.servicioicts.length<1){
                        this.validaMensaje.push('Debe indicar los servicios de los que está interesado');
                    }  */
                     if(this.nombre.length<1 || this.nombre.length>50){
                        this.validaMensaje.push('Debe de indicar un nombre');
                    }
                    if(this.num_documento.length<8 || this.num_documento.length>20){
                     this.validaMensaje.push('El documento no debe tener más de 20 caracteres ni menos de 8.');                              
                    }
                     if(this.apellidos.length<1 || this.apellidos.length>250){
                        this.validaMensaje.push('Debe indicar un apellido');
                    }
                    if(this.password.length<8 || this.confirmPassword.length<8){
                        this.validaMensaje.push('Debe de indicar una contraseña con 8 caracteres como mínimo y confirmarla.');
                    } 
                  
                    if(this.centro_procedencia.length<1 && this.verProyectos==1 ){
                        this.validaMensaje.push('Debe de indicar un centro de procedencia');
                    } 
                 /*    if(this.descripcion.length>255){
                        this.validaMensaje.push('La descripción del servicio no debe tener más de 255 caracteres.');
                    } */
                  /*    if(this.nombreentidadproyecto.length<1){
                        this.validaMensaje.push('Debe seleccionar un proyecto de la lista desplegable.');
                    } */
                    
                    if (this.validaMensaje.length){
                        this.valida=1;
                    }
                    return this.valida;
            }, 
            validarPro(){
                this.valida=0;
                this.validaMensaje=[];
                             
                if(this.nombreentidadproyecto.length<1){
                    this.validaMensaje.push('Debe de indicar un nombre para el proyecto.');
                }
                if(this.responsable.length<1){
                    this.validaMensaje.push('Debe de indicar un responsable.');
                }
                if(this.emailip.length<1){
                    this.validaMensaje.push('Debe de indicar un correo IP.');
                }
                if(this.vigencia.length<1){
                    this.validaMensaje.push('Debe de indicar la vigencia del proyecto.');
                }
                 if(this.financiador.length<1){
                    this.validaMensaje.push('Debe de indicar un financiador del proyecto.');
                }
                if (this.validaMensaje.length){
                    this.valida=1;
                }

                return this.valida;
            },
          
           selectProyectos(){
                let me=this;
                let proyectosArray=[];
                    
                axios.get('proyecto/listactivos').then(function (response){
                    proyectosArray=response.data;
                    proyectosArray.map(function(x){
                        me.proyectos.push({text:x.nombreentidadproyecto.substr(0,80) + ' ... / ' + x.responsable, value:x._id});
                        
                    });
                }).catch(function(error){
                    console.log(error);
                });
            },
           
             listarProyecto(id){
       
                let me=this;
                 
                console.log(me.proyecto);         
                axios.get('proyecto/query?_id='+id).then(function (response){
                   // me.proyecto=response.data;
                     me.proyecto=response.data._id;
                  // me.proyecto=response.data._id;
                   me._idproyecto=response.data._id; 
                    // console.log(me.proyecto);
                   // me.nombreentidadproyecto=response.data.nombreentidadproyecto;                 
                    me.verNuevo=0;          
                }).catch(function(error){
                    console.log(error);
                });
            },

            limpiar(){
                    this.apellidos='';
                    this.nombre='';
                    this.email='';
                    this.emailip='';
                   // this.tipouso='';
                    this.num_documento='';
                    this.descripcion='';
                    this.servicioicts='';
                    this.nombreentidadproyecto='';
                    this.descripcion='';
                    this.responsable='';
                    this.financiador='';
                    this.resumen='';
                    this.objetivos='';
                    this.presupuesto=0;
                    this.detalles=[];
                    this.vigencia='';
                    this._idproyecto='';
                    this.dialog=0;
                    this._idusuario='';
                    this.actividad='';
                    this.centro_procedencia='';
                    this.password='';
                    this.actividad='';
                    this.verNuevo=0;
                    this.pp=0;
                    this.dialogM=0;
                    this.verProyectos=0;
                    
                /*  this.valida=0;
                    this.validaMensaje=[];  
                    this.editedIndex=-1;    */         
                },
            
            
             
            
          
             registrarUsuario(){

                let me=this;
                  if (this.validar()){
                    return;
                }
                 if (me.buscardni()) {
                   //me.limpiar();
                   return;
               
                 }      
                  console.log(me._idproyecto);
                 axios.get('proyecto/query?_id='+me._idproyecto).then(function (response){
                    
                    if (response.data.length == 0) {
                           
                       console.log("error");                  
                    }
                      else {
                         
                      
                      me.roles.push({
                           rol:'Usuario',
                          
                      }); 
                      me.detalles.push({
                           _idproyecto:response.data._id,
                           nombreentidadproyecto:response.data.nombreentidadproyecto,

                      }); 
                    
                        //console.log(me.detalles[0]._idproyecto);
                        axios.post('usuarios/add',
                    {
                        'apellidos':me.apellidos,
                        'nombre':me.nombre,
                        'password':me.password,
                        'email':me.email,
                        'num_documento':me.num_documento,
                        'actividad':me.actividad,
                        'centro_procedencia':me.centro_procedencia,
                        'roles':me.roles[0],                        
                        'detalles':me.detalles[0]
                    })
                    .then(function(response){                    
                                  
                     me.enviaravisoRegistro();
                   
                     
                       
                    })
                     .then(data => {
                             
                      me.validaMensaje=['Se completó el registro.'];                      
                      me.valida=1;
                      me.dialogM=1;
                            
                          })
                          .catch(function(error){
                              console.log(error);
                          }); 
                      
                            }
                            
                          
                      }).catch(function(error){
                          console.log(error);
                      });
                  },

             enviaravisoRegistro(){
                
                  
                let me=this;
              
                        
                axios.post('usuarios/sendmailR',{                    
                                           
                                                           
                        'apellidos':me.apellidos,
                        'nombre':me.nombre,                       
                        'email':me.email,          
                            
                     
                  }                
                  ).then(function (response){
                         
                  }).catch(function(error){
                     
                      console.log(error);
                  });
                
                
              },    
                  buscarmail(){
                    
                     

                    let me=this;
                     console.log(me.email);
                     me.valida=0;
                     me.validaMensaje=[];               
                      
                              
                    axios.get('usuarios/list?valor='+me.email).then(function (response){
                       
                       console.log(response.data);  
                      
                        
                         if (response.data.length == 0) {
                           me.valida=0;
                        } else { me.validaMensaje=['Hay un usuario registrado con este correo electrónico. Introduzca otro o vaya a la pantalla de registro.'];
                         me.valida=1;
                       // me.dialogM=1;
                      } 
                           
                                
                    }).catch(function(error){
                        console.log(error);
                    });
                    
                  
                  return me.valida;
                  }, 
       buscardni(){                    
                     
                      let me=this;                       
                       me.valida=0;
                       me.validaMensaje=[];               
                        
                                
                      axios.get('usuarios/list?valor='+me.num_documento).then(function (response){
                         
                        
                        
                          
                           if (response.data.length == 0) {
                             me.valida=0;
                          } else { me.validaMensaje=['Hay un usuario registrado con el mismo dni. Introduzca otro dni o vaya a la pantalla de registro.'];
                           me.valida=1;
                         // me.dialogM=1;
                        } 
                             
                                  
                      }).catch(function(error){
                          console.log(error);
                      });
                      
                    
                    return me.valida;
                    },   

           guardar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};

                
                 if (this.validarPro()){
                    return;
                } 
               
                    //Código para guardar
                    axios.post('proyecto/add',
                    {
                        'nombreentidadproyecto':this.nombreentidadproyecto,
                        'responsable':this.responsable,
                        'email':this.emailip,
                        'financiador':this.financiador,                                                
                        'vigencia':this.vigencia,
                        'resumen':this.resumen,
                        'estado':2,
                        'objetivos':this.objetivos
                    },configuracion)
                    .then(function(response){
                       
                        
                        me.selectProyectos();
                                                  
                        me.annadirP();
                        me.sendEmailAvisoP();
                       // me.limpiarP();
                       // me.close();
                       me.validaMensajeP=['Se enviaron los datos del proyecto a la oficina de coordinación para su aprobación. Ya se ha añadido su proyecto a lista de selección. Pulse en registrase para finalizar.'];                      
                       me.valida=1;
                       me.dialogMP=1;
                        
                      //  me.validaMensajeP=['Se enviaron los datos del proyecto. Hay que esperar a que el administrador lo apruebe.'];
                      //  me.verEnviar=0;
                       // me.validaP=1;
                       // me.dialogM=1;
                    })
                    .catch(function(error){
                        me.validaMensaje.push('Ya existe un proyecto con ese mismo nombre. ');
                        
                        me.valida=1;
                        return me.valida;
                        console.log(error);
                    });
                
                
            },
            annadirP(){                    
                     
                     let me=this;                       
                      me.valida=0;
                      me.validaMensaje=[];               
                       
                               
                     axios.get('proyecto/list?valor='+me.nombreentidadproyecto).then(function (response){
                        
                        console.log(response.data);  
                       
                        me._idproyecto=response.data[0]._id;
                        console.log(me._idproyecto);
                        me.listarProyecto(me._idproyecto);
                         
                         
                            
                                 
                     }).catch(function(error){
                         console.log(error);
                     });
                     
                   
                   
                   },   
         
            limpiarP() {
                this.nombreentidadproyecto='';                
                this.responsable='';
                this.vigencia='';
                this.financiador='';
                this.objetivos='';
                this.resumen='';
            },
            sendEmailAvisoP(){
                
                  
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};

                         
                axios.post('proyecto/sendmailP',{
                    
                    'nombreentidadproyecto':this.nombreentidadproyecto,                        
                    'responsable':this.responsable,
                    'email':this.emailip,
                    'financiador':this.financiador,                   
                    'vigencia':this.vigencia,                 
                    'quien':this.apellidos + ', ' + this.nombre  + '. mail: '  + this.email   
                     
                  },configuracion                
                  ).then(function (response){
                         
                  }).catch(function(error){
                     
                      console.log(error);
                  });
                
                
              },    
              closeR(){
                this.$router.push({ name: 'registrado' }); 
                
              },
          
                    closeS () {

                       this.step=1;
                    //  this.$refs.form1.reset();
                    //  this.$refs.form2.reset();
                      this.valid=false;
                      this.limpiar();
                      this.$router.push({ name: 'home' }); 
                    // this.step=1;
                   //   this.$refs.form1.reset();
                   //  this.$refs.form2.reset();
                    //  this.valid=false;
                  //   this.limpiar();
                  //   this.dialogMP=0;
                    //  this.adModalNuevo=0;
                     // this.$router.push({ name: 'home' }); 
                    },
                    closeP()  {
                      this.dialogMP=0;
                      this.adModalNuevo=0;
                     // this.valida=0;
                    },

            closeNuevo () {
             
             this.adModalNuevo=0;

             },  
             close () { 

            
            this.verProyectos=0;
            this.dialog = false;
              

                }
          },
                                 
                        
           
          
          
        
    }

</script>
<style>
.box{
   position:absolute;
   top: 10px;
   border: 1px solid green;
   padding: 2%;
   width: 80%;   
   left: 10%;
   margin-left: -100px;
}
 .box1{
    position: absolute;
    top: 50px;
    border: 3px solid green;
    padding: 2%;
    width: 80%;    
    left: 30%;
    margin-left: -100px;
   }
</style>