<template>
    <v-container class="box1">
      <v-row class="text-center">
        <v-col cols="12">
          <v-img
            :src="require('../assets/logo_ebd_csic.png')"
            class="my-3"
            contain
            height="200"
          />
        </v-col>
  
        <v-col class="mb-4">
          <h1 class="display-2 font-weight-bold mb-3">
            Servicios ICTS - RBD
          </h1>
  
          <p class="subheading font-weight-regular">
            Puede descargarse el manual de usuario en la siguiente dirección:
            <br>
            <a
             href="doc/manual-usuario.pdf"  
                    
              target="_blank"
            >Manual de usuario</a>
          </p>
          <v-btn 
              class="ma-2" 
              outlined 
              download
              href="doc/manual-usuario.pdf" 
             >
                  Descargar PDF
            </v-btn>
        </v-col>
  
        <v-col
          class="mb-5"
          cols="12"
        >
         
        </v-col>
  
        <v-col
          class="mb-5"
          cols="12"
        >
         
        
        </v-col>
  
       
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'manual',

    data: () => ({
     
     
     
    }),
  }
</script> 
<style>
  .box1{
    position: absolute;
    top: 50px;
    border: 3px solid green;
    padding: 2%;
    width: 800px;    
    left: 30%;
    margin-left: -100px;
   }
</style> 