 <template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar text color="white">                
                   <v-btn @click="crearPDF()">
                    <v-icon
                     small
                     color="brown"
                    class="mr-2"                   
                    >
                    mdi-printer
                    </v-icon> 
                   </v-btn>   
                   <vue-excel-xlsx v-if="tipo_anilla=='Metal'  || tipo_anilla=='PVC'"
                    :data="solicitudes"
                    :columns="columns"
                    :file-name="'solicitudes'"
                    :file-type="'xlsx'"
                    :sheet-name="'solicitudes'"
                    >
                    <v-icon
                    color="green"
                     large
                    class="mr-2"                   
                    >
                    mdi-file-excel-box-outline
                    </v-icon> 
                    
                </vue-excel-xlsx>         
                <v-toolbar-title>Solicitudes</v-toolbar-title>
                <v-divider
                class="mx-2"
                inset
                vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-text-field class="text-xs-center" v-model="search" append-icon="search" 
                label="Búsqueda" placeholder="Filtrar por nº solicitud, descripción, servicio ..." single-line hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-switch 
                    v-model="tipo_anilla"
                    color="primary"
                    hide-details
                    true-value="PVC"
                    false-value="Metal"
                    
                    :label="`Tipo de Anillas: ${tipo_anilla}`"
                    @change="changeTipo()"
               ></v-switch> 
                <v-dialog v-model="dialogeditar" max-width="600px">
                   
                   <v-card>
                       <v-card-title>
                       <span class="headline">Modificar Solicitud</span>
                      
                       <v-spacer></v-spacer>
                       <v-switch v-if="esAdministrador==1"
                            v-model="firma_responsable"
                            @change="changeState(_id)"
                        ></v-switch>                       
                        <v-switch v-if="esServicios==1 && firma_responsable == 1"
                            v-model="firma_tecnico"
                            @change="changeStatetec(_id)"
                        ></v-switch>
                        <div v-if="firma_responsable == 1 && esAdministrador==1">
                                <span class="green--text">Aceptado</span>
                            </div>
                            <div v-if="firma_responsable == 0 && esAdministrador==1">
                                <span class="red--text">Pendiente</span>
                            </div>
                       <div v-if="firma_tecnico == 1 && esServicios==1 && firma_responsable ==1">
                                <span class="green--text">Aceptado</span>
                            </div>
                            <div v-if="firma_tecnico == 0 && esServicios==1 && firma_responsable ==1">
                                <span class="red--text">Pendiente</span>
                            </div>
                            
                       </v-card-title>            
                       <v-card-text>
                       <v-container grid-list-md>
                           <v-layout wrap> 
                                                   
                            <v-spacer></v-spacer>
                               <v-flex xs3 sm3 md3>
                                   <v-text-field filled v-model="num_solicitud" label="Nº solicitud" readonly>                                        
                                   </v-text-field>
                               </v-flex>   
                                <v-flex xs5 sm5 md5>
                                   <v-text-field filled v-model="createdAt" label="Fecha" readonly>                                        
                                   </v-text-field>
                               </v-flex>  
                               <v-flex xs2 sm2 md2>
                                   <v-text-field filled v-if="verTiempo" v-model="horass" label="horas"  >                                        
                                   </v-text-field>
                               </v-flex> 
                               <v-flex xs2 sm2 md2>
                                   <v-text-field filled v-if="verTiempo" v-model="minutoss" label="minutos" >                                        
                                   </v-text-field>
                               </v-flex>                                    
                                 <v-flex xs4 sm4 md4>
                                   <v-text-field filled v-model="apellidos" label="Apellidos" readonly>                                        
                                   </v-text-field>
                               </v-flex>
                                <v-flex xs4 sm4 md4>
                                   <v-text-field filled v-model="nombre" label="Nombre" readonly>                                        
                                   </v-text-field>
                               </v-flex>      
                                 <v-flex xs4 sm4 md4>
                                   <v-text-field filled v-model="correo" label="Correo" readonly>                                        
                                   </v-text-field>
                               </v-flex>                       
                               <v-flex xs12 sm12 md12>
                                        <v-autocomplete  v-if="esAdministrador==1 || esServicios==1"
                                        :items="proyectos"                         
                                        v-model="proyecto" 
                                        dense
                                        chips
                                        clearable
                                        label="Seleccione para cambiar de proyecto. Escriba nombre del IP o parte del título."                        
                                        @change="listarTodosProyectos(proyecto)" 
                                        solo                                         
                                        >
                                        </v-autocomplete >
                                </v-flex> 
                                <v-flex xs12 sm12 md6>
                                    <h4>Servicios ICTS-RBD</h4>
                                   <v-radio-group v-if="esAdministrador==1 || esServicios==1"  v-model="servicioicts"
                                    :mandatory="false" :rules="[v => !!v || 'Item is required']" required 
                                    @change="changed">                          
                                           <v-radio label="Acceso a Colecciones" value="Colecciones" ></v-radio>
                                           <v-radio label="Acceso a Anillamiento" value="Anillamiento" ></v-radio>
                                           <v-radio label="Acceso RBD/END " value="RBD/END"></v-radio>
                                           <v-radio label="Acceso apoyo técnico o logístico de campo" value="Apoyo_Tecnico"></v-radio>
                                           <v-radio label="Acceso Datos de ICTS" value="Datos_ICTS"></v-radio>                           
                                           <v-radio label="Acceso infraestructura TIC" value="TIC"></v-radio>
                                           </v-radio-group>
                               </v-flex>   
                               <v-flex xs12 sm12 md6 v-if="esAdministrador==1 || esServicios==1" >
                                    <h4>Tipo de uso</h4>
                                                <v-radio-group v-model="tipouso" :mandatory="false" :rules="[v => !!v || 'Item is required']" required>
                                                    <v-radio label="Investigación" value="Investigador"></v-radio>
                                                    <v-radio label="Administración Pública" value="Administracion"></v-radio>
                                                    <v-radio label="Otros (ONG, Empresa, Particular etc.)" value="Otros"></v-radio>
                                                </v-radio-group>
                                            </v-flex>                                      
                                                                    
                                 <v-flex xs12 sm12 md12>
                                   <v-textarea rows="3" v-model="descripcion" label="Descripción" >                                        
                                   </v-textarea>
                               </v-flex>  
                               <v-flex xs12 sm12 md12>
                                   <v-textarea  v-if="verObservaciones" rows="3" v-model="observaciones" label="Observaciones" >                                        
                                   </v-textarea>
                               </v-flex>  
                                                        
                               <v-flex xs12 sm12 md12 v-show="valida">
                                       <div class="green darken-1"  v-for="v in validaMensaje" :key="v" v-text="v">

                                       </div>
                                   </v-flex>   
                           </v-layout>
                       </v-container>
                       </v-card-text>            
                       <v-card-actions>
                       <v-spacer></v-spacer>
                       <v-btn v-if="verActualizar==1" color="blue darken-1" text @click="actualizar">Actualizar</v-btn>
                       <v-btn color="blue darken-1" text @click="closeS">Cerrar</v-btn>
                       
                       </v-card-actions>
                   </v-card>
               </v-dialog>
               <v-dialog v-model="dialogresponder" max-width="650px">
                   
                   <v-card>
                       <v-card-title>
                       <span class="headline">Responder Solicitud</span>
                           <v-spacer></v-spacer>   
                            Adjuntar fichero 
                           
                           <v-icon 
                                x-large
                                @click="mostrardoc()"
                                >
                               mdi-attachment 
                            </v-icon>
                            <v-spacer></v-spacer>    
                           <div class="blue darken-1">
                            <span v-if="enviadodoc==1"> {{ nomfichero }}</span>
                        </div>                   
                       </v-card-title>            
                       <v-card-text>
                       <v-container grid-list-md>
                           <v-layout wrap> 
                             <v-flex xs12 sm12 md12>
                              {{ apellidos }}, {{ nombre }}  - {{ correo }}   
                              </v-flex>                   
                            <v-spacer></v-spacer>
                                      <v-flex xs6 sm6 md6>
                                   <v-text-field filled v-model="num_solicitud" label="Nº solicitud" readonly>                                        
                                   </v-text-field>
                               </v-flex>   
                                <v-flex xs6 sm6 md6>
                                   <v-text-field filled v-model="createdAt" label="Fecha" readonly>                                        
                                   </v-text-field>
                               </v-flex>                        
                                                              
                                                                   
                                 <v-flex xs12 sm12 md12>
                                   <v-textarea rows="3" v-model="descripcion" label="Descripción" readonly >                                        
                                   </v-textarea>
                               </v-flex>    
                                 <v-flex xs12 sm12 md12>
                                   <v-textarea 
                                    v-model="respuesta" 
                                    prepend-icon="mdi-tooltip-outline"
                                    :rules="respRules"
                                    required
                                    auto-grow
                                    label="Respuesta">                                        
                                   </v-textarea>
                               </v-flex>  
                                                
                               <v-flex xs12 sm12 md12 v-show="valida">
                                       <div class="green darken-1"  v-for="v in validaMensaje" :key="v" v-text="v">

                                       </div>
                                   </v-flex>   
                           </v-layout>
                       </v-container>
                       </v-card-text>            
                       <v-card-actions>
                       <v-spacer></v-spacer>
                       <v-btn   :disabled="respuesta==''" v-if="verResponder==1" color="blue darken-1" text @click="responder">Enviar</v-btn>
                       <v-btn color="blue darken-1" text @click="closeS">Cerrar</v-btn>
                       
                       </v-card-actions>
                   </v-card>
               </v-dialog> 
               <v-dialog v-model="dialoghistorico" max-width="650px">
                   
                   <v-card>
                       <v-card-title>
                       <span class="headline">Añadir comentario</span>
                           <v-spacer></v-spacer>   
                                              
                       </v-card-title>            
                       <v-card-text>
                       <v-container grid-list-md>
                           <v-layout wrap> 
                             <v-flex xs12 sm12 md12>
                              {{ apellidos }}, {{ nombre }}  - {{ correo }}   
                              </v-flex>                   
                            <v-spacer></v-spacer>
                                      <v-flex xs6 sm6 md6>
                                   <v-text-field filled v-model="num_solicitud" label="Nº solicitud" readonly>                                        
                                   </v-text-field>
                               </v-flex>   
                                <v-flex xs6 sm6 md6>
                                   <v-text-field filled v-model="createdAt" label="Fecha" readonly>                                        
                                   </v-text-field>
                               </v-flex>                        
                                                              
                                                                   
                               <v-flex xs12 sm12 md12>
                                   <v-textarea rows="4" v-model="descripcion" label="Descripción" readonly >                                        
                                   </v-textarea>
                               </v-flex> 
                              
                               
                               <v-flex xs12 sm12 md12>
                                <v-expansion-panels  v-model="panelOpen">
                                  <v-expansion-panel>
                                        <v-expansion-panel-header>
                                            Ver Histórico
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                        <v-textarea rows="10" v-model="historico" label="Histórico" readonly >                                        
                                   </v-textarea>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    </v-expansion-panels>
                               </v-flex>     
                                 <v-flex xs12 sm12 md12>
                                   <v-textarea 
                                    v-model="respuesta" 
                                    prepend-icon="mdi-tooltip-outline"
                                    :rules="respRules"
                                    required
                                    auto-grow
                                    label="Añadir comentario">                                        
                                   </v-textarea>
                               </v-flex>  
                                                
                               <v-flex xs12 sm12 md12 v-show="valida">
                                       <div class="green darken-1"  v-for="v in validaMensaje" :key="v" v-text="v">

                                       </div>
                                   </v-flex>   
                           </v-layout>
                       </v-container>
                       </v-card-text>            
                       <v-card-actions>
                       <v-spacer></v-spacer>
                       <v-btn   :disabled="respuesta==''" v-if="verResponder==1" color="blue darken-1" text @click="annadirComentario">Añadir comentario</v-btn>
                       <v-btn color="blue darken-1" text @click="closeS">Cerrar</v-btn>
                       
                       </v-card-actions>
                   </v-card>
               </v-dialog>
               <v-dialog v-model="dialoganillas" max-width="850px">
                   
                   <v-card>
                       <v-card-title>
                       <span class="headline">Ver - Modificar pedido de anillas</span>
                           <v-spacer></v-spacer>   
                                              
                       </v-card-title>            
                       <v-card-text>
                       <v-container grid-list-md>
                           <v-layout wrap> 
                             <v-flex xs12 sm12 md12>
                              {{ apellidos }}, {{ nombre }}  - {{ correo }}   
                              </v-flex>                   
                            <v-spacer></v-spacer>
                                      <v-flex xs6 sm6 md6>
                                   <v-text-field filled v-model="num_solicitud" label="Nº solicitud" readonly>                                        
                                   </v-text-field>
                               </v-flex>   
                                <v-flex xs6 sm6 md6>
                                   <v-text-field filled v-model="createdAt" label="Fecha" readonly>                                        
                                   </v-text-field>
                               </v-flex>                        
                               <v-data-table v-if="tipo_anilla=='Metal'" 
                                :headers="cabeceraDetallesAnillasMetal"
                                :items="detalleanilla"
                                hide-default-footer
                                disable-pagination
                                class="elevation-1"
                            >
                                <template v-slot:body="{ items }">
                                    <tbody>
                                    <tr v-for="item in items" :key="item.num_solicitud"> 
                                        <td>
                                           <v-icon
                                            small
                                            class="mr-2"
                                            @click="eliminarDetalle(detalleanilla,item)"
                                            >
                                            mdi-delete
                                            </v-icon>
                                        </td>
                                        
                                         
                                        <td class="text-xs-center">{{ item.tipo }}</td>
                                        <td class="text-xs-center"><v-text-field v-model="item.modelo" ></v-text-field></td>                                       
                                        <td class="text-xs-center"><v-text-field v-model="item.cantidad" min=1 type="number"></v-text-field></td>                                       
                                        <td class="text-xs-center"><v-text-field v-model="item.cantidadentregada" type="number"></v-text-field></td>  
                                     </tr>

                              </tbody>  
                                </template>
                                <template slot="no-data">
                                    <h3>No hay anillas.</h3>
                                </template>
                                               
                           
                            </v-data-table >
                            <v-data-table v-if="tipo_anilla=='PVC'" 
                                :headers="cabeceraDetallesAnillasPVC"
                                :items="detalleanilla"
                                hide-default-footer
                                disable-pagination
                                class="elevation-1"
                            >
                                <template v-slot:body="{ items }">
                                    <tbody>
                                    <tr v-for="item in items" :key="item.num_solicitud"> 
                                        <td>
                                           <v-icon
                                            small
                                            class="mr-2"
                                            @click="eliminarDetalle(detalleanilla,item)"
                                            >
                                            mdi-delete
                                            </v-icon>
                                        </td>
                                        
                                         
                                        <td class="text-xs-center">{{ item.tipo }}</td>
                                       
                                        <td class="text-xs-center"><v-text-field v-model="item.especie"></v-text-field></td>
                                        <td class="text-xs-center"><v-text-field v-model="item.tipo_PVC"></v-text-field></td>
                                        <td class="text-xs-center"><v-text-field v-model="item.cantidad"  type="number"></v-text-field></td> 
                                        <td class="text-xs-center"><v-text-field v-model="item.cantidadentregada" type="number"></v-text-field></td>                                       
                                        <td class="text-xs-center"><v-text-field v-model="item.codigos"></v-text-field></td>
                                     </tr>

                              </tbody>  
                                </template>
                                <template slot="no-data">
                                    <h3>No hay anillas.</h3>
                                </template>
                                               
                           
                            </v-data-table>                              
                                                                   
                             
                                                            
                                                            
                                                
                               <v-flex xs12 sm12 md12 v-show="valida">
                                       <div class="green darken-1"  v-for="v in validaMensaje" :key="v" v-text="v">

                                       </div>
                                   </v-flex>   
                           </v-layout>
                       </v-container>
                       </v-card-text>            
                       <v-card-actions>
                       <v-spacer></v-spacer>
                       <v-btn  color="blue darken-1" text @click="modificarpedAnillas">Modificar pedido</v-btn>
                       <v-btn color="blue darken-1" text @click="closeAnillas">Cerrar</v-btn>
                       
                       </v-card-actions>
                   </v-card>
               </v-dialog>
               <v-dialog v-model="dialogderivar" max-width="650px">
                   
                   <v-card>
                       <v-card-title>
                       <span class="headline">Derivar Solicitud</span>
                           <v-spacer></v-spacer>  
                           <v-flex xs12 sm6 md6>
                                        <v-autocomplete  
                                        :items="usuarios"                         
                                        v-model="usuariode" 
                                        :rules="[v => !!v || 'Elija un usuario de la lista desplegable.']" required
                                        dense
                                        chips
                                        label="Derivar al usuario:"                        
                                        @change="listarUsuario(usuariode)" 
                                        solo                                         
                                        >
                                        </v-autocomplete >    

                            </v-flex>    
                                       
                       </v-card-title>            
                       <v-card-text>
                       <v-container grid-list-md>
                           <v-layout wrap> 
                             <v-flex xs12 sm12 md12>
                              {{ apellidos }}, {{ nombre }}  - {{ correo }}   
                              </v-flex>                   
                            <v-spacer></v-spacer>
                                      <v-flex xs6 sm6 md6>
                                   <v-text-field filled v-model="num_solicitud" label="Nº solicitud" readonly>                                        
                                   </v-text-field>
                               </v-flex>   
                                <v-flex xs6 sm6 md6>
                                   <v-text-field filled v-model="createdAt" label="Fecha" readonly>                                        
                                   </v-text-field>
                               </v-flex>                        
                                                              
                                                                   
                                 <v-flex xs12 sm12 md12>
                                   <v-textarea rows="3" v-model="descripcion" label="Descripción" readonly >                                        
                                   </v-textarea>
                               </v-flex>    
                                 <v-flex xs12 sm12 md12>
                                   <v-textarea 
                                    v-model="respuesta" 
                                    prepend-icon="mdi-tooltip-outline"
                                    :rules="respRules"
                                    required
                                    auto-grow
                                    label="Texto">                                        
                                   </v-textarea>
                               </v-flex>  
                                                
                               <v-flex xs12 sm12 md12 v-show="valida">
                                       <div class="green darken-1"  v-for="v in validaMensaje" :key="v" v-text="v">

                                       </div>
                                   </v-flex>   
                           </v-layout>
                       </v-container>
                       </v-card-text>            
                       <v-card-actions>
                       <v-spacer></v-spacer>
                       <v-btn   :disabled="respuesta==''" v-if="verResponder==1 && emailusuario !=''" color="blue darken-1" text @click="derivar">Enviar</v-btn>
                       <v-btn color="blue darken-1" text @click="closeS">Cerrar</v-btn>
                       
                       </v-card-actions>
                   </v-card>
               </v-dialog>
               <v-dialog v-model="dialogcambiarusuario" max-width="650px">
                   
                   <v-card>
                       <v-card-title>
                        
                       <span es class="headline">Cambiar usuario</span>
                           <v-spacer></v-spacer>  
                           <v-flex   xs6 sm6 md6>
                          {{ apellidos }}, {{ nombre }} 
                              </v-flex>    
                                       
                       </v-card-title>            
                       <v-card-text>
                       <v-container grid-list-md>
                           <v-layout wrap> 
                                         
                            <v-spacer></v-spacer>
                                      <v-flex xs6 sm6 md6>
                                   <v-text-field filled v-model="num_solicitud" label="Nº solicitud" readonly>                                        
                                   </v-text-field>
                               </v-flex>   
                                <v-flex xs6 sm6 md6>
                                   <v-text-field filled v-model="createdAt" label="Fecha" readonly>                                        
                                   </v-text-field>
                               </v-flex>     
                               <v-flex xs12 sm12 md12>
                                        <v-autocomplete  
                                        :items="usuarios"                         
                                        v-model="usuariode" 
                                        :rules="[v => !!v || 'Elija un usuario de la lista desplegable.']" required
                                        dense
                                        chips
                                        label="Elija un usuario para cambiarlo en esta solicitud."                        
                                        @change="listarUsuarioCambiar(usuariode)" 
                                        solo                                         
                                        >
                                        </v-autocomplete >    

                            </v-flex>                 
                                                          
                                                                  
                                                                           
                               <v-flex xs12 sm12 md12 v-show="valida">
                                       <div class="green darken-1"  v-for="v in validaMensaje" :key="v" v-text="v">

                                       </div>
                                   </v-flex>   
                           </v-layout>
                       </v-container>
                       </v-card-text>            
                       <v-card-actions>
                       <v-spacer></v-spacer>
                       <v-btn v-if="verResponder==1"  color="blue darken-1" text @click="cambiarUs">Cambiar usuario</v-btn>
                       <v-btn color="blue darken-1" text @click="closeS">Cerrar</v-btn>
                       
                       </v-card-actions>
                   </v-card>
               </v-dialog>
                <v-dialog v-model="adModal" max-width="290">
                    <v-card>
                        <v-card-title class="headline" v-if="adAccion==1">
                            Activar Item
                        </v-card-title>
                        <v-card-title class="headline" v-if="adAccion==2">
                            Desactivar Item
                        </v-card-title>
                        <v-card-text>
                            Estás a punto de <span v-if="adAccion==1">Activar </span>
                            <span v-if="adAccion==2">Anular </span> el item {{adNombre}}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="activarDesactivarCerrar()" color="green darken-1" text="text">
                                Cancelar
                            </v-btn>
                            <v-btn v-if="adAccion==1" @click="activar()" color="orange darken-4" text="text">
                                Activar
                            </v-btn>
                            <v-btn v-if="adAccion==2" @click="desactivar()" color="orange darken-4" text="text">
                                Anular
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog
                        v-model="dialogM"
                        width="350"
                    >
                    
                
                        <v-card>
                        <v-card-title class="text-h8 green lighten-2">
                            Solicitud {{this.num_solicitud}}
                        </v-card-title>
                
                        <v-card-text>
                            <v-flex xs12 sm12 md12 v-show="valida">
                                <div  v-for="v in validaMensaje" :key="v" v-text="v">

                                </div>
                            </v-flex>   
                        </v-card-text>
                
                        <v-divider></v-divider>
                
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                            color="primary"
                            
                            text
                            @click="closeS"
                            >
                            Cerrar
                            </v-btn>
                        </v-card-actions>
                        </v-card>
                    
                    </v-dialog>
                    <v-dialog v-model="verModalFecha" max-width="290">
                    <v-card>
                        <v-card-title class="headline">
                             Cambiar Fecha
                        </v-card-title>
                       
                        <v-card-text>
                            Estás a punto de <span >Cambiar </span>
                            el item: 
                              <p>Nº solicitud:  {{num_solicitud}}<br>                                                                              
                                 Fecha: {{getHumanDate(fecha)}}</p>
                             
                            <v-flex xs12 sm12 md12 v-show="valida">
                                    <div class="red--text" v-for="v in validaFecha" :key="v" v-text="v">

                                    </div>
                                </v-flex>

                      
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                             <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                            <v-btn  @click="cambiarFechaCal()" color="orange darken-4" text="text">
                                Cambiar Fecha
                            </v-btn>
                           
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="verModalFin" max-width="490">
                    <v-card>
                        <v-card-title class="headline">
                             Finalizar solicitud
                        </v-card-title>
                       
                        <v-card-text>
                          
                              <p>Nº solicitud:  {{num_solicitud}}                                                                             
                               -  Fecha: {{getHumanDate(fecha)}}</p>
                                 <v-flex xs12 sm12 md12>
                                   <v-textarea 
                                    v-model="observaciones" 
                                    prepend-icon="mdi-tooltip-outline"
                                    :rules="respRules"
                                    required
                                    auto-grow
                                    label="Observaciones">                                        
                                   </v-textarea>
                               </v-flex>  
                             
                            <v-flex xs12 sm12 md12 v-show="valida">
                                    <div class="red--text" v-for="v in validaFecha" :key="v" v-text="v">

                                    </div>
                                </v-flex>

                      
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                             <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                            <v-btn :disabled="observaciones==''" @click="cambiarFechaFin()" color="orange darken-4" text="text">
                                Finalizar
                            </v-btn>
                           
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="adModalF" max-width="290">
                    <v-card>
                        <v-card-title class="headline" v-if="adAccionF==1">
                            Finalizar 
                        </v-card-title>
                        <v-card-title class="headline" v-if="adAccionF==2">
                            Activar
                        </v-card-title>
                        <v-card-text>
                            Estás a punto de <span v-if="adAccionF==1">Finalizar </span>
                            <span v-if="adAccionF==2">Activar</span> la solicitud {{adNombre}}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="activarDesactivarCerrarF()" color="green darken-1" text="text">
                                Cancelar
                            </v-btn>
                            <v-btn v-if="adAccionF==1" @click="activarF()" color="orange darken-4" text="text">
                                Finalizar
                            </v-btn>
                            <v-btn v-if="adAccionF==2" @click="desactivarF()" color="orange darken-4" text="text">
                                Activar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="verModalFechafin" max-width="400">
                    <v-card>
                        <v-card-title  class="headline">
                            Nº solicitud:  {{num_solicitud}}<br>
                         
                        </v-card-title>
                       
                        <v-card-text>
                            <h4>  Elija fecha de finalización:</h4>
                         
                            <v-date-picker v-model="picker"></v-date-picker>
                           

                      
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                             <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                            <v-btn  @click="cambiarFechaFinn()" color="orange darken-4" text="text">
                                Aceptar
                            </v-btn>
                           
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="verModalFechacal" max-width="400">
                    <v-card>
                        <v-card-title class="headline">
                            Nº solicitud:  {{num_solicitud}}<br>
                        </v-card-title>
                       
                        <v-card-text>
                           
                         
                            <v-date-picker v-model="picker"></v-date-picker>
                           

                      
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                             <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                            <v-btn  @click="cambiarFecha()" color="orange darken-4" text="text">
                                Cambiar
                            </v-btn>
                           
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                 <v-dialog v-model="verModalTiempo" max-width="400">
                    <v-card>
                        <v-card-title >
                           
                            Nº solicitud:  {{num_solicitud}}<br>
                            <p>Tiempo empleado:</p>
                        </v-card-title>
                       
                        <v-card-text>
                           
                            <v-flex xs6 sm6 md6>
                                    <v-text-field 
                                    v-model="horas"
                                    min="0"                                    
                                    type="number" 
                                    placeholder="0 - Nº de horas"
                                    label="Horas">                                        
                                    </v-text-field>
                                </v-flex>    
                                 <v-flex xs6 sm6 md6>
                                  <v-text-field 
                                    v-model="minutos"
                                    min="0"
                                    max="60"
                                    type="number"
                                    label="Minutos"
                                ></v-text-field >
                                </v-flex>          
                          
                      
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                             <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                            <v-btn  @click="cambiarTiempo()" color="orange darken-4" text="text">
                                Aceptar
                            </v-btn>
                           
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogdoc" max-width="500px" max-height="600px" margin-bottom="1px;" >
                       <v-card>
                           <v-card-title>Adjunte el fichero</v-card-title>
                           
                         <v-card-text>
                            <v-alert outlined x-small type="info">
                               Si necesita adjuntar más de un fichero. Comprímalo primero en un zip o war. (Max 3 MB)</v-alert>
                         <v-container grid-list-md>
                        <label for="uploads">Elija documento</label> <br>
                        <v-file-input type="file"
                        show-size
                        :rules="rulesf"
                        placeholder="Seleccione un fichero"
                         id="uploads" 
                         v-model = "selectedFile"
                          @change="onFileChange">
                        </v-file-input> 
                        <v-spacer></v-spacer>
                        <!-- <button :disabled="!selectedFile" color="blue darken-1" @click="deletedoc">Borrar</button> -->
                               
                        </v-container>
                        </v-card-text>
                        
                        <v-card-action>
                         
                          <v-flex xs12 sm12 md12 v-show="valida">
                        <div class="brown--text" v-for="v in validaDoc" :key="v" v-text="v" style="border: 1px solid black;margin:10px;">

                        </div>
                        </v-flex>   
                         <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closedialogdoc">Cerrar</v-btn>
                        <v-btn :disabled="!selectedFile"  @click="onUploadFile"  color="blue darken-1"
                        >Subir Fichero</v-btn>
                         
                      
                        </v-card-action>
                       </v-card>
                       <v-spacer></v-spacer>
                     </v-dialog>    
            </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="solicitudes"
                :search="search"
                class="elevation-1"
            >
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-for="item in items" :key="item.num_solicitud"> 
                                                
                     
                <td>{{ getHumanDate(item.createdAt) }}</td>     
                <td>{{ item.num_solicitud }}</td>                
                <td>{{ item.usuario.apellidos }}</td>                
                <td>{{ item.usuario.nombre }}</td>
                <td>{{ item.proyecto.nombreentidadproyecto }}</td>
                <td>{{ item.servicioicts }}</td>
                <td>{{ item.descripcion.substr(0,200) + ' ...  ' }}</td>
                <td>
                    <div v-if="item.firma_responsable == 1 && item.nomresponsable !=null">
                        <span class="green--text">Aceptado por: {{ item.nomresponsable.nombre }} {{ item.nomresponsable.apellidos }}</span>
                     
                    </div>
                    <div v-else>
                        <span class="red--text">Pendiente</span>
                    </div>
                </td>
                 <td>
                    <div v-if="item.firma_tecnico == 1 && item.nomtecnico !=null">
                        <span class="green--text">Aceptado por: {{ item.nomtecnico.nombre }} {{ item.nomtecnico.apellidos }}</span>
                    </div>
                    <div v-else>
                        <span class="red--text">Pendiente</span>
                    </div>
                </td>       
               
                
                
                <td>
                    <div v-if="item.estado == 1 &&  (item.firma_responsable==0 || item.firma_tecnico==0) ">
                        <span class="blue--text">Solicitado</span>
                    </div>
                    <div v-if="item.estado == 1 &&  item.firma_responsable==1 && item.firma_tecnico==1 ">
                        <span class="orange--text">En curso</span>
                    </div>
                   
                    <div v-if="item.estado == 3">
                        <span class="brown--text">Finalizado el {{ getHumanDateC(item.fechafin) }}</span>
                    </div>
                   <div v-if="item.estado == 4">
                        <span class="darkblue--text">Respuesta enviada</span>
                    </div>
                                            
                    <div v-if="item.estado == 0">
                        <span class="red--text">Anulada</span>
                    </div>
                </td>
                <td>   
                    <template v-if="item.estado!=0" >

                   <v-tooltip slot="append" location="top">    
                    <template v-slot:activator="{ on }">        
                    <v-icon                     v-on="on"
                      
                    small
                    class="mr-2"
                    large color="blue darken-2"                    
                    @click="editItem(item)"                    
                    >
                    mdi-pencil                 
                           
                    </v-icon >
                </template>
                <span>Editar / Modificar Solicitud</span>
                </v-tooltip>
            </template>
                <template v-if="item.estado!=3 && item.estado!=0" > 
                <v-tooltip slot="append" location="top">   
                    <template v-slot:activator="{ on }">   
                   <v-icon 
                    v-on="on"
                    small
                    class="mr-2"
                    large color="orange darken-2"
                    @click="responderUsuario(item)"
                    >
                    mdi-email-outline
                    </v-icon>
                </template>
                <span>Responder al usuario por correo</span>
                </v-tooltip>
            </template>
            <template v-if="item.firma_responsable==1  && item.estado!=0" > 
                <v-tooltip slot="append" location="top">   
                    <template v-slot:activator="{ on }">   
                   <v-icon 
                    v-on="on"
                    small
                    class="mr-2"
                    large color="green-darken-3"
                    @click="annadirCom(item)"
                    >
                    mdi-message-outline
                    </v-icon>
                </template>
				<span>Añadir comentario / ver histórico</span>
                </v-tooltip>
            </template>
              <template  v-if="tipo_anilla"> 
                <v-tooltip slot="append" location="top">   
                    <template v-slot:activator="{ on }">   
                   <v-icon 
                    v-on="on"
                    small
                    class="mr-2"
                    large color="green-darken-3"
                    @click="modAnillas(item)"
                    >
                    mdi-omega
                    </v-icon>
                </template>
				<span>Ver - Modificar pedido de anillas</span>
                </v-tooltip>
            </template>
                <template v-if="item.firma_tecnico==0 && item.estado!=3 && item.estado!=0">
               <v-tooltip slot="append" location="top">     
                    <template v-slot:activator="{ on }">  
                    <v-icon 
                    v-on="on"
                    small
                    class="mr-2"
                    large color="teal darken-2"
                    @click="derivarUsuario(item)"
                    >
                    mdi-account-multiple
                    </v-icon>
                </template>
                <span>Derivar esta solicitud a un técnico administardor de servicios</span>
                </v-tooltip>
            </template>
                <template v-if="item.estado!=0 && item.estado!=3">
               <v-tooltip slot="append" location="top">     
                    <template v-slot:activator="{ on }">   
                     <v-icon 
                       v-on="on"
                       small
                       class="mr-2"
                       large color="teal darken-2"
                       @click="cambiarF(item)"
                        >
                        today
                    </v-icon>  
                </template>
                <span>Cambiar la fecha a la solicitud</span>
                </v-tooltip>
            </template>
                    <template v-if="item.estado !=0 && item.estado !=3">
                     <v-tooltip slot="append" location="top"> 
                    <template v-slot:activator="{ on }">    
                        <v-icon
                        v-on="on"
                        small
                        class="mr-2"
                        large color=" red accent-2"
                        @click="activarDesactivarMostrar(2,item)"
                        >
                        mdi-delete
                        </v-icon>
                    </template>
                <span>Anular la solicitud</span>
                </v-tooltip>
                    </template>
                     <template v-if="item.estado==0">
                       <v-tooltip slot="append" location="top"> 
                        <template v-slot:activator="{ on }">  
                        <v-icon 
                        v-on="on"
                        small
                        @click="activarDesactivarMostrar(1,item)"
                        >
                        mdi-check-bold
                        </v-icon> 
                    </template>
                <span>Activar la solicitud</span>
                </v-tooltip>  
                     </template>   
                     
                     <template v-if="(item.estado==3)"> 
                       <v-tooltip slot="append" location="top"> 
                    <template v-slot:activator="{ on }">   
                     <v-icon 
                       v-on="on"
                        small
                        @click="activarDesactivarF(2,item)"
                        >
                        refresh
                      </v-icon>  
                    </template>
                <span>Activar la solicitud</span>
                </v-tooltip>
                      </template>
                      <template v-if="item.firma_responsable==1  && item.estado!=3 && item.estado!=0">
                        <v-tooltip slot="append" location="top">     
                                <template v-slot:activator="{ on }">  
                                <v-icon 
                                v-on="on"
                                small
                                class="mr-2"
                                large color="blue darken-2"
                                @click="cambiarUsuario(item)"
                                >
                                mdi-account-remove-outline
                                </v-icon>
                            </template>
                            <span>Cambiar el usuario de la solicitud</span>
                            </v-tooltip>
                        </template>
                      <template v-if="(item.estado!=3 && item.firma_responsable==1 && item.firma_tecnico==1 && item.estado!=0 )">  
                      <v-tooltip slot="append" location="top"> 
                    <template v-slot:activator="{ on }">                                    
                     <v-icon                      
                         v-on="on"
                         class="mr-2"
                         color="red"
                       
                        @click="finalizar(item)"
                        >                                            
                        mdi-close-circle-outline                       
                    </v-icon>  
                       </template>
                <span>Finalizar la solicitud</span>
                </v-tooltip>
                <template v-if="(item.estado!=3 && item.firma_responsable==1 && item.firma_tecnico==1 && !item.tiempo && item.estado!=0)">  
                   <v-tooltip slot="append" location="top"> 
                    <template v-slot:activator="{ on }"> 
                    <v-icon                      
                      v-on="on" 
                        class="mr-2"
                       
                      
                       @click="vtiempo(item)"
                       >                                            
                       mdi-camera-timer                   
                   </v-icon>
                </template>
                   <span>Estimar tiempo de resolución de la solicitud</span>
                </v-tooltip>
            </template>   
            
            </template>      
                    </td>    
                 </tr>

                  </tbody>                 
                </template>
                <template v-slot:no-data>
                <v-btn color="primary" @click="listar()">Resetear</v-btn>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>

<script>
    import axios from 'axios'    
    import jsPDF from 'jspdf'
    import moment from 'moment'
   

    import autoTable from 'jspdf-autotable'
    
    export default {
        data(){
            return{
                dialog: false,
                search:'',
                solicitudes:[],
                
                headers: [
                    { text: 'Fecha', value: 'createdAt', sortable: true},
                    { text: 'Nº sol',value: 'num_solicitud', sortable: true},
                    { text: 'Apellidos',value: 'usuario.apellidos', sortable: true},
                    { text: 'Nombre', value: 'usuario.nombre'},
                    { text: 'Proyecto',value: 'proyecto.nombreentidadproyecto', sortable: true}, 
                    { text: 'Servicio',value: 'servicioicts', sortable: true}, 
                    { text: 'Descripción',value: 'descripcion', sortable: false},  
                    { text: 'Firma del responsable',value: 'nomresponsable', sortable: false},                    
                    { text: 'Firma del técnico',value: 'nomtecnico', sortable: false},                                                  
                    { text: 'Estado', value: 'estado', sortable: false },
                    { text: 'Opciones', value: 'opciones', sortable: false }             
                    
                ],
                columns : [
                {
                        label: "Fecha",
                        field: "createdAt",
                        dataFormat: this.getHumanDateC

                    },
                    {
                        label: "nº solicitud",
                        field: "num_solicitud",
                    },
                    {
                        label: "Usuario",
                        field: "usuario",
                        dataFormat: this.validaNulo
                    },
                  
                    {
                        label: "Proyecto",
                        field: "proyecto.nombreentidadproyecto",
                    },
                  
                    {
                        label: "Datos de envío",
                        field: "descripcion",
                        dataFormat: this.getDescripcion
                    },
                    {
                        label: "Datos de facturación",
                        field: "descripcion",
                        dataFormat: this.getFacturacion
                    },
                    {
                            label: "Detalles de anillas",
                            field: "detallesanillas",
                            dataFormat: this.getDetallesAnillas
                     },
                     {
                            label: "Cantidad entregada",
                            field: "detallesanillas",
                            dataFormat: this.getDetallesAnillascantentregada
                     },
                    {
                        label: 'Responsable',
                        field: 'nomresponsable',
                        dataFormat: this.validaNulo
                        },
                        {
                        label: 'Técnico',
                        field: 'nomtecnico',
                        dataFormat: this.validaNulo
                        },
                        {
                        label: 'Tiempo (minutos)',
                        field: 'tiempo',
                       
                        },
                       
                       
                   
                
                ],
                cabeceraDetallesAnillasMetal:[
                    { text: 'Borrar', value: 'borrar', sortable: false },
                    { text: 'Tipo', value: 'tipo', sortable: false },
                    { text: 'Modelo', value: 'modelo', sortable: false },                   
                    { text: 'Cantidad', value: 'cantidad', sortable: false },
                    { text: 'Cantidad entregada', value: 'cantidadentregada', sortable: false }
                   
                ],
                cabeceraDetallesAnillasPVC:[
                    { text: 'Borrar', value: 'borrar', sortable: false },
                    { text: 'Tipo', value: 'tipo', sortable: false },                  
                    { text: 'Especie', value: 'especie', sortable: false },
                    { text: 'Tipo PVC', value: 'tipo_PVC', sortable: false },
                    { text: 'Cantidad', value: 'cantidad', sortable: false },
                    { text: 'Cantidad entregada', value: 'cantidadentregada', sortable: false },
                    { text: 'Códigos', value: 'codigos', sortable: false }
                   
                ],
                editedIndex: -1,
                _id:'', 
                _idproyecto:'',
                _idproyecton:'',
                _idusuario:'',
                tipouso:'',              
                nombreentidadproyecto:'',
                apellidos:'',
                nombre:'',
                correo:'',
                num_solicitud:'',
                responsable:'',
                servicioicts:'',
                descripcion:'', 
                email:'',
                panelOpen:0,
                firma_responsable:0,
                nomresponsable:null,
                firma_tecnico:0,
                nomtecnico:null,
                nomresponsable:'',
                esAdministrador:0,
                esServicios:0,
                createdAt:null,  
                proyecto:'',
                usuarios:[],   
                usuariode:null,  
                proyectos:[],          
                proyectosus:[],                         
                valida:0,                
                validaMensaje:[],
                validaMensajeP:[],
                validaFecha:[],
                validaDoc:[],
                enviadodoc:0,
                dialogeditar:0,
                adModal:0,
                adAccion:0,
                enlacepdf:'',
                verNuevo:0,
                verActualizar:0,
                verDatos:0,
                adModalPro:0,  
                adModalProN:0, 
                adModalF:0, 
                adModal:0,
                adAccion:0,
                adAccionF:0,
                adNombre:'',
                adId:'',
                verCantidad:0,
                verModalFecha:0,
                verModalFechacal:0,
                verModalFin:0,
                verModalTiempo:0,
                adModalDatos:0, 
                dialogM:0,
                adNombre:'',
                verResponder:0,
                nomfichero:'',
                dialogresponder:0,
                dialoghistorico:0,
                dialoganillas:0,
                dialogderivar:0,
                apellidosde:'',
                nombrede:'',
                dialogcambiarusuario:0,
                emailusuario:'',
                dialogdoc:0,
                respuesta:'',
                valid: true,
                fecha:null,              
                selectedFile:null,
                fechafin:null,
                observaciones:'',
                verObservaciones:0,
                verModalFechafin:0,
                horas:0,
                minutos:0,
                horass:0,
                minutoss:0,
                tiempo:0,
                verTiempo:0,
                cantidad:0,
                cantidadentregada:0,
                busqDesc:'',
                enviarcorreoSerrvicio:0,
                numsolicitud:'',
                historico:'',
                tipo_anilla:'', 
                codigos:'',
                detalleanilla:[],
                _iddetalleanilla:'', 
                respRules: [
                v => !!v || 'required',
                
                ],
                peRules: [
                  v => !!v || 'Elija un proyecto de la lista desplegable',
                  
                ],
                rulesf: [
                value => {
                    return !value || !value.length || value[0].size < 3000000 || 'File size should be less than 2 MB!'
                },
                ],
                picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                adId:''
            }
        },
        computed: {
            formTitle () {
            return this.editedIndex === -1 ? 'Nuevo registro' : 'Ver registro'
            }
        },
        watch: {
            dialog (val) {
            val || this.close()
            }
        },
        created () {
          
            this.rol=this.$store.state.rol;
            this.id=this.$store.state.usuario._id;
           // this.nomresponsable=this.$store.state.usuario.apellidos + ', ' + this.$store.state.usuario.nombre;
            console.log(this.rol);
            
            if (this.rol=="Administrador"){
                this.esAdministrador=1;
                this.listar();
                this.listarTodosUsuarios();
               
            } else {
                 this.esServicios=1;
                 this.listarporServicios();  
                 this.listarusrol();                             
                 
            }            
                                
           
        },
        methods: {

            getHumanDate : function (date) {
                        return moment(date).local().format('DD/MM/YYYY, h:mm a');
            },
            getHumanDateC : function (date) {
                        return moment(date).local().format('DD/MM/YYYY');
            },
            convertirTiempo(minutos) {
                if (typeof minutos != 'number' || !Number.isInteger(minutos)) {
                    throw TypeError('El argumento debe ser un número entero.');
                }

                if (minutos < 0) {
                    return null;
                }
                console.log(minutos);
              //  let horas = Math.floor(minutos / 60);
                 this.horass = Math.floor(minutos / 60);
                 minutos = minutos % 60;               
                
                 this.minutoss=minutos;
                
            },
            getDescripcion(value) {
               
                const startIndex = value.indexOf("Datos de envío:");
                const endIndex = value.indexOf("Datos de facturación:");
                const startIndexf = value.indexOf("Datos de envío:");
             if (this.tipo_anilla=='Metal') {  
               
                if (startIndexf !== -1) {
                    return value.substring(startIndexf + 15);
                } else {
                    return "N/A";
                }
            } else { if (this.tipo_anilla=='PVC') {
                if (startIndex !== -1 && endIndex !== -1 && endIndex > startIndex) {
                    return value.substring(startIndex + "Datos de envío:".length, endIndex).trim();
                } else {
                    return "N/A";
                }


            }
            }

                },
          /*   getDescripcion(value) {
                const startIndex = value.indexOf("Datos de envío:");
                const endIndex = value.indexOf("Datos de facturación:");
                if (startIndex !== -1 && endIndex !== -1 && endIndex > startIndex) {
                    return value.substring(startIndex + "Datos de envío:".length, endIndex).trim();
                } else {
                    return "N/A";
                }
             }, */
            getFacturacion(value) {
                const startIndex = value.indexOf("Datos de facturación:");
                    if (startIndex !== -1) {
                        return value.substring(startIndex + 21);
                    } else {
                        return "N/A";
                    }
                },
            getDetallesAnillas(value) {

                if (this.tipo_anilla=='Metal') {
                if (value && value.length > 0) {
                    const detalles = value.map(anilla => {
                    return `${anilla.modelo}, ${anilla.cantidad}`;
                    });
                    return detalles.join(' / '); // Une los detalles de las anillas con saltos de línea
                } else {
                    return 'N/A'; // O cualquier valor que desees mostrar cuando no hay datos
                }
             } else {  if (this.tipo_anilla=='PVC') {
                if (value && value.length > 0) {
                    const detalles = value.map(anilla => {
                    return `${anilla.especie}, ${anilla.cantidad}, ${anilla.tipo_PVC}`;
                    });
                    return detalles.join(' / '); // Une los detalles de las anillas con saltos de línea
                } else {
                    return 'N/A'; // O cualquier valor que desees mostrar cuando no hay datos
                }

               } 
               }
                },
                getDetallesAnillascantentregada(value) {

                if (this.tipo_anilla=='Metal') {
                if (value && value.length > 0) {
                    const detalles = value.map(anilla => {
                    return `${anilla.modelo}, ${anilla.cantidadentregada}`;
                    });
                    return detalles.join(' / '); // Une los detalles de las anillas con saltos de línea
                } else {
                    return 'N/A'; // O cualquier valor que desees mostrar cuando no hay datos
                }
                } else {  if (this.tipo_anilla=='PVC') {
                if (value && value.length > 0) {
                    const detalles = value.map(anilla => {
                    return `${anilla.especie}, ${anilla.cantidadentregada}, ${anilla.codigos} `;
                    });
                    return detalles.join(' / '); // Une los detalles de las anillas con saltos de línea
                } else {
                    return 'N/A'; // O cualquier valor que desees mostrar cuando no hay datos
                }

                } 
                }
                },

            changeTipo() {
               
              
                 this.listarporServicios(); 
             
               
                if (this.tipo_anilla=='Metal') {
                   
                 this.busqDesc='Tipo de anillas: Metal'
                 setTimeout(() => {
                  this.listarporDescripcion();
                }, 200); 
                } else {  if (this.tipo_anilla=='PVC') {                
                
                 this.busqDesc='Tipo de anillas: PVC'
                 setTimeout(() => {
                 this.listarporDescripcion();
                }, 200); 
            }  
        }
             
            },
            validaNulo(value) {
                if (value === null) {
                         return 'sin firmar';
                } else {
                return value.apellidos + ', ' + value.nombre;              
                              
                }
            },
          
            crearPDF(){
                var columns =[
                    {title: "Fecha", dataKey: "createdAt"},
                    {title: "Nº de solicitud", dataKey: "num_solicitud"},
                    {title: "Apellidos", dataKey: "apellidos"},
                    {title: "Nombre", dataKey: "nombre"},  
                    {title: "Proyecto", dataKey: "nombreentidadproyecto"},                   
                    {title: "Servicio", dataKey: "servicioicts"}
                   
                   
                ];
                   
               
                var rows=[];
              

                this.solicitudes.map(function(x){
                   
                    rows.push({
                       
                        createdAt:moment(x.createdAt).local().format('DD/MM/YYYY, h:mm a'),
                        num_solicitud:x.num_solicitud,
                        apellidos:x.usuario.apellidos,
                        nombre:x.usuario.nombre, 
                        nombreentidadproyecto:x.proyecto.nombreentidadproyecto,                      
                        servicioicts:x.servicioicts
                      
                        
                        }
                    );
                });                
                var doc = new jsPDF('l','pt');
                doc.autoTable(columns,rows,{
                    margin: {top: 60},
                    didDrawPage: function(data) {
                        doc.text("Solicitudes", 40, 30);
                    }
                });

                doc.save('Solicitudes.pdf');
            },
            finalizar(item) {
              this._id=item._id;
              this.num_solicitud=item.num_solicitud; 
              this.fecha=item.createdAt; 
              this.historico=item.historico;
               
              this.verModalFin=1;

            },

            vtiempo(item){
              this._id=item._id;
              this.num_solicitud=item.num_solicitud; 
              this.fecha=item.createdAt; 
              this.horas=0;
              this.minutos=0; 
              this.historico=item.historico;
              this.verModalTiempo=1;

            },
          
            cambiarF(item){
              this._id=item._id;
              this.num_solicitud=item.num_solicitud; 
              this.fecha=item.createdAt; 

              this.verModalFecha=1;
            },
            cambiarFechaCal(){
             this.verModalFechacal=1;
            },
            cambiarFechaFin(){
             this.verModalFechafin=1;
            },
              cambiarFecha(){
                let me=this; 
                console.log(me.picker);
              
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                axios.put('solicitud/updatefe',
                {
                    '_id':this._id,
                    'fecha':this.picker
                   
                   
                },configuracion)
                .then(function(response){
                   // me.limpiar();
                   
                    //this.listar();
                    if (me.esAdministrador) {
                            me.listar();
                        }
                        if (me.esServicios) {
                            me.listarporServicios();
                            me.listaranillas();
                        }
                        me.close();
                })
                .catch(function(error){
                     
                     me.validaFecha.push('error en la fecha. Fecha con formato incorrecto');                     
                     me.valida=1;
                     return me.valida; 
                     console.log(error);
                });
             
            },

            changed() {
             this.enviarcorreoSerrvicio=1;
            },
            
            enviaravisoServicio(){
                
                  
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                let para='';
                
               

                 if (me.servicioicts=='Colecciones'){
                  me.para='colecciones@ebd.csic.es';
                } 
                if (me.servicioicts=='Anillamiento'){
                    me.para='anillamientoebd@ebd.csic.es';
                } 
                if (me.servicioicts=='RBD/END'){
                  me.para='guyonne@ebd.csic.es';
                } 
                if (me.servicioicts=='Apoyo_Tecnico'){
                    me.para='jbustamante@ebd.csic.es';
                } 
                if (me.servicioicts=='Datos_ICTS'){
                    me.para='antonio.martinez@ebd.csic.es';
                } 
                if (me.servicioicts=='TIC'){
                  me.para='joaquin.cobos@ebd.csic.es';
                } 
                
                    
                axios.post('solicitud/sendmailS',{                    
                                           
                                                           
                    'num_solicitud':me.numsolicitud, 
                    'apellidos':me.apellidos,                   
                    'nombre':me.nombre,     
                    'email':me.mailpro, 
                    'mail':me.correo,             
                    'servicioicts':me.para,
                    'descripcion':me.descripcion          
                          
                     
                  }                
                  ,configuracion).then(function (response){
                    setTimeout(() => {
                            me.$refs.form.reset();
                            
                        }, 3000);
                      
                  }).catch(function(error){
                     
                      console.log(error);
                  });
                
                
              },    
        
            cambiarFechaFinn(){
                let me=this; 
                console.log(me.picker);
                let actor ='';
                actor=  'usuario que finaliza: ' + this.$store.state.usuario.apellidos + ',  ' + this.$store.state.usuario.nombre;
                me.historico = me.historico  + '------------------------------------------------------------' + '\n' + this.observaciones + ', Fecha: ' + this.picker + '\n'
                me.historico= me.historico + actor + '\n';
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                axios.put('solicitud/updatefefin',
                {
                    '_id':this._id,
                    'observaciones':this.observaciones,
                    'fecha':this.picker
                   
                   
                },configuracion)
                .then(function(response){
                    axios.put('solicitud/respenviada',{'_id':me._id,  'historico':me.historico})
                    .then(function(response){
                                  
                                
                    },configuracion)
                    .catch(function(error){
                        console.log(error);
                    });  
                    if (me.esAdministrador) {
                            me.listar();
                        }
                       else {
                            me.listarporServicios();
                            me.listaranillas();
                        }
                        me.close();
                })
                .catch(function(error){
                     
                     me.validaFecha.push('error en la fecha. Fecha con formato incorrecto');                     
                     me.valida=1;
                     return me.valida; 
                     console.log(error);
                });
             
            },
            cambiarTiempo(){
                let me=this; 
                let t=0;
                let actor='';
                let fechahoy= new Date();
                let now = fechahoy.toLocaleDateString('es-ES');
                actor=  this.$store.state.usuario.apellidos + ',  ' + this.$store.state.usuario.nombre;
              
                t=me.horas*60;
                
                t = t + parseInt(me.minutos);
                me.historico = me.historico  + '----------------------------------------------------------------------------------------------' + '\n';
                me.historico = me.historico + actor + '. Ha estimado la resolución  de esta solicitud en: ' + t + ' minutos' + ', Fecha: ' + now + '\n'
                 
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                axios.put('solicitud/updatetiempo',
                {
                    '_id':this._id,
                    'tiempo':t
                    
                   
                },configuracion)
                .then(function(response){
                    axios.put('solicitud/respenviada',{'_id':me._id,  'historico':me.historico})
                    .then(function(response){
                                  
                                
                    },configuracion)
                    .catch(function(error){
                        console.log(error);
                    });  
                   me.validaMensaje=['Se imputó el tiempo correctamente: ' + me.horas + ' horas ' + me.minutos + ' minutos'];
                       me.valida=1;

                     me.dialogM=1;
                    if (me.esAdministrador) {
                            me.listar();
                        }
                        if (me.esServicios) {
                            me.listarporServicios();
                            me.listaranillas();
                        }
                       // me.close();
                })
                .catch(function(error){
                     
                    
                });
                

            },
           
                
            listarTodosUsuarios() {
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                let usuariosArray=[];
                          
                axios.get('usuarios/listactivos',configuracion).then(function (response){
                   
                    usuariosArray=response.data;  
                    usuariosArray.map(function(x){
                        me.usuarios.push({text:x.apellidos + ', ' + x.nombre, value:x._id});
                        
                    });               
                }).catch(function(error){
                    console.log(error);
                });

              

            },
           
            listarusrol(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                let usuariosArray=[];
                
                          
                axios.get('usuarios/listrol?valor='+me.rol,configuracion).then(function (response){
                    usuariosArray=response.data;  
                    usuariosArray.map(function(x){
                        me.usuarios.push({text:x.apellidos + ', ' + x.nombre, value:x._id});
                        
                    });                   
                }).catch(function(error){
                    console.log(error);
                });

            },
            listarUsuario(id) {
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                          
                  
                      
                axios.get('usuarios/query?_id='+id,configuracion).then(function (response){
                    //me.proyecto=response.data;
                    me.emailusuario=response.data.email; 
                    me.apellidosde=response.data.apellidos;
                    me.nombrede=response.data.nombre;
                    console.log(me.emailusuario);    
                }).catch(function(error){
                    console.log(error);
                });

            },
            listarUsuarioCambiar(id) {
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                          
                  
                      
                axios.get('usuarios/query?_id='+id,configuracion).then(function (response){
                    //me.proyecto=response.data;
                    me.usuario=response.data; 
                    me.apellidos=response.data.apellidos;
                    me.nombre=response.data.nombre;
                    me.correo=response.data.email;
                    me.verResponder=1;
                    console.log(me.usuario);    
                }).catch(function(error){
                    console.log(error);
                });

            },
            listar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};  
                          
                axios.get('solicitud/list',configuracion).then(function (response){
                    me.solicitudes=response.data;  
                    console.log(me.solicitudes);                  
                }).catch(function(error){
                    console.log(error);
                });

            },
          /*   listarporServicios(){
                let me=this;
                //let _id=this.$store.state.usuario._id;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};  
               console.log(me.rol);                       
                axios.get('solicitud/listserv?valor='+me.rol,configuracion).then(function (response){
                    me.solicitudes=response.data;  
                    console.log(me.solicitudes);                       
                                                
                   
                 });               
                       
                         
               
            }, */
            listarporServicios(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};  
                          
                axios.get('solicitud/listanillas',configuracion).then(function (response){
                    me.solicitudes=response.data;  
                    console.log(me.solicitudes);                  
                }).catch(function(error){
                    console.log(error);
                });

            },
            listarporDescripcion(){
                let me=this;
               
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};  
               console.log(me.rol);                       
                axios.get('solicitud/listdesc?valor='+me.busqDesc,configuracion).then(function (response){
                    me.solicitudes=response.data;  
                    console.log(me.solicitudes);                       
                                                
                   
                 });               
                       

            },
            listarporUsuarios(_idusuario){
                
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};        
                axios.get('solicitud/list?valor='+_idusuario,configuracion).then(function (response){
                    me.solicitudes=response.data;  
                    console.log(me.solicitudes);                       
                }).catch(function(error){
                    console.log(error);
                });
               
            },   
            listarTodosProyectos(id){
               
               let me=this;
               let header={"Token" : this.$store.state.token};
               let configuracion= {headers : header};
               me.validaMensajeP=[];
               me.validaP=0;
               
              
               me.sw=false;
               console.log(me.proyecto);  
               axios.get('proyecto/query?_id='+id,configuracion).then(function (response){
                  me._idproyecto=response.data._id;
                  console.log(me._idproyecto);
                  me.nombreentidadproyecto = response.data.nombreentidadproyecto;                               
                                     
                        
              }).catch(function(error){
                  console.log(error);
              });
             },
             
             selectProyectos(){
              let me=this;
              let header={"Token" : this.$store.state.token};
              let configuracion= {headers : header};
              let proyectosArray=[];
                  
              axios.get('proyecto/listactivos',configuracion).then(function (response){
                  proyectosArray=response.data;
                 
                  proyectosArray.map(function(x){
                      me.proyectos.push({text:x.nombreentidadproyecto.substr(0,80) + ' ... / ' + x.responsable, value:x._id});
                      
                  });
              }).catch(function(error){
                  console.log(error);
              });
          },

          eliminarDetalle(arr,item){
                let i=arr.indexOf(item);
                if (i!= -1){
                    arr.splice(i,1);
                }
            },  
            limpiar(){
                    this.email='';
                    this.tipouso='';
                    this.descripcion='';
                    this.servicioicts='';
                    this.nombreentidadproyecto='';
                    this.descripcion='';
                    this.responsable='';
                    this.financiador='';
                    this.resumen='';
                    //this.rol='';
                    this.objetivos='';
                   /*  this.firma_responsable=0;
                    this.firma_tecnico=0; */
                    this.presupuesto=0;
                    this.detalles=[];
                    this.vigencia='';
                    this._idproyecto='';
                    this._idusuario='';
                    this.password='';
                    this.verNuevo=0;
                    this.num_solicitud='';
                    this.createdAt=null;
                    this.pp=0;
                    this.sw=false;
                 //   this.dialogeditar=0;
                //    this.dialogonueva=0;
                    this.validaMensaje=[];
                    this.validaMensajeP=[];
                    this.validaDoc=[];
                    this.proyecto='';
                    this.valid=false;
                    this.verActualizar=0;
                    this.adNombre='';
                    this.verDatos=0;
                    this.veranadirpusuario=0;
                    this.checkbox1=false;
                    this.verProyectos=0;
                    this.verModalFecha=0;
                    this.verModalFechacal=0;
                    this.validaFecha=[];
                    this.verResponder=0;             
                    this.dialogresponder=0;
                    this.dialoghistorico=0;                    
                    this.dialogdoc=0;
                    this.nomfichero='';
                    this.observaciones='';
                    this.verObservaciones=0;
                    this.selectedFile=null;
                    this.usuariode=null;
                  //  this.dialogM=0;
                   
            },
            validar(){
                this.valida=0;
                this.validaMensaje=[];
                             
                if(this.nombreentidadproyecto.length<1){
                    this.validaMensaje.push('Debe de indicar un nombre para el proyecto.');
                }
                if(this.responsable.length<1){
                    this.validaMensaje.push('Debe de indicar un responsable.');
                }
                if(this.vigencia.length<1){
                    this.validaMensaje.push('Debe de indicar la vigencia del proyecto.');
                }
                 if(this.financiador.length<1){
                    this.validaMensaje.push('Debe de indicar un financiador del proyecto.');
                }
                return this.valida;
            },
            guardar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                
                if (this.validar()){
                    return;
                }
                if (this.editedIndex >-1){
                    //Código para editar
                    axios.put('proyecto/update',
                    {
                        '_id':this._id,
                        'nombreentidadproyecto':this.nombreentidadproyecto,
                        'responsable':this.responsable,
                        'financiador':this.financiador,                                                
                        'vigencia':this.vigencia,
                        'resumen':this.resumen,
                        'objetivos':this.objetivos

                    },configuracion)
                    .then(function(response){
                        me.limpiar();
                        me.close();
                        if (me.esAdministrador) {
                            me.listar();
                        }
                        if (me.esServicios) {
                            me.listarporServicios();
                            me.listaranillas();
                        }
                       // me.listar();
                    })
                    .catch(function(error){
                        console.log(error);
                    });
                }else{
                    //Código para guardar
                    axios.post('proyecto/add',
                    {
                        'nombreentidadproyecto':this.nombreentidadproyecto,
                        'responsable':this.responsable,
                        'financiador':this.financiador,                                                
                        'vigencia':this.vigencia,
                        'resumen':this.resumen,
                        'objetivos':this.objetivos
                    },configuracion)
                    .then(function(response){
                        me.limpiar();
                        me.close();
                        if (me.esAdministrador) {
                            me.listar();
                        }
                        if (me.esServicios) {
                            me.listarporServicios();
                            me.listaranillas();
                        }
                       // me.listar();
                    })
                    .catch(function(error){
                        me.validaMensaje.push('Ya existe un artículo con ese mismo nombre. ');
                        
                        me.valida=1;
                        return me.valida;
                        console.log(error);
                    });
                }
            },
            obtenerusuario() {
                this.usuario=this.$store.state.usuario;
                this.nombre=this.usuario.nombre;
                this.rol=this.usuario.rol;
                
                this.apellidos=this.usuario.apellidos;
                this._idproyecto=this.usuario.detalles[0]._id;
                this._idusuario=this.usuario._id;
                console.log(this._idusuario);
                this.listarDetallesUsuario(this._idusuario);
                
            },
            listarDetallesUsuario(id){
                      let me=this;
                      let header={"Token" : this.$store.state.token};
                      let configuracion= {headers : header};
                      console.log(id);
                      let proyectosArray=[];
                      me.proyectosus=[];          
                      axios.get('usuarios/query?_id='+id,configuracion).then(function (response){
                          me.detallesUsuario=response.data.detalles;                                                                                       
                          proyectosArray=me.detallesUsuario;                       
                                             
                          console.log(proyectosArray);
                         
                          proyectosArray.map(function(x){
                              me.proyectosus.push({text:x.nombreentidadproyecto.substr(0,100) + ' ...  ',  value:x._idproyecto});
                      });
                          
                      }).catch(function(error){
                          console.log(error);
                      });
                  },
                
            editItem (item) {
                this._id=item._id;
                this.verActualizar=0;
                this.selectProyectos(); 
                console.log(this.proyectos);
                this.createdAt=moment(item.createdAt).local().format('DD/MM/YYYY, h:mm a');                          
                // this.nombreentidadproyecto=(item.proyecto.nombreentidadproyecto).substr(0,50)  + ' ...  ';                 
                this.usuario=item.usuario; 
                // this.proyecto=item.proyecto;  
              
                this.proyecto=item.proyecto._id;  
               /*  if (this.proyecto=='60c88317e86ff200901dc1ee') { 
                  //  this.verProyectos=1;
                  this.validaMensaje=['No es un proyecto de investigación. Algunos servicios de la ICTS-RBD pueden no estar disponibles'];
                    this.valida=1;
                    
                }  */
                this.numsolicitud=item.num_solicitud;
                this.apellidos=item.usuario.apellidos;
                this.nombre=item.usuario.nombre; 
                this.correo=item.usuario.email;              
                this.servicioicts=item.servicioicts;
                this.tipouso=item.tipouso;
                this.descripcion=item.descripcion;
                this.num_solicitud=item.num_solicitud;
                this.firma_responsable=item.firma_responsable;
                this.firma_tecnico=item.firma_tecnico;
                this.nomresponsable=item.nomresponsable;
                this.nomtecnico=item.nomtecnico;    
                if (item.observaciones)  {     
                  this.observaciones=item.observaciones; 
                  this.verObservaciones=1; 
                   } else {
                     this.observaciones='';
                  }   
                  if (item.tiempo)  {     
                  this.tiempo=item.tiempo;
                  this.convertirTiempo(this.tiempo);
                 
                  this.verTiempo=1; 
                   } else {
                     this.tiempo=0;
                  }   
                  if (item.estado !=3) {  
                this.verActualizar=1; }           
                this.dialogeditar=1;
            },
            derivarUsuario (item) {

                this._id=item._id;
                this.respuesta='';
                this.usuariode=null;
                this.createdAt=moment(item.createdAt).local().format('DD/MM/YYYY, h:mm a'); 
                this.apellidos=item.usuario.apellidos;
                this.nombre=item.usuario.nombre; 
                this.correo=item.usuario.email;  
                this.nomresponsable=item.nomresponsable;
                this.servicioicts=item.servicioicts;
                this.nomtecnico=item.nomtecnico;   
                this.descripcion=item.descripcion;
                this.historico=item.historico;
                this.num_solicitud=item.num_solicitud; 
                this.verResponder=1;   
                if (this.rol=="Administrador"){
                
                this.listarTodosUsuarios();
               
            } else {
                 
                 this.listarusrol();                             
                 
            }    
               // this.verResponder=1;             
                this.dialogderivar=1; 

              },
              cambiarUsuario (item) {

                this._id=item._id;
               
                this.usuariode=null;
                this.createdAt=moment(item.createdAt).local().format('DD/MM/YYYY, h:mm a'); 
                this.apellidos=item.usuario.apellidos;
                this.nombre=item.usuario.nombre; 
                this.correo=item.usuario.email;  
                this.nomresponsable=item.nomresponsable;
                this.servicioicts=item.servicioicts;
                this.nomtecnico=item.nomtecnico;   
                this.descripcion=item.descripcion;
                this.num_solicitud=item.num_solicitud; 
               // this.verResponder=1;   
               

                this.listarTodosUsuarios();                                         
                
                  
                // this.verResponder=1;             
                this.dialogcambiarusuario=1; 

                },
         cambiarUs() {
            let me=this; 
                console.log(me.picker);
            
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                axios.put('solicitud/updateus',
                {
                    '_id':this._id,
                    'usuario':this.usuario,
                    
                   
                },configuracion)
                .then(function(response){
                   // me.limpiar();
                   
                    //this.listar();
                    if (me.esAdministrador) {
                            me.listar();
                        }
                       else {
                            me.listarporServicios();
                            me.listaranillas();
                        }
                        me.validaMensaje=['Se cambió correctamente al usuario:  ' + me.apellidos + ', ' + me.nombre];
                        me.valida=1;
                        me.verResponder=0;
                        me.dialogM=1;
                })
                .catch(function(error){
                     
                     me.validaMansaje.push('error');                     
                     me.valida=1;
                     return me.valida; 
                     console.log(error);
                }); 

         },      
          responderUsuario (item) {

             this._id=item._id;
             this.respuesta='';
             this.createdAt=moment(item.createdAt).local().format('DD/MM/YYYY, h:mm a'); 
             this.apellidos=item.usuario.apellidos;
             this.nombre=item.usuario.nombre; 
             this.correo=item.usuario.email;  
             this.nomresponsable=item.nomresponsable;
             this.servicioicts=item.servicioicts;
             this.nomtecnico=item.nomtecnico;   
             this.descripcion=item.descripcion;
             this.num_solicitud=item.num_solicitud; 
             this.historico=item.historico;
             this.verResponder=1;             
             this.dialogresponder=1; 

            },
            annadirCom (item) {

                this._id=item._id;
                this.respuesta='';
                this.createdAt=moment(item.createdAt).local().format('DD/MM/YYYY, h:mm a'); 
                this.apellidos=item.usuario.apellidos;
                this.nombre=item.usuario.nombre; 
                this.correo=item.usuario.email;  
                this.nomresponsable=item.nomresponsable;
                this.servicioicts=item.servicioicts;
                this.nomtecnico=item.nomtecnico;   
                this.descripcion=item.descripcion;
                this.num_solicitud=item.num_solicitud; 
                this.historico=item.historico;
                this.panelOpen=false;
                this.verResponder=1;             
                this.dialoghistorico=1; 

            },
            modAnillas(item) {

                this._id=item._id;
            
                this.createdAt=moment(item.createdAt).local().format('DD/MM/YYYY, h:mm a'); 
                this.apellidos=item.usuario.apellidos;
                this.nombre=item.usuario.nombre; 
                this.correo=item.usuario.email;  
                // this.detallesanillas.item.detallesanillas; 
                this.listarDetalles(item._id);
               
                this.num_solicitud=item.num_solicitud; 
                 console.log(this.detalleanilla);
                    
                this.dialoganillas=1; 

             },
           
             listarDetalles(id){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};            
                axios.get('solicitud/query?_id='+id,configuracion).then(function (response){
                    me.detalleanilla=response.data.detallesanillas; 
                  
                    console.log(me.detalleanilla);                 
                }).catch(function(error){
                    console.log(error);
                });
            },
            modificarpedAnillas() {

                let me=this; 
              
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
              
                console.log(me.detalleanilla); 
                axios.put('solicitud/updateanillas',
                {
                    '_id':me._id,
                    'detallesanillas':me.detalleanilla
                    
                   
                },configuracion)
                .then(function(response){
                   me.validaMensaje=['Se modificó correctamente  esta solicitud.'];
                       me.valida=1;

                     me.dialogM=1;
                    if (me.esAdministrador) {
                            me.listar();
                        }
                        if (me.esServicios) {
                            me.listarporServicios();
                            me.listaranillas();
                        }
                       // me.close();
                })
                .catch(function(error){
                     
                    
                });
                


            },
            

            annadirComentario(){
                let me=this; 
                let fechahoy= new Date();
                let now = fechahoy.toLocaleDateString('es-ES');
                let actor = '';
                actor=  'usuario que hace el comentario: ' + this.$store.state.usuario.apellidos + ',  ' + this.$store.state.usuario.nombre; 
                me.historico = me.historico  + '--------------------------------------------------------------------------------------------' + '\n' + me.respuesta + ', Fecha: ' + now + '\n';
                me.historico= me.historico + actor + '\n';
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                axios.put('solicitud/updatehistorico',
                {
                    '_id':this._id,
                    'historico':me.historico
                    
                   
                },configuracion)
                .then(function(response){
                   me.validaMensaje=['Se añadió el cometario correctamente a esta solicitud.'];
                       me.valida=1;

                     me.dialogM=1;
                    if (me.esAdministrador) {
                            me.listar();
                        }
                        if (me.esServicios) {
                            me.listarporServicios();
                            me.listaranillas();
                        }
                       // me.close();
                })
                .catch(function(error){
                     
                    
                });
                

            },
            responder() {
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                let nomfich='';
                let actor='';
                let fechahoy= new Date();
                let now = fechahoy.toLocaleDateString('es-ES');
                actor=  'usuario que responde: ' + this.$store.state.usuario.apellidos + ',  ' + this.$store.state.usuario.nombre; 
                me.historico = me.historico  + '---------------------------------------------------------------------------' + '\n' + me.respuesta + ', Fecha: ' + now + '\n'
                me.historico= me.historico + actor + '\n';
                me.historico=me.historico + 'usuario al que se ha enviado el correo: ' + me.apellidos + ', ' + me.nombre + '\n';
                

              
                if (me.servicioicts=='Colecciones'){
                 me.para='colecciones@ebd.csic.es';
               } 
               if (me.servicioicts=='Anillamiento'){
                   me.para='balbontin@ebd.csic.es';
               } 
               if (me.servicioicts=='RBD/END'){
                 me.para='guyonne@ebd.csic.es';
               } 
               if (me.servicioicts=='Apoyo_Tecnico'){
                   me.para='jbustamante@ebd.csic.es@ebd.csic.es';
               } 
               if (me.servicioicts=='Datos_ICTS'){
                   me.para='antonio.martinez@ebd.csic.es';
               } 
               if (me.servicioicts=='TIC'){
                 me.para='joaquin.cobos@ebd.csic.es';
               } 
               if (me.nomfichero=='') {
                  me.nomfich='Sin-adjunto';
               }else {
                 me.nomfich=me.nomfichero; 
                 me.historico = me.historico + 'se adjuntó el siguiente fichero: ' + me.nomfich + '\n';
                  }
              if (me.nomtecnico==null) {
                 me.nomtecnico=me.$store.state.usuario;
              }
              
                axios.post('solicitud/sendmailResp',{                    
                                         
                              
                    'num_solicitud':me.num_solicitud, 
                    'apellidos':me.apellidos,                   
                    'nombre':me.nombre,   
                    'mail':me.correo,    
                    'para':me.para,      
                    'descripcion':me.descripcion,
                    'tecnico':me.nomtecnico,
                    'nomfichero':me.nomfich,
                    'respuesta':me.respuesta         
                            
                
                }                
                ,configuracion).then(function (response){
                  
                   
                })
                     .then(data => {
                        axios.put('solicitud/respenviada',{'_id':me._id,  'historico':me.historico})
                    .then(function(response){
                                  
                                
                    },configuracion)
                    .catch(function(error){
                        console.log(error);
                    });
                       me.validaMensaje=['Se envío la respuesta correctamente a: ' + me.apellidos + ', ' + me.nombre];
                       me.valida=1;
                       me.verResponder=0;
                       if (me.esAdministrador) {
                            me.listar();
                       } else if (me.esServicios) {
                            me.listarporServicios();
                            me.listaranillas();
                        }
                       // me.listar();
                       me.dialogM=1;
                      
                       
                }).catch(function(error){
                
                    console.log(error);
                });
                  

            },
            derivar() {
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                let nomfich='';
                let fechahoy= new Date();
                let now = fechahoy.toLocaleDateString('es-ES');
                let actor = '';
                actor=  'usuario que deriva: ' + this.$store.state.usuario.apellidos + ',  ' + this.$store.state.usuario.nombre;
                me.respuesta=me.respuesta + '(se derivó al usuario: ' + me.apellidosde + ', ' + me.nombrede + ')'
                me.historico = me.historico  + '--------------------------------------------------------------------------------' + '\n' + me.respuesta + ', Fecha: ' + now + '\n'
                me.historico= me.historico + actor + '\n';         
              
              
                axios.post('solicitud/sendmailDeriv',{                    
                                         
                              
                    'num_solicitud':me.num_solicitud, 
                    'apellidos':me.apellidos,                   
                    'nombre':me.nombre, 
                     
                    'para':me.emailusuario,      
                    'descripcion':me.descripcion,
                   
                    'respuesta':me.respuesta         
                            
                
                }                
                ,configuracion).then(function (response){                  
                   
                     
                      // axios.put('solicitud/respenviada',{'_id':me._id,  'historico':me.historico}); 
                    axios.put('solicitud/respenviada',{'_id':me._id,  'historico':me.historico})
                    .then(function(response){
                                  
                                
                    },configuracion)
                    .catch(function(error){
                        console.log(error);
                    });       
                       me.validaMensaje=['Se envió correctamente el correo a: ' + me.emailusuario];
                       me.valida=1;
                       me.verResponder=0;
                       if (me.esAdministrador) {
                            me.listar();
                       } else if (me.esServicios) {
                            me.listarporServicios();
                            me.listaranillas();
                        }
                       // me.listar();
                       me.dialogM=1;
                      
                       
                }).catch(function(error){
                
                    console.log(error);
                });
                  

            },

           mostrardoc(){
                let me=this; 

              //  me.deletedoc();
               
                me.dialogdoc=1;
                
            },
            onFileChange(e) {
               
                //const selectedFile = this.fileNames.files[0]; // accessing file
              //  this.selectedFile = selectedFile;
                console.log(this.selectedFile);
                 // si el fichero es mayor que 3 MB
                 if (this.selectedFile.size>3097152) {
                  this.enviadodoc=2;
                  
                  this.validarDoc();
                  this.deletedoc();
                 }else{
                   this.validaDoc=[];
                  
                 

                } 
                
            },
            deletedoc(){
                document.getElementById("uploads").value=null; 
                this.selectedFile=null;
               // this.enviadodoc=0;
               
                },

             onUploadFile() {    
               let me=this;
              
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};  
                const formData = new FormData();
                formData.append("file", this.selectedFile);  // appending file
                console.log(this.selectedFile);
               
              //  me.enlacepdf = this.selectedFile.name;
                axios.post('upload/upload', formData, configuracion).then(function (response){
                   console.log('SUCCESS!!');
                   me.nomfichero=me.selectedFile.name;
                   me.selectedFile=null;                  
                   me.enviadodoc=1;
                   me.validarDoc();
                  
                   
                   //me.closedialogdoc();          
               }).catch(function(error){
                    me.enviadodoc=0;
                    me.selectedFile=null;
                    me.validarDoc();
                    console.log(error);
                    console.error(error);
                });
            },
            validarDoc(){
                this.valida=0;
                this.validaDoc=[];
              
               if(this.enviadodoc==1){
                    this.validaDoc.push('El documento se ha adjuntado correctamente');
                } 
                else if(this.enviadodoc==2){this.validaDoc.push('El tamaño del fichero no puede ser mayor de 3 MB.') } else{this.validaDoc.push('Ha habido un problema. El documento no se ha adjuntado correctamente.')}
                if (this.validaDoc.length){
                    this.valida=1;
                }
               
                return this.valida;
            },

           changeState(id) {
                console.log("Switch "+id);
                // actualizar en solicitudes el nombre que esta firmando.
                if (this.firma_responsable){
                   this.nomresponsable=this.$store.state.usuario;
                }else{
                    this.nomresponsable=null;   
                }
                console.log(this.nomresponsable);

            },
             changeStatetec(id) {
                console.log("Switch "+id);
                // actualizar en solicitudes el nombre que esta firmando.
                if (this.firma_tecnico){
                   this.nomtecnico=this.$store.state.usuario;
                }else{
                    this.nomtecnico=null;   
                }
                console.log(this.nomtecnico);

            }, 
            actualizar(){
                
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                let tiem = 0;
               // me.buscarmail();
               if (me.proyecto==null) { 
                    //  me.proyecto='60c88317e86ff200901dc1ee'
                    me.validaMensaje=['Seleccione un proyecto antes de actualizar.'];
                    me.valida=1;
                   // me.dialogM=1;
                    // me.verActualizar=0;
                    return;
                     
                    
                }
                tiem=me.horass * 60 + parseInt(me.minutoss);
                console.log(tiem);
                 axios.put('solicitud/update',
                    {
                        '_id':me._id,                       
                        'proyecto':me.proyecto, 
                        'servicioicts':me.servicioicts,
                        'tipouso':me.tipouso,
                        'firma_responsable':me.firma_responsable,
                        'firma_tecnico':me.firma_tecnico,
                        'nomresponsable':me.nomresponsable,
                        'nomtecnico':me.nomtecnico,
                        'descripcion':me.descripcion,
                        'tiempo':tiem,
                        'observaciones':me.observaciones                      
                        
                    },configuracion)
                    .then(function(response){
                      if (me.enviarcorreoSerrvicio) {
                         me.enviaravisoServicio();
                      }
                      
                    })
                     .then(data => {
                      
                      // me.validaMensaje=['La solicitud se actualizó correctamente.'];
                       if (me.enviarcorreoSerrvicio){
                         me.validaMensaje=['La solicitud se actualizó correctamente. Se envió un correo al Servicio'];
                        } else{
                            me.validaMensaje=['La solicitud se actualizó correctamente.'];   
                        }
                       
                       me.valida=1;
                       me.verActualizar=0;
                       if (me.esAdministrador) {
                            me.listar();
                       } else if (me.esServicios) {
                            me.listarporServicios();
                           me.listaranilllas();
                        }
                       // me.listar();
                       me.dialogM=1;
                      
                      })
                    .catch(function(error){
                        console.log(error);
                        me.validaMensaje=['Se ha producido un error al actualizar la solicitud: ' + error];
                        me.valida=1;
                        me.dialogM=1;
                    });
               
            },
            listaranillas() {   
                if (this.tipo_anilla=='Metal') {
                                
                                this.busqDesc='Tipo de anillas: Metal'
                                setTimeout(() => {
                                this.listarporDescripcion();
                                }, 200); 
                                } else {  if (this.tipo_anilla=='PVC') {                
                                
                                this.busqDesc='Tipo de anillas: PVC'
                                setTimeout(() => {
                                this.listarporDescripcion();
                                }, 200); 
                            }  
                        }
                },
            activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adNombre=item.nombreentidadproyecto;
                this.adId=item._id;
                if (accion==1){
                    this.adAccion=1;
                } else if(accion==2){
                    this.adAccion=2;
                } else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
            },
            activar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
               
                axios.put('solicitud/activate',{'_id':this.adId})
                    .then(function(response){
                        me.adModal=0;
                        me.adAccion=0;
                        me.adNombre='';
                        me.adId='';
                        if (me.esAdministrador) {
                            me.listar();
                        }
                        else {
                            me.listarporServicios();
                            me.listaranillas();
                        }
                       
                    },configuracion)
                    .catch(function(error){
                        console.log(error);
                    });
            },
            desactivar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                
                axios.put('solicitud/deactivate',{'_id':this.adId},configuracion)
                    .then(function(response){
                        me.adModal=0;
                        me.adAccion=0;
                        me.adNombre='';
                        me.adId='';
                        if (me.esAdministrador) {
                            me.listar();
                        }
                        else {
                            me.listarporServicios();
                            me.listaranillas();
                        }
                       // me.listar();
                    })
                    .catch(function(error){
                        console.log(error);
                    });
            },
            activarDesactivarF(accion,item){
                this.adModalF=1;
                this.adNombre=item.num_hoja;
                this.adId=item._id;
                if (accion==1){
                    this.adAccionF=1;
                } else if(accion==2){
                    this.adAccionF=2;
                } else{
                    this.adModalF=0;
                }
            },
             activarDesactivarCerrar(){
                this.adModal=0;
            },
            activarDesactivarCerrarF(){
                this.adModalF=0;
            },
            activarF(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                axios.put('solicitud/activatef',{'_id':this.adId},configuracion)
                    .then(function(response){
                        me.adModalF=0;
                        me.adAccionF=0;
                        me.adNombre='';
                        me.adId='';
                        if (me.esAdministrador) {
                            me.listar();
                       } else {
                            me.listarporServicios();
                            me.listaranillas();
                        }
                           
                       // me.listar();
                    })
                    .catch(function(error){
                        console.log(error);
                    });
            },
            desactivarF(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                axios.put('solicitud/deactivatef',{'_id':this.adId},configuracion)
                    .then(function(response){
                        me.adModalF=0;
                        me.adAccionF=0;
                        me.adNombre='';
                        me.adId='';
                        if (me.esAdministrador) {
                            me.listar();
                       } else {
                            me.listarporServicios();
                            me.listaranillas();
                        }
                           
                      //  me.listar();
                    })
                    .catch(function(error){
                        console.log(error);
                    });
            },
            closedialogdoc () {
                //this.dialog = false;
               document.getElementById("uploads").value=null; 
              this.selectedFile=null;
               this.validaDoc=[];
               
              // this.enviadodoc=0;
               this.dialogdoc = 0;
               

            },


            closeS () {
               this.validaMensaje=[]; 
               this.dialogM=0;
               this.verModalFecha=0;
                this.dialogeditar=0;
                this.dialogresponder=0;
                this.dialoghistorico=0;
                this.dialogderivar=0;
                this.dialogcambiarusuario=0;
                this.enviadodoc=0;
                this.nomfichero='';
                this.verModalFin=0;
                this.verResponder=0;
                this.verModalTiempo=0;
                this.panelOpen=0;
                this.enviarcorreoSerrvicio=0;
                this.verTiempo=0;
                this.valid=false;
                
               // this.limpiar();
               
            },
            closeAnillas() {
              this.dialoganillas=0;
            },
            close () {
               // this.limpiar();
                this.verModalFecha=0;
                this.verModalFechacal=0;
                this.validaFecha=[];
                this.verModalFecha=0;
                this.dialog = false;
                this.dialogeditar=0;
                this.verModalFin=0;
                this.verModalFechafin=0;
                this.verTiempo=0;
                this.enviarcorreoSerrvicio=0;
                this.verModalTiempo=0;
                this.observaciones='';
                this.verObservaciones=0;
                this.panelOpen=0;
            }
        }
    }
</script>
