<template>
   <div class="box1">
   
      <v-row >
      

        <v-col class="mb-12">
          <h1 >
            ACCESO A LA RESERVA
          </h1>
          <br>
        
            <h2>Acceso a la ICTS: Requisitos para investigar en Doñana</h2>         
          
          <p >
                       
            </p>         
            <p >
            La investigación es uno de los objetivos prioritarios por la declaración de Parque Nacional de Doñana según establece la Ley de Doñana (Ley 91/1978, de 28 de diciembre), y el Director de la Estación Biológica de Doñana es el responsable de la coordinación de los programas de investigación desarrollados en la zona. 
            Los principales criterios tenidos en cuenta para la selección y aprobación de los proyectos de investigación son: su relevancia científica, su posible aplicación para la conservación y gestión del parque nacional, la dificultad de llevarlos a cabo en cualquier otro emplazamiento fuera del Parque Nacional y el posible impacto de la investigación en el entorno natural de la zona.
            Las solicitudes para investigar en Doñana se pueden presentar en cualquier momento aunque conviene ajustar a las fechas previstas (ver abajo) para las reuniones de la Comision de Trabajo de Investigación del END (Comité de Acceso de la ICTS) y deben ser presentadas por un investigador adscrito a un centro de investigación reconocido.
            Si tu proyecto ya ha sido aprobado puedes solicitar aqui acceso a la ICTS-RBD (gestion de estancias)<br><br>

            IMPORTANTE: ENVIEN LAS SOLICITUDES PARA PROYECTOS A EJECUTAR EN EL PRIMER SEMESTRE DEL AÑO ANTES DEL 15 DE OCTUBRE (del año anterior); Y LOS A EJECUTAR EN EL SEGUNDO SEMESTRE ANTES DEL 15 DE MAYO (del año en curso). GRACIAS
                                 
          </p>
           <p >
           Estancias y alojamiento en la RBD: <a href="http://alojamientos.ebd.csic.es/Habitaciones/" target="blank">Ir a la aplicación</a>   
            
          </p>
          
          <p >
            <a href="http://www.ebd.csic.es/investigar-en-donana" target="blank">Consulta su página web</a>   
            
          </p>
        </v-col>    

      

      

      
      </v-row>
    
   </div>
  
</template>

<script>
  export default {
    name: 'Anillamiento',

    data: () => ({
     
     
     
    }),
  }
</script>
<style>
  .box1{
    position: absolute;
    top: 10px;
    border: 3px solid green;
    padding: 2%;
    width: 600px;    
    left: 30%;
    margin-left: -100px;
   }
</style>
