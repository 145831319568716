<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar text color="white">                
                   <v-btn @click="crearPDF()">
                    <v-icon
                    small
                    class="mr-1"                   
                    >
                    mdi-printer
                    </v-icon> 
                   </v-btn>              
                <v-toolbar-title>Mis solicitudes</v-toolbar-title>
                <v-divider
                class="mx-6"
                inset
                vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-text-field class="text-xs-center" v-model="search" append-icon="search" 
                label="Búsqueda" placeholder="Filtrar por nº solicitud, descripción, servicio ..." single-line hide-details></v-text-field>
                <v-spacer></v-spacer>
              

                <template >
                   
                        <v-btn color="primary"  @click="mostrarNuevaS()"  class="mr-1">Nueva solicitud</v-btn>
                    </template>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialogeditar" max-width="500px">
                   
                    <v-card>
                        <v-card-title>
                        <!-- <span class="headline">Modificar Solicitud</span> -->
                         <h4>Modificar</h4> 
                         <v-spacer></v-spacer>
                       <v-switch v-if="esIp==1"
                            v-model="firma_responsable"
                            @change="changeState(_id)"
                        ></v-switch> 
                        <div v-if="firma_responsable == 1 && esIp==1">
                                <span class="green--text">Aceptado</span>
                            </div>
                            <div v-if="firma_responsable == 0 && esIp==1">
                                <span class="red--text">Pendiente</span>
                            </div>
                        </v-card-title> 
                               
                        <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap> 
                                 <v-flex xs6 sm6 md6>
                                    <v-text-field v-model="num_solicitud" label="Nº solicitud" readonly>                                        
                                    </v-text-field>
                                </v-flex>    
                                 <v-flex xs6 sm6 md6>
                                    <v-text-field v-model="createdAt" label="Fecha" readonly>                                        
                                    </v-text-field>
                                </v-flex>                               
                            
                                
                                <v-flex xs12 sm12 md12>
                                    <v-select v-model="proyecto" v-if="verProyectos==0"
                                        :items="proyectosus"
                                        :rules="[v => !!v || 'Elija un proyecto de la lista desplegable.']" required                                                                              
                                        dense
                                        chips                                        
                                        label="proyecto">
                                    </v-select>                                       
                                   
                                </v-flex>
                                
                                 <v-flex xs12 sm12 md6  v-if="(firma_responsable == 0 && firma_tecnico == 0) || esIp==1">
                                    <h4>Servicio ICTS</h4>
                                    <v-radio-group   v-model="servicioicts" :mandatory="false" :rules="[v => !!v || 'Item is required']" required >                          
                                            <v-radio label="Acceso a Colecciones" value="Colecciones" ></v-radio>
                                            <v-radio label="Acceso a Anillamiento" value="Anillamiento" ></v-radio>
                                            <v-radio label="Acceso RBD/END " value="RBD/END"></v-radio>
                                            <v-radio label="Acceso apoyo técnico o logístico de campo" value="Apoyo_Tecnico"></v-radio>
                                            <v-radio label="Acceso Datos de ICTS" value="Datos_ICTS"></v-radio>                           
                                            <v-radio label="Acceso infraestructura TIC" value="TIC"></v-radio>
                                            </v-radio-group>
                                </v-flex>   
                               
                                <v-flex xs12 sm12 md6  v-if="(firma_responsable == 0 && firma_tecnico ==0) || esIp==1">
                                    <h4>Tipo de uso</h4>
                                                <v-radio-group v-model="tipouso" :mandatory="false" :rules="[v => !!v || 'Item is required']" required>
                                                    <v-radio label="Investigación" value="Investigador"></v-radio>
                                                    <v-radio label="Administración Pública" value="Administracion"></v-radio>
                                                    <v-radio label="Otros (ONG, Empresa, Particular etc.)" value="Otros"></v-radio>
                                                </v-radio-group>
                                            </v-flex>                                      
                                  <v-flex xs12 sm12 md12>
                                    <v-textarea rows="3" v-model="descripcion" label="Descripción" >                                        
                                    </v-textarea>
                                </v-flex>    
                                                     
                                 <v-flex xs12 sm12 md12 v-show="valida">
                                        <div class="green darken-1" v-for="v in validaMensaje" :key="v" v-text="v">

                                        </div>
                                    </v-flex>   
                                   
                            </v-layout>
                        </v-container>
                        </v-card-text>            
                        <v-card-actions>
                         <v-spacer></v-spacer>
                        <v-btn v-if="verActualizar==1" color="blue darken-1" text @click="actualizar">Actualizar</v-btn>
                        <v-btn color="blue darken-1" text @click="closeSS">Cerrar</v-btn>
                        
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogonueva" max-width="800px" >
                  <v-stepper v-model="step">
						<v-stepper-header>
						  <v-stepper-step :complete="step > 1" step="1">
						   
							<span class="headline">Nueva solicitud - {{apellidos}}, {{nombre}}   </span>                       
							
							
						  
						  </v-stepper-step>
						  <v-divider></v-divider>
						  <v-stepper-step :complete="step > 2" step="2">
						  Descripción de la solicitud
						  
						  </v-stepper-step>
						</v-stepper-header>                               
                                            
                                                                                         
                               <v-stepper-items>
								  <v-stepper-content step="1">
									<v-form ref="form1" v-model="valid1" @submit.prevent="validateStep1">                                      
        
                               
															
                                <v-card >                             
                                                        
                                    <v-container grid-list-md>                        
                                                                 
                                    <v-checkbox
                                        v-model="checkbox1"
                                        label="No tengo proyecto de investigación. Algunos servicios de la ICTS-RBD pueden no estar disponibles"
                                        color="success"
                                       
                                        small
                                        @click="check(checkbox1)"
                                    ></v-checkbox>
                                                                    
                                    <v-flex xs12 sm12 md12>
                                                <v-autocomplete v-if="verProyectos==0"
                                                    :items="proyectosus"                         
                                                    v-model="proyecto" 
                                                    dense
                                                    chips
                                                    clearable
                                                    :rules="[v =>  !!v && verProyecto != 0 || 'Elija un proyecto de la lista desplegable.']"                                 
                                                    label="Seleccione alguno de sus proyectos registrados"                        
                                                    @change="listarProyecto(proyecto)" 
                                                    solo 
                                                    
                                                    >
                                                    </v-autocomplete>
                                    </v-flex>                                 
                                    
                                <v-flex xs12 sm12 md12 lg12 x12>
                                    <v-btn small color="primary"
                                    v-if="verDatos==0 & verProyectos==0"
                                    @click.native="mostrarPro()"  >
                                        Añadir Proyecto
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-alert v-if="verDatos==0 & verProyectos==0" outlined small type="info">
                                        Si esta solicitud la va a registrar a un proyecto distinto a los que tiene registrados pulse el botón añadir proyecto.</v-alert>
                                    </v-flex>
            
                            
                                <v-dialog v-model="adModalPro"  max-width="500px">
                                    <v-container grid-list-md>
                                    <v-card>
                                    <v-card-title>
                                    <span class="headline">AÑADIR UN PROYECTO</span>
                                   </v-card-title>            
                                    <v-card-text>
                                           
                                <v-flex xs12 sm12 md12 lg12 x12>
                                  <!--   <v-btn small color="primary"
                                    v-if="verDatos==0 & verProyectos==0"
                                    @click.native="mostrarProNuevo()"  >
                                        Añadir Proyecto Nuevo
                                    </v-btn> -->
                                    <v-spacer></v-spacer>
                                    <v-alert v-if="verDatos==0 & verProyectos==0" outlined small type="info">
                                       Busque el proyecto en nuestra lista de selección. Si no lo encuentra utilice la opción "Añadir proyecto nuevo" del menú principal.</v-alert>
                                    </v-flex>
                                    <v-layout wrap>
                        
                                        <v-flex xs12 sm12 md12>
                                        <v-autocomplete  
                                        :items="proyectos"                         
                                        v-model="proyecto" 
                                        dense
                                        chips
                                        clearable
                                        label="Proyecto. Escriba nombre del IP o parte del título."                        
                                        @change="listarTodosProyectos(proyecto)" 
                                        solo                                         
                                        >
                                        </v-autocomplete >
                                </v-flex> 
                                        <v-container grid-list-md>
                                                
                                                    <v-text-field
                                                    v-model="nombreentidadproyecto"                                                     
                                                    outlined
                                                    filled
                                                    readonly
                                                    label="Nombre Proyecto o Entidad *"
                                                    
                                                    ></v-text-field>
                                                    <v-text-field
                                                    v-model="responsable"                                                     
                                                    outlined
                                                    filled
                                                    readonly
                                                    label="Nombre Responsable del Proyecto o Entidad *"
                                                    
                                                    ></v-text-field>
                                                    <v-text-field
                                                    v-model="financiador" 
                                                    outlined
                                                    filled
                                                    readonly
                                                    
                                                    label="Financiador"
                                                    
                                                    ></v-text-field>
                                                    <v-text-field
                                                    v-model="vigencia" 
                                                    outlined
                                                    filled
                                                    readonly
                                                    
                                                    label="Vigencia"
                                                    
                                                    ></v-text-field>
                                                
                                                        <v-flex xs12 sm12 md12 v-show="validaP">
                                                        <div class="orange--text" v-for="v in validaMensajeP" :key="v" v-text="v">

                                                        </div>
                                                        </v-flex>
                                                        </v-container> 
                                                    </v-layout>
                                                    
                                                    </v-card-text>            
                                                    <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="blue darken-1" text @click="closeP()">Cerrar</v-btn>
                                                    <v-btn color="blue darken-1" v-if="veranadirpusuario==1" text @click="guardarPusuario()">Añadir</v-btn>
                                                    </v-card-actions>
                                                    </v-card>
                                                    </v-container>
                                                </v-dialog>  
                
                                    
                                   
                                    <v-card   >
                                         
                                      
                                                                        
                                       <!--  <h4> Servicio de la ICTS que va a usar * </h4>   -->                                   
                                        <p>  <v-alert outlined x-small type="info">
                                        {{ textoTooltip }} </v-alert></p>
                                                                               
                                       
                                            <v-card-text>   
                                              
                                            <v-radio-group   v-model="servicioicts" :mandatory="false" :rules="[v => !!v || 'Item is required']" required >                          
                                                
                                            <v-radio label="Acceso a Colecciones" value="Colecciones"  v-on:click="updateTextoTooltip"></v-radio>   
                                                                                                                              
                                                <v-radio label="Acceso a Anillamiento" value="Anillamiento" v-on:click="updateTextoTooltip"></v-radio>
                                                                                   
                                                <v-radio label="Acceso RBD/END " value="RBD/END" v-on:click="updateTextoTooltip"></v-radio>
                                                                                       
                                                <v-radio label="Acceso apoyo técnico o logístico de campo" value="Apoyo_Tecnico" v-on:click="updateTextoTooltip"></v-radio>
                                                                                      
                                                <v-radio label="Acceso Datos de ICTS" value="Datos_ICTS" v-on:click="updateTextoTooltip"></v-radio>   
                                          
                                                <v-radio label="Acceso infraestructura TIC" value="TIC" v-on:click="updateTextoTooltip"></v-radio>   
                                           
                                      
                                            </v-radio-group>
                                          
                                        
                                            </v-card-text>
                                            </v-card>
                                                                                    
                                        
                                          
                                                                
                                <v-card-actions>   
								  							
                                        <v-spacer></v-spacer>
                                       
                                       
                                     <v-btn :disabled="valid1==false" color="primary" @click="step++">Siguiente</v-btn>
									  <v-btn color="blue darken-1" text @click="close">Cerrar</v-btn> 
										
                                  
                                    
                                   </v-card-actions> 
                                </v-container>         
                                </v-card>   
                                                   
                              </v-form>
							  </v-stepper-content>	                 
                                <v-stepper-content step="2">
                                <v-form ref="form2"   v-model="valid"
                                    value @submit.prevent="validateStep2">   
                                    <v-card >                           
                                                <v-card-title>
                                                    Tipo de Uso *
                                                </v-card-title> 
                                                <v-card-text>
                                                    
                                                <v-radio-group v-model="tipouso" :mandatory="false" :rules="[v => !!v || 'Item is required']" required>
                                                    <v-radio label="Investigación" value="Investigador"></v-radio>
                                                    <v-radio label="Administración Pública" value="Administracion"></v-radio>
                                                    <v-radio label="Otros (ONG, Empresa, Particular etc.)" value="Otros"></v-radio>
                                                </v-radio-group>
                                               
                                                </v-card-text>
                                                </v-card>         
                                    <v-card >   
                                       
                                <v-btn small color="primary"
                                    v-if="servicioicts=='Anillamiento'"
                                    @click.native="mostrarAnillas()"  >
                                        Pedir Anillas
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-alert v-if="servicioicts=='Anillamiento'" outlined small type="info">
                                        Si va a solicitar anillas tanto de PVC como de metal pulse el botón "pedir anillas". Si su petición es para pedirnos datos, escriba una breve descripción de los datos que necesita indicando la especie etc.</v-alert>
                                  
            
                            
                                <v-dialog v-model="adModalAnillas"  max-width="700px">
                                    <v-container grid-list-md>
                                    <v-card>
                                    <v-card-title>
                                    <span class="headline">Petición de Anillas</span>
                                    <v-spacer></v-spacer>
                                    <v-switch 
                                        v-model="tipo_anilla"
                                        color="primary"
                                        hide-details
                                        true-value="PVC"
                                        false-value="Metal"
                                       
                                        :label="`Tipo de Anillas: ${tipo_anilla}`"
                                        @change="changeTipo()"
                                    ></v-switch> 
                                   </v-card-title>            
                                    <v-card-text>                                         
                                                                                                  
                                 
                                    <v-layout wrap>
                                     
                                        <v-container grid-list-md>                                                
                                            <v-layout wrap>     
                                             <v-flex xs6 sm6 md6 v-if="tipo_anilla=='Metal'">     
                                                <v-autocomplete 
                                                   label="Seleccionar modelo"
                                                   v-model="modelo"  
                                                    dense
                                                    chips
                                                    clearable                                                
                                                   :items="['AX','B','BA','BC','BX','C','CX','E','F','FA','G','GX','H','J','KA','KX','L','LA','LX','M','MX','NX','P','PA','RX','T','TX','V','VX','W','Y','Z']"
                                                 
                                                ></v-autocomplete>
                                              </v-flex>
                                              <v-flex xs6 sm6 md6 v-if="tipo_anilla=='PVC'">  
                                                <v-text-field 
                                                    v-model="especie" 
													placeholder="Escriba una especie"	
                                                    outlined
                                                    filled
                                                    
                                                    
                                                    label="Especie"
                                                    
                                                    ></v-text-field>
                                                </v-flex>
                                            <v-flex xs6 sm6 md6 >
                                                  <v-text-field 
                                                    v-model="cantidad"  
                                                    min="1"
                                                    type="number" 
                                                    placeholder="Escriba cantidad"													
                                                    outlined
                                                    filled                                                    
                                                    label="Cantidad *"
                                                    
                                                    ></v-text-field>
                                                </v-flex>
                                                <v-flex xs12 sm12 md12 >
                                                <v-card  color="#EEEEEE"   v-if="tipo_anilla=='PVC'">
                                               
                                                   
                                            <v-radio-group v-model="tipo_PVC" >
                                                <v-radio  label="Físicas" value="fisicas"></v-radio>
                                                <v-radio  label="Códigos" value="codigos"></v-radio>
                                               
                                                </v-radio-group>

                                               
                                                </v-card>
                                            </v-flex>
                                             <v-flex xs6 sm6 md6 v-if="tipo_anilla=='Metal'">
                                             <v-alert outlined x-small type="info">
                                              Indique modelo y cantidad. Pulse "Añadir Anillas" hasta completar el pedido.</v-alert>
                                             </v-flex>
                                            <v-flex xs6 sm6 md6 v-if="tipo_anilla=='PVC'">
                                             <v-alert outlined x-small type="info">
                                              Indique especie, cantidad, y si son anillas físicas o códigos. Pulse "Añadir Anillas" hasta completar el pedido.</v-alert>
                                             </v-flex>
                                                <v-flex xs6 sm6 md6>
                                                <v-btn color="blue darken-1"  dark class="mb-6"  @click="AnnadirAnillas()"><v-icon>mdi-plus</v-icon>Añadir Anillas</v-btn>
                                                </v-flex>
                                                <v-flex xs6 sm6 md6>
                                            <v-textarea 
                                                    v-model="pedido" 													
                                                    outlined
                                                    filled                                                   
                                                    label="Pedido de anillas"
                                                    readonly
                                                    ></v-textarea>
                                                </v-flex>
                                                <v-flex xs6 sm6 md6>
                                                <v-btn color="blue darken-1"  dark class="mb-6"  @click="BorrarAnillas()"><v-icon>mdi-minus</v-icon>Borrar Pedido</v-btn>
                                                </v-flex>
                                                <v-flex xs6 sm6 md6>
                                                    <v-textarea
                                                    v-model="datos_envio" 
													placeholder="Escriba dirección de envío o donde recoger las anillas (EBD/Centralita etc.)"
                                                    outlined
                                                    filled
                                                    rows="4"
                                                    
                                                    label="Dirección de envío o donde recoger"
                                                    
                                                    ></v-textarea>
                                                    </v-flex>
                                                   
                                                  <v-flex xs6 sm6 md6 v-if="facturacion">
													  <v-textarea 
                                                    v-model="datos_facturacion" 
													placeholder="Escriba datos de facturación."
                                                    outlined
                                                    filled   
                                                    rows="4"                                              
                                                    label="Datos de facturación"
                                                    
                                                    ></v-textarea>
                                                    </v-flex>
                                              
                                            </v-layout>                                             
                                                  
                                                
                                                
                                                    <v-flex xs12 sm12 md12 v-show="validaA">
                                                    <div class="red--text" v-for="v in validaMensajeA" :key="v" v-text="v">

                                                    </div>
                                                    </v-flex>
                                                    </v-container> 
                                                  </v-layout>
                                                    
                                                    </v-card-text>            
                                                    <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="blue darken-1" text @click="closeA()">Cerrar</v-btn>
                                                    <v-btn color="blue darken-1"  text @click="guardarDescripcion()">Aceptar</v-btn>
                                                    </v-card-actions>
                                                    </v-card>
                                                    </v-container>
                                                </v-dialog>               
                                    
                                                                 
                               
								  <v-textarea
                                            v-model="descripcion"  
                                                  
                                           
                                            :rules="[v => !!v || 'Escriba una breve descripción']" required
                                            clearable
                                            clear-icon="mdi-close-circle"  
                                            no-resize
                                            rows="6"           
                                            label="Breve descripción del servicio solicitado *"     
                                            >
                                            </v-textarea>
                                       
                                            
                                           
                               <v-row>
                                  <v-col> 

                                  <v-btn color="blue darken-1" text @click="close">Cerrar</v-btn> 
                                    </v-col>
                                 <v-col>
                                    <v-btn color="primary" @click="previousStep()">Anterior</v-btn>
                                    </v-col>
                                  
                                  <v-col>     
                                     <v-btn
                                    :disabled="valid==false"
                                                                        
                                    color="success"
                                    class="mr-4"
                                    @click="guardarsolicitud()"
                                    >
                                    Enviar solicitud
                                    </v-btn>
                                    </v-col>
                                 
                                    </v-row>
                                     </v-card>                               
                                   
								
								
                                
								 
                                 </v-form>
									  </v-stepper-content>
									</v-stepper-items>
								  </v-stepper>
																 
                              
                                
                       
                  
                   
                </v-dialog>
                <v-dialog
                        v-model="dialogM"
                        width="350"
                    >
                    
                
                        <v-card>
                        <v-card-title class="text-h8 green lighten-2">
                            Solicitud {{this.num_solicitud}}
                        </v-card-title>
                
                        <v-card-text>
                            <v-flex xs12 sm12 md12 v-show="valida">
                                <div  v-for="v in validaMensaje" :key="v" v-text="v">

                                </div>
                            </v-flex>   
                        </v-card-text>
                
                        <v-divider></v-divider>
                
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                            color="primary"
                            
                            text
                            @click="closeS"
                            >
                            Cerrar
                            </v-btn>
                        </v-card-actions>
                        </v-card>
                    
                    </v-dialog>
                    <v-dialog
                        v-model="dialogMA"
                        width="350"
                    >
                    
                
                        <v-card>
                        <v-card-title class="text-h8 green lighten-2">
                            Solicitud {{this.num_solicitud}}
                        </v-card-title>
                
                        <v-card-text>
                            <v-flex xs12 sm12 md12 v-show="valida">
                                <div  v-for="v in validaMensaje" :key="v" v-text="v">

                                </div>
                            </v-flex>   
                        </v-card-text>
                
                        <v-divider></v-divider>
                
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                            color="primary"
                            
                            text
                            @click="closeSA"
                            >
                            Cerrar
                            </v-btn>
                        </v-card-actions>
                        </v-card>
                    
                    </v-dialog>
                <v-dialog v-model="adModal" max-width="290">
                    <v-card>
                        <v-card-title class="headline" v-if="adAccion==1">
                            Activar Item
                        </v-card-title>
                        <v-card-title class="headline" v-if="adAccion==2">
                            Borrar Item
                        </v-card-title>
                        <v-card-text>
                            Estás a punto de <span v-if="adAccion==1">activar </span>
                            <span v-if="adAccion==2">Borrar </span> el item {{adNombre}}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="activarDesactivarCerrar()" color="green darken-1" text="text">
                                Cancelar
                            </v-btn>
                            <v-btn v-if="adAccion==1" @click="activar()" color="orange darken-4" text="text">
                                Activar
                            </v-btn>
                            <v-btn v-if="adAccion==2" @click="desactivar()" color="orange darken-4" text="text">
                                Borrar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogresponder" max-width="650px">
                   
                   <v-card>
                       <v-card-title>
                       <span class="headline">Responder </span>
                           <v-spacer></v-spacer>   
                            Adjuntar fichero 
                           
                           <v-icon 
                                x-large
                                @click="mostrardoc()"
                                >
                               mdi-attachment 
                            </v-icon>
                            <v-spacer></v-spacer>    
                           <div class="blue darken-1">
                            <span v-if="enviadodoc==1"> {{ nomfichero }}</span>
                        </div>                   
                       </v-card-title>            
                       <v-card-text>
                       <v-container grid-list-md>
                           <v-layout wrap> 
                             <v-flex xs12 sm12 md12>
                              {{ apellidos }}, {{ nombre }}  - {{ correo }}   
                              </v-flex>                   
                            <v-spacer></v-spacer>
                                      <v-flex xs6 sm6 md6>
                                   <v-text-field filled v-model="num_solicitud" label="Nº solicitud" readonly>                                        
                                   </v-text-field>
                               </v-flex>   
                                <v-flex xs6 sm6 md6>
                                   <v-text-field filled v-model="createdAt" label="Fecha" readonly>                                        
                                   </v-text-field>
                               </v-flex>                        
                                                              
                                                                   
                                 <v-flex xs12 sm12 md12>
                                   <v-textarea rows="3" v-model="descripcion" label="Descripción" readonly >                                        
                                   </v-textarea>
                               </v-flex>    
                                 <v-flex xs12 sm12 md12>
                                   <v-textarea 
                                    v-model="respuesta" 
                                    prepend-icon="mdi-tooltip-outline"
                                    :rules="respRules"
                                    required
                                    auto-grow
                                    label="Respuesta">                                        
                                   </v-textarea>
                               </v-flex>  
                                                
                               <v-flex xs12 sm12 md12 v-show="valida">
                                       <div class="green darken-1"  v-for="v in validaMensaje" :key="v" v-text="v">

                                       </div>
                                   </v-flex>   
                           </v-layout>
                       </v-container>
                       </v-card-text>            
                       <v-card-actions>
                       <v-spacer></v-spacer>
                       <v-btn   :disabled="respuesta==''" v-if="verResponder==1" color="blue darken-1" text @click="responder">Enviar</v-btn>
                       <v-btn color="blue darken-1" text @click="closeS">Cerrar</v-btn>
                       
                       </v-card-actions>
                   </v-card>
               </v-dialog>
              <v-dialog v-model="dialogdoc" max-width="500px" max-height="600px" margin-bottom="1px;" >
                       <v-card>
                           <v-card-title>Adjunte el fichero</v-card-title>
                           
                         <v-card-text>
                            <v-alert outlined x-small type="info">
                               Si necesita adjuntar más de un fichero. Comprímalo primero en un zip o war. (Max 3 MB)</v-alert>
                         <v-container grid-list-md>
                        <label for="uploads">Elija documento</label> <br>
                       <!--  <input type="file" id="uploads"  @change="onFileChange" /> -->
                         <v-file-input type="file"
                                show-size
                                :rules="rulesf"
                                placeholder="Seleccione un fichero"
                                id="uploads" 
                                v-model = "selectedFile"
                                @change="onFileChange">
                        </v-file-input> 
                        <v-spacer></v-spacer>
                      <!--   <button :disabled="!selectedFile" color="blue darken-1" @click="deletedoc">Borrar</button> -->
                               
                        </v-container>
                        </v-card-text>
                        
                        <v-card-action>
                         
                          <v-flex xs12 sm12 md12 v-show="valida">
                        <div class="brown--text" v-for="v in validaDoc" :key="v" v-text="v" style="border: 1px solid black;margin:10px;">

                        </div>
                        </v-flex>   
                         <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closedialogdoc">Cerrar</v-btn>
                        <v-btn :disabled="!selectedFile"  @click="onUploadFile"  color="blue darken-1"
                        >Subir Fichero</v-btn>
                         
                      
                        </v-card-action>
                       </v-card>
                       <v-spacer></v-spacer>
                     </v-dialog>
            </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="solicitudes"
                :search="search"
                sortBy="createdAt"
                sortDesc="true"
                class="elevation-1"
            >
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-for="item in items" :key="item.num_solicitud"> 
                                                
                     
                <td>{{ getHumanDate(item.createdAt) }}</td>     
                <td>{{ item.num_solicitud }}</td>                
                <td>{{ item.usuario.apellidos }}</td>                
                <td>{{ item.usuario.nombre }}</td>
                <td>{{ item.proyecto ? item.proyecto.nombreentidadproyecto : 'N/A' }}</td>
                <td>{{ item.servicioicts }}</td>
                <td>{{ item.descripcion ? item.descripcion.substr(0,200) + ' ...  ' : 'N/A' }}</td>
                <td>
                    <div v-if="item.firma_responsable == 1 && item.nomresponsable !=null"">
                        <span class="green--text">Aceptado por: {{ item.nomresponsable.nombre }} {{ item.nomresponsable.apellidos }}</span>
                        <span v-if="item.nomderivado" class="green--text" > Derivado a: {{ item.nomderivado }} </span>
                    </div>
                    <div v-else>
                        <span class="red--text">Pendiente</span>
                    </div>
                </td>
                 <td>
                    <div v-if="item.firma_tecnico == 1 && item.nomtecnico !=null">
                        <span class="green--text">Aceptado por: {{ item.nomtecnico.nombre }} {{ item.nomtecnico.apellidos }}</span>
                        <span v-if="item.nomderivado" class="green--text" > Derivado a: {{ item.nomderivado }} </span>
                    </div>
                    <div v-else>
                        <span class="red--text">Pendiente</span>
                    </div>
                </td>       
               
                
                <td>
                    <div v-if="item.estado == 1 &&  (item.firma_responsable==0 || item.firma_tecnico==0) ">
                        <span class="blue--text">Solicitado</span>
                    </div>
                    <div v-if="item.estado == 1 &&  item.firma_responsable==1 && item.firma_tecnico==1 ">
                        <span class="orange--text">En curso</span>
                    </div>
                    <div v-if="item.estado == 3">
                        <span class="brown--text">Finalizado el {{ getHumanDateC(item.fechafin) }}</span>
                    </div>
                    <div v-if="item.estado == 4">
                        <span class="darkblue--text">Respuesta enviada</span>
                    </div>
                    <div v-if="item.estado == 0">
                        <span class="red--text">Inactivo</span>
                    </div>
                </td>
                <td> 
                    <template v-if="item.estado !=0" >
                    <v-tooltip slot="append" location="top"> 
                    <template v-slot:activator="{ on }"> 
                    <v-icon 
                    v-on="on"
                    small
                    class="mr-2"
                    large color="blue darken-2"
                    @click="editItem(item)"
                    >
                    mdi-pencil
                    </v-icon>
                </template>
                <span>Editar / Modificar Solicitud</span>
                </v-tooltip>
            </template>
               <!--  <template v-if="item.firma_responsable==1 && rol != 'Usuario' && item.estado!=3" >
                <v-tooltip slot="append" location="top"> 
                    <template v-slot:activator="{ on }"> 
                    <v-icon 
                    v-on="on"
                    small
                    class="mr-2"
                    large color="orange darken-2"
                    @click="responderUsuario(item)"
                    >
                    mdi-email-outline
                    </v-icon>
                </template>
                <span>Responder al usuario por correo</span>
                </v-tooltip>
            </template> -->
            <template v-if="item.firma_responsable==1 && item.firma_tecnico==1  && item.estado!=3" >
                <v-tooltip slot="append" location="top"> 
                    <template v-slot:activator="{ on }"> 
                    <v-icon 
                    v-on="on"
                    small
                    class="mr-2"
                    large color="indingo darken-4"
                    @click="responderTecnico(item)"
                    >
                    mdi-email-outline
                    </v-icon>
                </template>
                <span>Responder al técnico por correo</span>
                </v-tooltip>
            </template>
                    <template v-if="item.estado !=0 && item.estado !=3">
                        <v-tooltip slot="append" location="top"> 
                    <template v-slot:activator="{ on }">   
                        <v-icon
                        v-on="on"
                        small
                        class="mr-2"
                        large color="orange darken-2"
                        @click="activarDesactivarMostrar(2,item)"
                        >
                        mdi-delete
                        </v-icon>
                    </template>
                <span>Anular la solicitud</span>
                </v-tooltip>
                    </template>
                    <template v-else>
                        <v-tooltip slot="append" location="top"> 
                    <template v-slot:activator="{ on }"> 
                        <v-icon
                        v-on="on"
                        small
                        class="mr-2"
                        large color="teal darken-2"
                        @click="activarDesactivarMostrar(1,item)"
                        >
                        mdi-check-bold
                        </v-icon>   
                    </template>
                <span>Activar la solicitud</span>
                </v-tooltip>  
                     </template>  
                    </td>    
                 </tr>

                  </tbody>                 
                </template>
                <template v-slot:no-data>
                <v-btn color="primary" @click="listar()">Resetear</v-btn>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
    import axios from 'axios'    
    import jsPDF from 'jspdf'
    import moment from 'moment';
    
    
    export default {
        data(){
            return{
                dialogonueva: 0,
                search:'',
                solicitudes:[],
                checkbox1: false,
                headers: [
                    { text: 'Fecha', value: 'createdAt', sortable: true  },
                    { text: 'Nº solicitud',value: 'num_solicitud', sortable: true},
                    { text: 'Apellidos',value: 'apellidos', sortable: true},
                    { text: 'Nombre', value: 'nombre', sortable: true },
                    { text: 'Proyecto',value: 'nombreentidadproyecto', sortable: true}, 
                    { text: 'Servicio',value: 'servicioicts', sortable: true}, 
                    { text: 'Descripción',value: 'descripcion', sortable: false},  
                    { text: 'Investigador Responsable - IP',value: 'firma_responsable', sortable: false},  
                    { text: 'Técnico',value: 'firma_tecnico', sortable: false},                            
                    { text: 'Estado', value: 'estado', sortable: false },
                    { text: 'Opciones', value: 'opciones', sortable: false }             
                    
                ],
              
                 editedIndex: -1,
                _id:'',               
                _idproyecto:'',
                _idproyecton:'',
                _idusuario:'',
                tipouso:'',
                apellidos:'',
                step: 1,
                email:'',
                nombre:'',
                password:'',             
                esIp:0,
                rol:'',             
                servicioicts:'',
                tipouso:'',
                proyecto:'',
                usuario:'',
                actividad:'',
                centro_procedencia:'',
                proyectos:[],                 
                proyectosus:[],
                detalles:[],
                solicitudes:[],
                detallesUsuario:[],                
                nombreentidadproyecto:'',
                descripcion:'',
                responsable:'',
                nomresponsable:null,
                nomtecnico:null,
                mailpro:'',
                financiador:'',
                resumen:'',
                objetivos:'',
                firma_tecnico:0,
                firma_responsable:0,
                quien:'',
                presupuesto:0,
                vigencia:'',
                valida:0,
                valida1:0,
                dialogeditar:0,
                verProyectos:0,
                validaMensaje:[],
                validaMensajeP:[],
                validaMensajeA:[],
                validaP:[],
                validaA:0,
                veranadirpusuario:0,
                usuario:[],
                createdAt:null, 
                num_solicitud:'',
                numsolicitud:0,
                adModal:0,
                adAccion:0, 
                verNuevo:0,
                verActualizar:0,
                verDatos:0,
                adModalPro:0,  
                adModalProN:0,                
                adModalDatos:0, 
                adNombre:'',
                dialogdoc:0,
                respuesta:'',
                selectedFile:null,
                verResponder:0,
                enviadodoc:0,
                historico:'',
                nomfichero:'',
                dialogresponder:0,
                validaDoc:[],
                correo:'',
                pp:0,
                
                adModalAnillas:0,
                modelo:'',
                cantidad:0,
                especie:'',
                datos_envio:'',
                datos_facturacion:'',
                sw:false, 
                dialogM:0,
                dialogMA:0,
                fechafin:null,
                textoTooltip: 'Pulse un servicio ICTS.',            
                tipo_anilla:'Metal',
                tipo_PVC:'',
                valid:true,
                valid1:false,
                pedido:'',
                selection:'',
                facturacion:0,
                detallesAnillas:[],
                sonanillas:0,
                tags: ['AX','B','BA','BC','BX','C','CX','E','F','FA','G','GX','H','J','KA','KX','L','LA','LX','M','MX','NX','P','PA','RX','T','TX','V','VX','W','Y','Z'],
                nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 255) || 'Name must be less than 255 characters',
                ],
               descRules: [
                v => !!v || 'indique una breve descripción',
               // v => (v && v.length <= 255) || 'Name must be less than 255 characters',
                ],
                respRules: [
                v => !!v || 'required',
                
                ],
                rulesf: [
                value => {
                    return !value || !value.length || value[0].size < 3000000 || 'File size should be less than 2 MB!'
                }],
                peRules: [
                  v => !!v || 'Elija un proyecto de la lista desplegable',
                  
                ],
                checkRules: [
                v => (!v && v._idproyecto == '') || 'Elija un proyecto de la lista desplegable'
                  
                ],
                emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                select: null,
                items: [
                'Item 1',
                'Item 2',
                'Item 3',
                'Item 4',
                ],
                checkbox: false,
                itemsb: [
                  {
                    text: 'Usuario Registrado',
                    disabled: true,
                    href: 'Registrado',
                  },
                  
                  {
                    text: 'Usuario Nuevo (primera solicitud)',
                    disabled: false,
                    href: 'Solicitudnueva',
                  },
                ],
               
                }
                
            
        },
        computed: {
            formTitle () {
            return this.editedIndex === -1 ? 'Nueva solicitud' : 'Ver registro'
            }
        },
      
     
        created () {
           
           
            this.rol=this.$store.state.rol;
           // this.id=this.$store.state.usuario._id;
            this.obtenerusuario();           
            console.log(this._idusuario);
           
          //  this._idproyecton='60c88317e86ff200901dc1ee';

            console.log(this.rol);
            if (this.rol=="Usuario"){
                this.listarporUsuarios(this._idusuario);  
            } else {
                this.esIp=1;
                // this.listarporProyectos();  
                this.listarporProy(this.detalles);                              
                 
            }  
                   
                     
            
        },
        methods: {

            getHumanDate : function (date) {
                        return moment(date).local().format('DD/MM/YYYY, h:mm a');
            },
            getHumanDateC : function (date) {
                        return moment(date).local().format('DD/MM/YYYY');
            },
          
                    
            reset () {
               // this.verDatos=0;
               // this.dialogM=0;
                this.$refs.form.reset();
                },
                previousStep() {
                 this.step--
                },
                validateStep1() {
                    console.log(this.valid1);
                    this.$refs.form1.validate().then(valid1 => {
                        if (valid1) {
                         
                        this.step++
                        }
                    })
                    },

                    validateStep2() {
                    this.$refs.form2.validate().then(valid => {
                        if (valid) {
                        // this.guardarsolicitud();
                        }
                    })
                    },
            updateTextoTooltip() {

               console.log(this.servicioicts); 
              if (this.servicioicts === 'Colecciones') {
                this.textoTooltip = 'Colecciones: En dicho recurso a colecciones podremos solicitar desde visita a las mismas, a aspectos artísticos como fotografia, pintura, etc. Por otra parte aspectos forenses (identificacion de animales o sus restos decomisados en aduanas o procedentes del SEPRONA…), gestión de datos de la colección y solicitud de muestras o préstamos.';                                                
                } else if (this.servicioicts === 'Anillamiento') {
                this.textoTooltip = 'Anillamiento: Dicha oficina incluida en el equipo de Seguimiento de Procesos Naturales de la EBD, tramita todo lo relativo a anillamientos de aves, coordina marcajes legibles a distancia que se realizan en España (se pueden solicitar las anillas PVC o códigos), citar datos asociados a los anillamientos).';
                } else if (this.servicioicts === 'RBD/END') {
                this.textoTooltip = 'RBD/END: Por un lado estaría la petición de acceso para uso de investigación y que previa revisión del equipo de coordinación  pasarían por la comision de investigación del END para poder tramitar los permisos. Y por otro lado estaría una vez realizado todo lo anterior, solicitar la estancia para la entrada y alojamiento en la RBD. Otro tipo de visitas y estancias (talleres, reuniones) se gestiona directamente con los responsables de la RBD';
                                                                
                } else if (this.servicioicts === 'Apoyo_Tecnico') {
                this.textoTooltip = 'Apoyo Técnico: El equipo de Seguimiento de Procesos y Recursos Naturales de la Estación Biológica presta apoyo a los proyectos de investigación poniendo a disposición de los mismos la experiencia y el conocimiento acumulados durante años de trabajo en Doñana. Se pueden solicitar los datos de seguimiento (http://icts.ebd.csic.es/es/infraestructura-de-datos) ';                                                                                
                } else if (this.servicioicts === 'Datos_ICTS') {
                this.textoTooltip = 'Acceso a datos ICTS. Solicitud de datos.';
                } else if (this.servicioicts === 'TIC') {
                this.textoTooltip = 'TIC: El persona del RBD puede dar apoyo para gestionar: Instalaciones de campo, soporte técnico y logistico. El equipo de ISTIC (infraestructura y Servicios) de la RBD atienden peticiones de acceso a la infraestructura de acceso a datos, y servicios y herramientas TIC (Cobertura WIFI y WIMAX)';
                                                                
               
                }
                
               },

            obtenerusuario() {
              
              //  this.usuario=this.$store.state.usuario;
                this.detalles=this.$store.state.usuario.detalles; 
                this.nombre=this.$store.state.usuario.nombre;
                this.rol=this.$store.state.rol;
                //this.rol=this.usuario.rol;
                console.log(this.rol);
                this.apellidos=this.$store.state.usuario.apellidos; 
                this.email=this.$store.state.usuario.email;
                if (this.rol=="Usuario") {            
                console.log(this.rol);
                if (this.detalles.length==1) 
                this._idproyecto=this.detalles[0]._id;} else {this._idproyecto='';}               
                this._idusuario=this.$store.state.usuario._id;
                console.log(this._idusuario);
                this.listarDetallesUsuario(this._idusuario);
                
            },
            check: function(e) {
                
                console.log(this.checkbox1,e);
                if (this.checkbox1==true) {
                    this._idproyecton='60c88317e86ff200901dc1ee';
                    this.listarnoProyecto(this._idproyecton);
                   // this._idproyecto='';
                    this.verProyectos=1;
                    
                } else  if (this.checkbox1==false) {
                      this.proyecto=null;
                   this._idproyecto='';
                    
                   
                    this.limpiarProyecto();
                     this.verProyectos=0;
                   // this._idproyecton='aa';
                 //  this.servicioicts='';
                 
                  
                    
                }
                
                },
            listarporUsuarios(_idusuario){
                
                let me=this;
                
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};        
                axios.get('solicitud/listtodas?valor='+_idusuario,configuracion).then(function (response){
                    me.solicitudes=response.data;  
                    console.log(me.solicitudes);                       
                }).catch(function(error){
                    console.log(error);
                });
               
            },   
            listarporProy(detalles){
                
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                let temp=[];
                let _id='';
                let proyectosArray=[];
                me.solicitudes=[];       
                proyectosArray=me.detalles; 
                console.log(me.detalles);     
                   for (var i=0;i<proyectosArray.length;i++){
                         
                        _id=proyectosArray[i]._idproyecto;                                         
                                  
                        axios.get('solicitud/listtodas?valor='+_id,configuracion).then(function (response){
                        temp=response.data;                           
                        me.solicitudes=me.solicitudes.concat(temp);    
                                           
                        
                        }).catch(function(error){
                            console.log(error);
                        });
                    }
               
            }, 
            listar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                          
                axios.get('solicitud/listtodas',configuracion).then(function (response){
                    me.solicitudes=response.data;  
                    console.log(me.solicitudes);                  
                }).catch(function(error){
                    console.log(error);
                });

            }, 
            mostrarAnillas() {
                this.adModalAnillas=1;
                this.tipo_anilla="Metal";

            },

            BorrarAnillas() {
               this.pedido='';
               this.modelo='';
               this.cantidad=0;
               this.especie='';
               this.tipo_PVC='';
               this.facturacion=0;
               this.detallesAnillas=[];
            },

            AnnadirAnillas() {
             
            this.validaMensajeA=[];
            if (this.tipo_anilla == 'Metal') {
                    if (this.cantidad !=0 && this.modelo != '') {  
                    this.pedido=this.pedido + this.modelo + ': ' + this.cantidad  + '\n';
                    this.detallesAnillas.push(
                        {
                            tipo:this.tipo_anilla,
                            modelo:this.modelo,
                            cantidad:this.cantidad
                            
                        }); 
                       
                    this.validaMensajeA=[];
                    this.modelo='';
                    this.cantidad=0;
                    } else {
                    this.validaMensajeA.push('Seleccione un modelo y una cantidad válida distinta de cero.');
                    this.validaA=1;
                    }
            } else {
                if (this.tipo_anilla == 'PVC') {
                    if (this.cantidad !=0 && this.especie != '' && this.tipo_PVC != '') {  
                    this.pedido=this.pedido + this.especie + ': ' + this.cantidad + ' (' + this.tipo_PVC + ')' + '\n';
                    this.detallesAnillas.push(
                        {
                            tipo:this.tipo_anilla,
                            especie:this.especie,
                            tipo_PVC:this.tipo_PVC,
                            cantidad:this.cantidad
                            
                        }); 
                    if (this.tipo_PVC=='fisicas') {
                     this.facturacion=1;   
                    }
                    this.validaMensajeA=[];
                    this.especie='';
                    this.cantidad=0;
                    this.tipo_PVC='';
                    } else {
                    this.validaMensajeA.push('Seleccione una especie, una cantidad válida distinta de cero y si quiere códigos o anillas físicas.');
                    this.validaA=1;
                    }
                }
                }

             if (this.validaA==1) {return}
            
            
            },

            mostrarNuevaS() {    
               
                this.obtenerusuario(); 
                this.textoTooltip='Pulse un servicio ICTS.'; 
                this.proyecto=null;
                this._idproyecto='';
               // this.listarporUsuarios(this._idusuario);  
                if (this.rol=="Usuario"){
                        this.listarporUsuarios(this._idusuario);  
                    } else {
                        this.esIp=1;
                        // this.listarporProyectos();  
                        this.listarporProy(this.detalles);                              
                        
                    }   
                
                this.dialogonueva=1; 
                
             
                this.dialogM=0; 
                this.dialogMA=0;          
                              
            },
            crearPDF(){
                var columns =[
                    {title: "Fecha", dataKey: "createdAt"},
                    {title: "Nº de solicitud", dataKey: "num_solicitud"},
                    {title: "Apellidos", dataKey: "apellidos"},
                    {title: "Nombre", dataKey: "nombre"},  
                    {title: "Proyecto", dataKey: "nombreentidadproyecto"},                   
                    {title: "Servicio", dataKey: "servicioicts"}
                    
                   
                ];
                   
               
                var rows=[];

                this.solicitudes.map(function(x){
                    rows.push({
                        createdAt:moment(x.createdAt).local().format('DD/MM/YYYY, h:mm a'),
                        num_solicitud:x.num_solicitud,
                        apellidos:x.usuario.apellidos,
                        nombre:x.usuario.nombre, 
                        nombreentidadproyecto:x.proyecto.nombreentidadproyecto,                      
                        servicioicts:x.servicioicts
                        
                        
                        }
                    );
                });                
                var doc = new jsPDF('l','pt');
                doc.autoTable(columns,rows,{
                    margin: {top: 60},
                    didDrawPage: function(data) {
                        doc.text("Solicitudes", 40, 30);
                    }
                });

                doc.save('Solicitudes.pdf');
            },
            limpiarProyecto(){
                    this.descripcion='';                    
                    this.nombreentidadproyecto='';
                    this.descripcion='';
                    this.email='';
                    this.responsable='';
                    this.financiador='';
                    this.vigencia='';
                    this.resumen=''; 
                  //  this._idproyecto='';
                    this._idproyecton='';
                   // this.proyecto=null;
                  
            },
            limpiar(){
                   /*  this.apellidos='';
                    this.nombre=''; */
                  //  this.email='';
                    this.tipouso='';
                  
                    this.servicioicts='';
                    this.nombreentidadproyecto='';
                    this.descripcion='';
                    this.responsable='';
                   
                    this.financiador='';
                    this.resumen='';
                   // this.rol='';
                    this.objetivos='';
                    this.presupuesto=0;
                    this.detalles=[];
                    this.vigencia='';
                    this._idproyecto='';
                    this._idusuario='';
                    this.password='';
                    this.verNuevo=0;
                    this.mailpro='';
                    this.num_solicitud='';
                    this.createdAt=null;
                    this.pp=0;
                    this.sw=false;
                  //  this.dialogeditar=0;
                  //  this.dialogonueva=0;                   
                    this.validaMensaje=[];
                    this.validaMensajeP=[];
                    this.validaP=[];
                    this.proyecto='';
                    this.valid=true;
                    this.verActualizar=0;
                    this.adNombre='';
                    this.verDatos=0;
                    this.veranadirpusuario=0;                    
                    this.checkbox1=false;
                    this.verProyectos=0;
                  //  this.dialogM=0;
                    
                /*  this.valida=0;
                    this.validaMensaje=[];  
                    this.editedIndex=-1;    */         
                },
            mostrarPro() {  

              this.adModalPro=1; 
              this.selectProyectos();
              this.veranadirpusuario=0;
                                               
                 
            },
        mostrarProNuevo(){
                //me.reset;
                this.$router.push({ name: 'proyectousuario' });
            },
         buscarProUs(){
                  let me=this;
                   me.sw=false;
                   console.log(me.detallesUsuario[0]._idproyecto);
                   console.log(me.proyecto._id);
                      for (var i=0;i<me.detallesUsuario.length;i++){
                          if(me.detallesUsuario[i]._idproyecto==me.proyecto._id){
                              me.sw=true;
                          }
                      }
                return me.sw;


                },    
         selectProyectos(){
              let me=this;
              let header={"Token" : this.$store.state.token};
              let configuracion= {headers : header};
              let proyectosArray=[];
                  
              axios.get('proyecto/listactivos',configuracion).then(function (response){
                  proyectosArray=response.data;
                 
                  proyectosArray.map(function(x){
                      me.proyectos.push({text:x.nombreentidadproyecto.substr(0,80) + ' ... / ' + x.responsable, value:x._id});
                      
                  });
              }).catch(function(error){
                  console.log(error);
              });
          },
             buscarProyecto(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};     
                axios.get('proyecto/list?valor='+me.nombreentidadproyecto,configuracion).then(function (response){
                    
                    if (response.data.length == 0) {
                       //me.noencontradoArticulo=" error proyecto"    
                       console.log("error");                  
                    }
                      else {
                          //me._id=response.data._idproyecto;
                      console.log("encontrado");
                      //console.log(response.data[0]._id);
                      me.detalles.push({
                           _idproyecto:response.data[0]._id,
                           nombreentidadproyecto:response.data[0].nombreentidadproyecto,

                      }); 
                        
                        //console.log(me.detalles[0]._idproyecto);
                        axios.post('usuarios/add',
                    {
                        'apellidos':me.apellidos,
                        'nombre':me.nombre,
                        'email':me.email,
                        'rol':'Usuario',                        
                        'detalles':me.detalles[0]
                    },configuracion)
                    .then(function(response){
                      
                       me.guardarsolicitud(me.email);
                       
                    })
                     .then(data => {
                       
                          })
                          .catch(function(error){
                              console.log(error);
                          }); 
                      
                            }
                            
                          
                      }).catch(function(error){
                          console.log(error);
                      });
                  },
             listarDetallesUsuario(id){
                      let me=this;
                      let header={"Token" : this.$store.state.token};
                      let configuracion= {headers : header}; 
                      console.log(id);
                      let proyectosArray=[];
                      me.proyectosus=[];          
                      axios.get('usuarios/query?_id='+id,configuracion).then(function (response){
                          me.detallesUsuario=response.data.detalles;                                                                                       
                          proyectosArray=me.detallesUsuario;                       
                                             
                          console.log(proyectosArray);
                         
                          proyectosArray.map(function(x){
                              me.proyectosus.push({text:x.nombreentidadproyecto.substr(0,100) + ' ...  ',  value:x._idproyecto});
                      });
                          
                      }).catch(function(error){
                          console.log(error);
                      });
                     
                        },
                
                    
         listarProyecto(id){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};              
                    axios.get('proyecto/query?_id='+id,configuracion).then(function (response){
                        me._idproyecto=response.data._id; 
                        me.nombreentidadproyecto=response.data.nombreentidadproyecto;
                        me._idproyecton=''; 
                                                         
                        me.verNuevo=0;
                         
                    }).catch(function(error){
                        console.log(error);
                    });
            },
            listarnoProyecto(id){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                    console.log(id);          
                    axios.get('proyecto/query?_id='+id,configuracion).then(function (response){
                        me._idproyecto=response.data._id; 
                        me.proyecto=response.data;
                        me.nombreentidadproyecto=response.data.nombreentidadproyecto;                                          
                        me.verNuevo=0;
                        
                    }).catch(function(error){
                        console.log(error);
                    });
            }, 
            listarTodosProyectos(id){
               
               let me=this;
               let header={"Token" : this.$store.state.token};
               let configuracion= {headers : header};
               me.validaMensajeP=[];
               me.validaP=0;
               
               //me.valida=0;
               me.sw=false;
               console.log(me.proyecto);  
               axios.get('proyecto/query?_id='+id,configuracion).then(function (response){
                  me._idproyecto=response.data._id;
                  console.log(me._idproyecto);
                  me.nombreentidadproyecto = response.data.nombreentidadproyecto;
                  me.responsable = response.data.responsable;
                  me.vigencia = response.data.vigencia;
                  me.financiador = response.data.financiador;  
                  me.objetivos = response.data.objetivos;                   
                //  me.proyecto=response.data;             
                                       
                                     
                  me.veranadirpusuario=1;                  
                  me.verNuevo=0;          
              }).catch(function(error){
                  console.log(error);
              });
          },
            
            calculanumHoja(a,b) {
                return Math.round(Math.random()*(b-a)+parseInt(a));
            },
           encuentraNumSolicitud(){
           
              let me=this;
              let sw=0;
              let sw1=true;              
              
              while (sw1==true) {  
                 sw=0;
                for (var i=0;i<this.solicitudes.length;i++){
                    if(this.solicitudes[i].num_solicitud==this.numsolicitud){
                        sw=true;
                    }
                }
                if (sw==true){
                    this.numsolicitud=this.calculanumHoja(1,10000000);
                } else {
                    sw1=false;
                }
            }
                
                return this.numsolicitud;
               
            },
            guardarPusuario() {
                  
                  let me=this;
                  
                    
                   me.sw=false;
                   console.log(me._idproyecto);
                   console.log(me.sw);
                   console.log(me.detallesUsuario);
                   me.buscarProUs(me._idproyecto);
                     console.log(me.sw);
                       if (me.sw) {
                        if(me.detallesUsuario.length >=1) {
                         console.log('proyecto duplicado');
                         me.validaMensajeP=['El proyecto elegido ya se encuentra en su lista de proyectos. Proyecto duplicado'];
                         me.validaP=1; 
                         me.veranadirpusuario=0;
                         
                         return;} else {
                            me.annadirpus();
                          }
                       }else{     
                        me.annadirpus();
                      }
                },
                buscarProUs(){
                  let me=this;
                   me.sw=false;
                  if  (me.detallesUsuario.length >=1){
                   console.log(me.detallesUsuario[0]._idproyecto);
                   console.log(me._idproyecto);
                      for (var i=0;i<me.detallesUsuario.length;i++){
                          if(me.detallesUsuario[i]._idproyecto==me._idproyecto){
                              me.sw=true;
                          }
                      
                        }
                    } else {
                        me.sw=true;

                    }
                    return me.sw;

                },    
                annadirpus(){
                    let me=this;
                    let header={"Token" : this.$store.state.token};
                    let configuracion= {headers : header};
                    me.detalles.push(
                          {
                              _idproyecto:me._idproyecto, 
                              nombreentidadproyecto:me.nombreentidadproyecto
     
                          });     
                                            
                         
                        
                         
                          axios.put('usuarios/updatep',{
                      
                          '_id':me._idusuario, 
                          'password':this.password,                    
                          'detalles': me.detalles,                
                        
                          },configuracion).then(function(response){
                             
                             me.listarDetallesUsuario(me._idusuario);                           
                            // me.listarProyecto(me.proyecto._id);
                            me.listarProyecto(me._idproyecto);
                             
                               me.validaMensajeP=['El proyecto se añadió a la lista de proyectos del usuario. Ya puede hacer la solicitud con este proyecto.'];
                               me.validaP=1;
                               me.veranadirpusuario=0;
                              
                               me.pp=1;
                               
                          
                      })
                      .catch(function(error){
                          console.log(error);
                      });
                },
            guardarsolicitud(){
                
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
               // me.buscarmail();
               if (me.sonanillas==1) {
                  me.firma_responsable=1;
                  me.nomresponsable=this.$store.state.usuario; 
               } else {
                me.firma_responsable=0;
                me.nomresponsable=null; 
                      
            }
               
                me.numsolicitud=this.calculanumHoja(1,10000000);
                me.numsolicitud=this.encuentraNumSolicitud();            
                 axios.post('solicitud/add',
                    {
                        'num_solicitud':me.numsolicitud, 
                        'usuario':me._idusuario,
                        'proyecto':me._idproyecto,                        
                        'servicioicts':me.servicioicts,
                        'tipouso':me.tipouso,
                        'detallesanillas':me.detallesAnillas,
                        'descripcion':me.descripcion,
                        'firma_responsable': me.firma_responsable,
                        'nomresponsable':me.nomresponsable                       
                        
                    },configuracion)
                    
                    .then(function(response){  
                         
                        me.obtenerpro(me._idproyecto);
                                              
                        setTimeout(() => {
                           
                            me.enviaravisoServicio();
                      
                                   
                       }, 4000); 
                     
                      
                         
                    }) .then(data => {
                        me.validaMensaje=['La solicitud ha quedado registrada en el sistema.'];
                         me.valida=1;   
                         this.verProyectos=0;                 
                         me.valid=false;
                         me.sonanillas=0;
                         me.nomresponsable=null;
                         me.firma_responsable=0;
                         me.obtenerusuario();  
                             if (this.rol=="Usuario"){
                                    this.listarporUsuarios(this._idusuario);  
                                } else {
                                    this.esIp=1;
                                    // this.listarporProyectos();  
                                    this.listarporProy(this.detalles);                              
                                    
                                }        
                        // me.listarporUsuarios(this._idusuario);                        
                         
                           me.dialogM=1;
                           setTimeout(() => {
                    this.$refs.form.reset();
                     
                }, 4000); 
                   
                    })
                     
                    .catch(function(error){
                        console.log(error);
                        me.validaMensaje=['Se ha producido un erro al registrar la solicitud: ' + error];
                        me.valida=1;
                    });
               
            }, 
            obtenerpro(id){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};          
                axios.get('proyecto/query?_id='+id,configuracion).then(function (response){
                   
                   
                    me.proyecto=response.data; 
                    me.mailpro=response.data.email;                     
                    console.log(me.mailpro);       
                }).catch(function(error){
                    console.log(error);
                });
            },
            enviaravisoServicio(){
                
                  
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                let para='';
                
               

                 if (me.servicioicts=='Colecciones'){
                  me.para='colecciones@ebd.csic.es';
                } 
                if (me.servicioicts=='Anillamiento'){
                 me.para='anillamiento@ebd.csic.es';
                } 
                if (me.servicioicts=='RBD/END'){
                  me.para='guyonne@ebd.csic.es';
                } 
                if (me.servicioicts=='Apoyo_Tecnico'){
                    me.para='jbustamante@ebd.csic.es';
                } 
                if (me.servicioicts=='Datos_ICTS'){
                    me.para='antonio.martinez@ebd.csic.es';
                } 
                if (me.servicioicts=='TIC'){
                  me.para='joaquin.cobos@ebd.csic.es';
                } 
               
                    
                axios.post('solicitud/sendmailS',{                    
                                           
                                                           
                    'num_solicitud':me.numsolicitud, 
                    'apellidos':me.apellidos,                   
                    'nombre':me.nombre, 
                    'proyecto': me.nombreentidadproyecto,    
                    'email':me.mailpro, 
                    'mail':me.email,             
                    'servicioicts':me.para,
                    'descripcion':me.descripcion          
                          
                     
                  }                
                  ,configuracion).then(function (response){
                    setTimeout(() => {
                            me.$refs.form.reset();
                            
                        }, 3000);
                      
                  }).catch(function(error){
                     
                      console.log(error);
                  });
                
                
              },    
            actualizar(){
                
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
               // me.buscarmail();
               
                 axios.put('solicitud/update',
                    {
                        '_id':me._id,                       
                        'proyecto':me.proyecto, 
                        'servicioicts':me.servicioicts,
                        'tipouso':me.tipouso,
                        'firma_responsable':me.firma_responsable,
                        'firma_tecnico':me.firma_tecnico,  
                        'nomresponsable':me.nomresponsable,
                        'nomtecnico':me.nomtecnico,
                        'descripcion':me.descripcion                       
                        
                    },configuracion)
                    .then(function(response){
                      
                      
                    })
                     .then(data => {
                     
                       me.validaMensaje=['La solicitud se modificó correctamente.'];
                       me.valida=1;
                       me.verActualizar=0;
                       me.obtenerusuario();          
                       if (this.rol=="Usuario"){
                            this.listarporUsuarios(this._idusuario);  
                        } else {
                            this.esIp=1;
                            // this.listarporProyectos();  
                            this.listarporProy(this.detalles);                              
                            
                        }    
                       // me.listarporUsuarios(this._idusuario); 
                        
                        me.dialogM=1;
                      
                        setTimeout(() => {
                            this.$refs.form.reset();
                            
                        }, 3000);
                                    
                      
                      
                      })
                    .catch(function(error){
                        console.log(error);
                        this.validaMensaje=['Se ha producido un error al guardar la solicitud: ' + error];
                        this.valida=1;
                        
                    });
               
            },
         
            mostrarNuevo(){  
                let me=this;   
                
                me.usuario=this.$store.state.usuario._id;  
                                                     
                this.verNuevo=1;
            },
           
           
           
            validar(){
                this.valida=0;
                this.validaMensaje=[];
                             
                if(this.nombreentidadproyecto.length<1){
                    this.validaMensaje.push('Debe de indicar un nombre para el proyecto.');
                }
                if(this.responsable.length<1){
                    this.validaMensaje.push('Debe de indicar un responsable.');
                }
                if(this.vigencia.length<1){
                    this.validaMensaje.push('Debe de indicar la vigencia del proyecto.');
                }
                 if(this.financiador.length<1){
                    this.validaMensaje.push('Debe de indicar un financiador del proyecto.');
                }
                return this.valida;
            },
             validarA(){
                this.validaA=0;
                this.validaMensajeA=[];
                             
               /*  if(this.especie.length<1){
                    this.validaMensaje.push('Debe de indicar un nombre para la especie.');
                } */
              if (this.tipo_anilla == 'Metal') {
                if(this.pedido.length<1){
                    this.validaMensajeA.push('Debe de indicar un pedido.');
                }
                 if(this.datos_envio.length<1){
                    this.validaMensajeA.push('Debe de indicar los datos de envío o donde recogerlas.');
                }
               }
               if (this.tipo_anilla == 'PVC') {
                if(this.pedido.length<1){
                    this.validaMensajeA.push('Debe de indicar un pedido.');
                }
                 if(this.datos_envio.length<1){
                    this.validaMensajeA.push('Debe de indicar los datos de envío.');
                }
                if(this.datos_facturacion.length<1 && this.facturacion){
                    this.validaMensajeA.push('Debe de indicar los datos de facturación.');
                }
               }
                if (this.validaMensajeA.length){
                    this.validaA=1;
                }
                return this.validaA;
            },
            guardar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                
                
                if (this.validar()){
                    return;
                }
                if (this.editedIndex >-1){
                    //Código para editar
                    axios.put('proyecto/update',
                    {
                        '_id':this._id,
                        'nombreentidadproyecto':this.nombreentidadproyecto,
                        'responsable':this.responsable,
                        'financiador':this.financiador,                                                
                        'vigencia':this.vigencia,
                        'resumen':this.resumen,
                        'objetivos':this.objetivos

                    },configuracion)
                    .then(function(response){
                        me.limpiar();
                        me.close();
                        me.listar();
                    })
                    .catch(function(error){
                        console.log(error);
                    });
                }else{
                    //Código para guardar
                    axios.post('proyecto/add',
                    {
                        'nombreentidadproyecto':this.nombreentidadproyecto,
                        'responsable':this.responsable,
                        'financiador':this.financiador,                                                
                        'vigencia':this.vigencia,
                        'resumen':this.resumen,
                        'objetivos':this.objetivos
                    },configuracion)
                    .then(function(response){
                        me.limpiar();
                        me.close();
                        me.listar();
                    })
                    .catch(function(error){
                        me.validaMensaje.push('Ya existe un proyecto con ese mismo nombre. ');
                        
                        me.valida=1;
                        return me.valida;
                        console.log(error);
                    });
                }
            },
            editItem (item) {
                this._id=item._id;
                this.createdAt=moment(item.createdAt).local().format('DD/MM/YYYY, h:mm a');                                  
                this.proyecto=item.proyecto._id; 
                if (this.proyecto=='60c88317e86ff200901dc1ee') { 
                    this.verProyectos=1;
                    
                }

              //  this.usuario=item.usuario;                        
                this.apellidos=item.apellidos;
                this.nombre=item.nombre;                
                this.servicioicts=item.servicioicts;
                this.descripcion=item.descripcion;
                this.tipouso=item.tipouso;
                this.num_solicitud=item.num_solicitud;
                this.firma_responsable=item.firma_responsable;
                this.firma_tecnico=item.firma_tecnico;
                this.nomresponsable=item.nomresponsable;
                this.nomtecnico=item.nomtecnico;  
                this.verActualizar=0;  
                if (item.estado !=3) {  
                this.verActualizar=1; }            
                this.dialogeditar=1;
                
               
            },
            responderUsuario (item) {

                this._id=item._id;
                this.respuesta='';
                this.createdAt=moment(item.createdAt).local().format('DD/MM/YYYY, h:mm a'); 
                this.apellidos=item.usuario.apellidos;
                this.nombre=item.usuario.nombre; 
                this.correo=item.usuario.email;  
                this.nomresponsable=item.nomresponsable;
                this.servicioicts=item.servicioicts;
                this.nomtecnico=item.nomtecnico;   
                this.descripcion=item.descripcion;
                this.num_solicitud=item.num_solicitud; 
                this.historico=item.historico
                this.verResponder=1;             
                this.dialogresponder=1; 

                },
                responderTecnico (item) {

                    this._id=item._id;
                    this.respuesta='';
                    this.createdAt=moment(item.createdAt).local().format('DD/MM/YYYY, h:mm a'); 
                    this.apellidos=item.usuario.apellidos;
                    this.nombre=item.usuario.nombre; 
                    this.correo=item.nomtecnico.email;  
                    this.nomresponsable=item.nomresponsable;
                    this.servicioicts=item.servicioicts;
                    this.nomtecnico=item.nomtecnico;   
                    this.descripcion=item.descripcion;
                    this.num_solicitud=item.num_solicitud; 
                    this.historico=item.historico
                    this.verResponder=1;             
                    this.dialogresponder=1; 

                    },

                mostrardoc(){
                let me=this; 

              //  me.deletedoc();
               
                me.dialogdoc=1;
                
            },
            responder() {
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                let nomfich='';
                let actor='';
                let fechahoy= new Date();
                let now = fechahoy.toLocaleDateString('es-ES');
                actor=  'usuario que responde: ' + this.$store.state.usuario.apellidos + ',  ' + this.$store.state.usuario.nombre;
                me.historico = me.historico  + '----------------------------------------------------------------------------------------' + '\n' + me.respuesta + ', Fecha: ' + now + '\n'
                me.historico= me.historico + actor + '\n';
                me.historico=me.historico + 'se ha enviado el correo: ' + me.correo + '\n';
                    if (me.servicioicts=='Colecciones'){
                        me.para='colecciones@ebd.csic.es';
                    } 
                    if (me.servicioicts=='Anillamiento'){
                        me.para='anillamiento@ebd.csic.es';
                    } 
                    if (me.servicioicts=='RBD/END'){
                        me.para='guyonne@ebd.csic.es';
                    } 
                    if (me.servicioicts=='Apoyo_Tecnico'){
                        me.para='jbustamante@ebd.csic.es';
                    } 
                    if (me.servicioicts=='Datos_ICTS'){
                        me.para='antonio.martinez@ebd.csic.es';
                    } 
                    if (me.servicioicts=='TIC'){
                        me.para='joaquin.cobos@ebd.csic.es';
                    } 
                    if (me.nomfichero=='') {
                        me.nomfich='Sin-adjunto';
                    }else {
                        me.nomfich=me.nomfichero; 
                        me.historico = me.historico + 'se adjuntó el siguiente fichero: ' + me.nomfich + '\n';
                        }
                    if (me.nomtecnico==null) {
                        me.nomtecnico=me.$store.state.usuario;
                    }
              
                axios.post('solicitud/sendmailResp',{                    
                                         
                              
                    'num_solicitud':me.num_solicitud, 
                    'apellidos':me.apellidos,                   
                    'nombre':me.nombre,   
                    'mail':me.correo,    
                    'para':me.para,      
                    'descripcion':me.descripcion,
                    'tecnico':me.nomtecnico,
                    'nomfichero':me.nomfich,
                    'respuesta':me.respuesta         
                            
                
                }                
                ,configuracion).then(function (response){
                  
                   
                })
                     .then(data => {
                      //  axios.put('solicitud/respenviada',{'_id':me._id})
                      axios.put('solicitud/respenviada',{'_id':me._id,  'historico':me.historico})
                  
                    .then(function(response){
                                  
                                
                    },configuracion)
                    .catch(function(error){
                        console.log(error);
                    });
                       me.validaMensaje=['Se envío la respuesta correctamente a: ' + me.apellidos + ', ' + me.nombre];
                       me.valida=1;
                       me.verResponder=0;
                       if (me.rol=="Usuario"){
                            me.listarporUsuarios(this._idusuario);  
                        } else {
                            me.esIp=1;
                            // this.listarporProyectos();  
                            me.listarporProy(this.detalles);                              
                            
                        }  
                 
                       // me.listar();
                       me.dialogM=1;
                      
                       
                }).catch(function(error){
                
                    console.log(error);
                });
                  

            },
        
           onFileChange(e) {
               
              //  const selectedFile = e.target.files[0]; // accessing file
             //   this.selectedFile = selectedFile;
                 // si el fichero es mayor que 3 MB
                 if (this.selectedFile.size>3097152) {
                  this.enviadodoc=2;
                  
                  this.validarDoc();
                  this.deletedoc();
                 }else{
                   this.validaDoc=[];
                  
                 

                } 
                
            },
            deletedoc(){
                document.getElementById("uploads").value=null; 
                this.selectedFile=null;
               // this.enviadodoc=0;
               
                },

             onUploadFile() {    
               let me=this;
              
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};  
                const formData = new FormData();
                formData.append("file", this.selectedFile);  // appending file
                console.log(this.selectedFile);
               
              //  me.enlacepdf = this.selectedFile.name;
                axios.post('upload/upload', formData, configuracion).then(function (response){
                   console.log('SUCCESS!!');
                   me.nomfichero=me.selectedFile.name;
                   me.selectedFile=null;                  
                   me.enviadodoc=1;
                   me.validarDoc();
                  
                   
                   //me.closedialogdoc();          
               }).catch(function(error){
                    me.enviadodoc=0;
                    me.selectedFile=null;
                    me.validarDoc();
                    console.log(error);
                    console.error(error);
                });
            },
            validarDoc(){
                this.valida=0;
                this.validaDoc=[];
              
               if(this.enviadodoc==1){
                    this.validaDoc.push('El documento se ha adjuntado correctamente');
                } 
                else if(this.enviadodoc==2){this.validaDoc.push('El tamaño del fichero no puede ser mayor de 3 MB.') } else{this.validaDoc.push('Ha habido un problema. El documento no se ha adjuntado correctamente.')}
                if (this.validaDoc.length){
                    this.valida=1;
                }
               
                return this.valida;
            },
           closedialogdoc () {
                //this.dialog = false;
               document.getElementById("uploads").value=null; 
              this.selectedFile=null;
               this.validaDoc=[];
               
              // this.enviadodoc=0;
               this.dialogdoc = 0;
               

            },

            changeTipo() {
                this.pedido='';
                this.modelo='';
                this.especie='';
                this.tipo_PVC='';
                this.datos_envio='';
                this.datos_facturacion='';
                this.detallesAnillas=[];
                this.cantidad=0;

            },

            changeState(id) {
                console.log("Switch "+id);
                // actualizar en solicitudes el nombre que esta firmando.
                if (this.firma_responsable){
                   this.nomresponsable=this.$store.state.usuario;
                }else{
                    this.nomresponsable=null; 
                    this.nomtecnico=null;
                    this.firma_tecnico=0;  
                }
                console.log(this.nomresponsable);

            },
          
            activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adNombre=item.nombreentidadproyecto;
                this.adId=item._id;
                if (accion==1){
                    this.adAccion=1;
                } else if(accion==2){
                    this.adAccion=2;
                } else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
            },
            activar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                
               
                axios.put('solicitud/activate',{'_id':this.adId},configuracion)
                    .then(function(response){
                       
                        me.adModal=0;
                        me.adAccion=0;
                        me.adNombre='';
                        me.adId='';
                        me.obtenerusuario();   
                        console.log(me._idusuario);      
                       // me.listarporUsuarios(me._idusuario);  
                        //me.listar();
                        if (me.rol=="Usuario"){
                            me.listarporUsuarios(me._idusuario);  
                        } else {
                            me.esIp=1;
                            // this.listarporProyectos();  
                            me.listarporProy(me.detalles);                              
                            
                        }     
         
                      
                    },configuracion)
                    .catch(function(error){
                        console.log(error);
                    });
            },
            desactivar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                
                axios.put('solicitud/deactivate',{'_id':this.adId},configuracion)
                    .then(function(response){
                       
                        me.adModal=0;
                        me.adAccion=0;
                        me.adNombre='';
                        me.adId='';
                        me.obtenerusuario();         
                        // me.listarporUsuarios(me._idusuario); 
                        if (me.rol=="Usuario"){
                            me.listarporUsuarios(me._idusuario);  
                        } else {
                            me.esIp=1;
                            // this.listarporProyectos();  
                            me.listarporProy(me.detalles);                              
                            
                        }     
         
                        
                       // me.listar();
                    })
                    .catch(function(error){
                        console.log(error);
                    });
            },
            closeS () {
             
              /*   setTimeout(() => {
                 this.limpiar();
                }, 4000); */
                 this.dialogM=0;
                 this.dialogMA=0;
                // this.dialogonueva=0;
                    this.dialogeditar=0;
                    this.validaMensaje=[];
                this.validaMensajeP=[];
                this.dialogresponder=0;
               
                this.enviadodoc=0;
                this.nomfichero='';
                this.adModalAnillas=0; 
                this.facturacion=0;
                this.step=1;
               this.dialogonueva=0;  
               
                 setTimeout(() => {
                    this.$refs.form1.reset();
                   
                }, 3000); 
                setTimeout(() => {
                    this.$refs.form2.reset();
                   
                }, 3000); 
               
              
                // this.dialogonueva=0;  
               // this.dialogeditar=0;
                
               
               
             //   this.dialogonueva=0;  
               // this.valid=false;
              
               
               
               
            },
            closeSA () {
             
             /*   setTimeout(() => {
                this.limpiar();
               }, 4000); */
                this.dialogM=0;
                this.dialogMA=0;
               // this.dialogonueva=0;
               this.dialogeditar=0;
               this.validaMensaje=[];
               this.validaMensajeP=[];
               this.dialogresponder=0;
              
               this.enviadodoc=0;
               this.nomfichero='';
               this.adModalAnillas=0; 
               this.facturacion=0;
             // this.dialogonueva=0;  
              
              /*  setTimeout(() => {
                   this.$refs.form.reset();
                    
               }, 4000); */
               
              
             
               // this.dialogonueva=0;  
              // this.dialogeditar=0;
               
              
              
            //   this.dialogonueva=0;  
              // this.valid=false;
             
              
              
              
           },
            closeSS () {
                /*  setTimeout(() => {
                    
                }, 3000); */
              //  this.$refs.form.reset();
               this.limpiar();
               this.validaMensaje=[];
               this.validaMensajeP=[];
               this.checkbox1=false;
               this.verProyectos=0;
               
              // this.dialogM=0;
               this.dialogeditar=0;
               if (this.detallesAnillas.length==0) {
              
               this.dialogonueva=0;  
               }

              // this.valid=false;
             
              
              
              
           },
           guardarDescripcion(){ 

            if (this.validarA()){
                    return;
                }

            this.descripcion='Petición de anillas '  +  '\n';
            this.descripcion=this.descripcion + 'Tipo de anillas: ' + this.tipo_anilla +  '\n';
            this.descripcion= this.descripcion + this.pedido + '\n';
            this.descripcion= this.descripcion + 'Datos de envío: ' + '\n';
            this.descripcion= this.descripcion + this.datos_envio + '\n';
            if (this.tipo_anilla == 'PVC') {
                this.descripcion= this.descripcion + 'Datos de facturación: ' + '\n';
            this.descripcion= this.descripcion + this.datos_facturacion; 
            }
            this.pedido='';
            this.cantidad=0;     
            this.datos_envio='';
            this.datos_facturacion='';          
            this.adModalAnillas=0; 
            this.validaMensajeA=[]; 
            this.validaMensaje=['Se añadió el pedido de anillas correctamente. Pulse en enviar solicitud para terminar. '];
            this.sonanillas=1; 
            this.valida=1;   
            this.dialogMA=1;
           
           },
           
           limpiarAnillas() {

            this.modelo='';
            this.especie='';
            this.pedido='';
            this.datos_envio='';
            this.datos_facturacion='';
            this.cantidad=0;
            this.detallesAnillas=[];

           },
            closeP() {
              this.adModalPro=0; 
              this.validaMensajeP=''   
              this.limpiarProyecto();                     
            
                        
                              
               
            },
             closeA() {
              this.adModalAnillas=0; 
              this.validaMensajeA=[]; 
              this.pedido='';
              this.detallesAnillas=[];
              this.cantidad=0;  
              this.facturacion=0;              
              this.limpiarAnillas();                   
                                  
                             
               
            },
           
            close () {                
                   // this.dialogM=0;
                    // this.dialogeditar=0;
               /*      setTimeout(() => {
                    this.$refs.form.reset();
                }, 3000); */
                    this.checkbox1=false;
                    
                   // this.$refs.form2.reset();
                    this.limpiarAnillas();  
                    this.facturacion=0;
                    this.actividad='';
                    this.password='';
                    this.verProyectos=0;
                    this.dialogonueva=0;  
                    this.dialogeditar=0;
                    this.step=1;
                    this.$refs.form1.reset();
                    this.$refs.form2.reset();
                   // this.limpiar();
            
                          
            }
        }
    }
</script>
<style>
    .box{
       position:relative;
       top: 0px;
       border: 1px solid green;
       padding: 2%;
       width: 600px;    
       left: 28%;
       margin-left: -100px;
    }
    </style>