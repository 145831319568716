import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Home from '../views/Home.vue'
import Solicitud from '../components/Solicitudnueva.vue'
import Registrado from '../components/Registrado.vue'
import Colecciones from '../components/Colecciones.vue'
import Anillamiento from '../components/Anillamiento.vue'
import Apoyo from '../components/Apoyo.vue'
import Icts from '../components/Icts.vue'
import Rbd from '../components/Rbd.vue'
import Tic from '../components/Tic.vue'
import Proyectos from '../components/Proyectos.vue'
import Usuarios from '../components/Usuarios.vue'
import Solicitudes from '../components/Solicitudes.vue'
import SolicitudesAnillas from '../components/SolicitudesAnillas.vue'
import SolicitudesUsuario from '../components/SolicitudesUsuario.vue'
import ProyectoUsuario from '../components/ProyectoUsuario.vue'
import MisProyectos from '../components/MisProyectos.vue'
import Manual from '../components/Manual.vue'
import Miperfil from '../components/Miperfil.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      libre: true, 
      estado: false   
    }
  },
  {
    path: '/registrado',
    name: 'registrado',
    component: Registrado,
    meta: {
      libre: true, 
      estado: false    
    }
  },
  {
    path: '/solicitud',
    name: 'solicitud',
    component: Solicitud,
    meta: {
      libre: true, 
      estado: false    
    }    
  },
  {
    path: '/proyectos',
    name: 'proyectos',
    component: Proyectos,
    meta: {
      administrador: true, 
      estado: true    
    }    
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: Usuarios,
    meta: {
      administrador: true, 
      estado: true    
    }    
  },
  {
    path: '/solicitudes',
    name: 'solicitudes',
    component: Solicitudes,
    meta: {
      administrador: true, 
      estado: true    
    }    
  },
  {
    path: '/solicitudesanillas',
    name: 'solicitudesanillas',
    component: SolicitudesAnillas,
    meta: {
      administrador: true, 
      estado: true    
    }    
  },
  {
    path: '/solicitudesusuario',
    name: 'solicitudesusuario',
    component: SolicitudesUsuario,
    meta: {
      administrador: false, 
      estado: true    
    }    
  },
  {
    path: '/misproyectos',
    name: 'misproyectos',
    component: MisProyectos,
    meta: {
      administrador: false, 
      estado: true    
    }    
  },
  {
    path: '/proyectousuario',
    name: 'proyectousuario',
    component: ProyectoUsuario,
    meta: {
      administrador: false, 
      estado: true    
    }    
  },
  {
    path: '/colecciones',
    name: 'colecciones',
    component: Colecciones,
    meta: {
      usuario:true,
      estado: true     
    }
  },
  {
    path: '/anillamiento',
    name: 'anillamiento',
    component: Anillamiento,
    meta: {
      usuario:true,
      estado: true      
    }
  },
  {
    path: '/rbd',
    name: 'rbd',
    component: Rbd,
    meta: {
      usuario:true,
      estado: true    
    }
  },
  {
    path: '/apoyo',
    name: 'apoyo',
    component: Apoyo,
    meta: {
      usuario:true,
      estado: true     
    }
  },
  {
    path: '/icts',
    name: 'icts',
    component: Icts,
    meta: {
      usuario:true,
      estado: true      
    }
  },
  {
    path: '/tic',
    name: 'tic',
    component: Tic,
    meta: {
      usuario:true,
      estado: true     
      
    }
  },
  {
    path: '/manual',
    name: 'manual',
    component: Manual,
    meta: {
      usuario:true,
      estado: true     
      
    }
  },
  {
    path: '/miperfil',
    name: 'miperfil',
    component: Miperfil,
    meta: {
      usuario:true,
      estado: true  
    }
    },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.libre)){
    next()
     }else if (store.state.usuario !=null) {
        if (to.matched.some(record => record.meta.estado)) {         
       next()
      }  
    }else if (store.state.usuario && store.state.usuario.rol == 'Administrador') {
      if (to.matched.some(record => record.meta.administrador)) {         
     next()
    }    
    }else if (store.state.usuario && store.state.usuario.rol == 'Usuario') {
     if (to.matched.some(record => record.meta.usuario)) {
          next()
    }  
    }
     else {
       next({
         name: 'home'
      })
    }
  })
export default router
