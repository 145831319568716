<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar text color="white">
                <v-toolbar-title>Mi Perfil - Cambiar mi contraseña</v-toolbar-title>
                <v-divider
                class="mx-2"
                inset
                vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-text-field class="text-xs-center" v-model="search" append-icon="search" 
                label="Búsqueda" single-line hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="700px">
                   <!--  <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark class="mb-2" v-on="on">Nuevo</v-btn>
                    </template> -->
                    <v-card>
                        <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                        </v-card-title>            
                        <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>
                               <!--  <v-flex xs12 sm6 md6>
                                    <v-text-field v-model="nombre" label="Nombre" disabled="true">                                        
                                    </v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md6>
                                    <v-select v-model="rol"
                                    :items="roles" label="Rol" disabled="true">
                                    </v-select>
                                </v-flex>   
                                                              
                                <v-flex xs12 sm6 md6>
                                    <v-text-field v-model="num_documento" label="Número Documento" disabled="true">
                                    </v-text-field>
                                </v-flex>                               
                                <v-flex xs12 sm6 md6>
                                    <v-text-field v-model="telefono" label="Teléfono" disabled="true">
                                    </v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md6>
                                    <v-text-field v-model="email" label="Correo electrónico" disabled="true">
                                    </v-text-field>
                                </v-flex> -->
                                <v-flex xs12 sm12 md12>
                                    <v-text-field type="password" v-model="password" label="Contraseña">
                                    </v-text-field>
                                </v-flex>
                               <v-flex xs12 sm12 md12>
                                    <v-text-field type="password" v-model="repeatpassword" label="Repetir contraseña">
                                    </v-text-field>
                                </v-flex>
                                <!--  <v-flex xs12 sm12 md12>
                                     <v-select 
                                        :items="proyectos"                         
                                        v-model="proyecto" 
                                        label="Seleccione un Proyecto"                        
                                        @change="listarProyecto(proyecto)" 
                                        solo                                         
                                        >
                                     </v-select>
                                </v-flex>  -->
                                <v-flex xs12 sm12 md12 v-show="valida">
                                    <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">

                                    </div>
                                </v-flex>
                                 
                          <!-- <v-flex xs12 sm12 md12 lg12 x12>
                            <v-data-table 
                                :headers="cabeceraDetalles"
                                :items="detalles"
                                hide-default-footer
                                class="elevation-1"
                            >
                              
                                <template v-slot:body="{ items }" >
                                    <tbody>
                                        <tr v-for="item in items" :key="item.nombre"> 
                                            <td>
                                           <v-icon
                                            small
                                            class="mr-2"
                                            @click="eliminarDetalle(detalles,item)"
                                            >
                                            mdi-delete
                                            </v-icon>
                                        </td>
                                            <td class="text-xs-center">{{ item.nombreproyecto }}</td>
                                            
                                        </tr>

                                   </tbody> 
                              
                                </template>
                                <template slot="no-data">
                                    <h3>No hay proyectos agregados al detalle.</h3>
                                </template>
                                               
                           
                            </v-data-table>
                            </v-flex> -->
                            </v-layout>
                        </v-container>
                        </v-card-text>            
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                        <v-btn color="blue darken-1" text @click="guardar">Guardar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="adModal" max-width="290">
                    <v-card>
                        <v-card-title class="headline" v-if="adAccion==1">
                            Activar Item
                        </v-card-title>
                        <v-card-title class="headline" v-if="adAccion==2">
                            Desactivar Item
                        </v-card-title>
                        <v-card-text>
                            Estás a punto de <span v-if="adAccion==1">activar </span>
                            <span v-if="adAccion==2">desactivar </span> el item {{adNombre}}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="activarDesactivarCerrar()" color="green darken-1" text="text">
                                Cancelar
                            </v-btn>
                            <v-btn v-if="adAccion==1" @click="activar()" color="orange darken-4" text="text">
                                Activar
                            </v-btn>
                            <v-btn v-if="adAccion==2" @click="desactivar()" color="orange darken-4" text="text">
                                Desactivar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogM"  width="350" >
                                   
        
                                   <v-card>
                                   <v-card-title class="text-h8 green lighten-2">
                                       Aviso 
                                   </v-card-title>
                           
                                   <v-card-text>
                                       <v-flex xs12 sm12 md12 v-show="valida">
                                           <div  v-for="v in validaMensaje" :key="v" v-text="v">
               
                                           </div>
                                       </v-flex>   
                                   </v-card-text>
                                   <v-card-actions>
                                       <v-spacer></v-spacer>
                                       <v-btn
                                       color="primary"
                                       
                                       text
                                       @click="close()"
                                       >
                                        Cerrar
                                       </v-btn>
                                   </v-card-actions>
                               </v-card>
                               </v-dialog>
            </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="usuarios"
                :search="search"
                class="elevation-1"
            >
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-for="item in items" :key="item.nombre">   
                
                        <td>{{ item.apellidos }}</td>
                        <td>{{ item.nombre }}</td>
                        
                        <td>{{ item.email }}</td>
                       
                    <td>
                        <div v-if="item.estado == 1">
                            <span class="blue--text">Activo</span>
                        </div>
                        <div v-else>
                            <span class="red--text">Inactivo</span>
                        </div>
                    </td>
                    <td> 
                        <v-tooltip slot="append" location="top">   
                            <template v-slot:activator="{ on }">    
                        <v-icon
                        small
                        v-on="on"
                        class="mr-2"
                        @click="editItem(item)"
                        >
                        mdi-account-key
                        </v-icon>
                    </template>
                        <span>Cambiar mi contraseña</span>
                </v-tooltip>
                         </td>    
                 </tr>

                  </tbody>                 
                </template>
                <template v-slot:no-data>
                <v-btn color="primary" @click="listarDetallesUsuario(me.id)">Resetear</v-btn>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
    import axios from 'axios'
    export default {
        data(){
            return{
                dialog: false,
                search:'',
                usuarios:[],
                headers: [         
                    { text: 'Apellidos', value: 'apellidos', sortable: true },          
                    { text: 'Nombre', value: 'nombre', sortable: true },                   
                    { text: 'Email', value: 'email', sortable: false  },
                    { text: 'Estado', value: 'estado', sortable: false  },  
                    { text: 'Cambiar contraseña', value: 'opciones', sortable: false }                    

                ],
                editedIndex: -1,
                _id:'',
                id:'',
                nombre:'',
                rol:'',
                roles: ['Administrador','Investigador','Usuario','Gerente'], 
                proyecto:'',
                proyectos:[],
                cabeceraDetalles:[
                    { text: 'Proyecto', value: 'nombre', sortable: false }                    
                ], 
                detalles:[], 
                detallesUsuario:[], 
                _idproyecto:'',
                nombreproyecto:'',            
                num_documento: '',
                telefono: '',
                email: '',               
                nombre:'',
                password:'',
                repeatpassword:'',
                valida:0,
                validaMensaje:[],
                adModal:0,
                adAccion:0,
                dialogM:0,
                adNombre:'',
                adId:''
            }
        },
        computed: {
            formTitle () {
            return this.editedIndex === -1 ? 'Nuevo registro' : 'Cambiar mi contraseña'
            }
        },
        watch: {            
            dialog (val) {
            val || this.close()
            }
        },
        created () {
            let me=this;
            me.id=this.$store.state.usuario._id;
           // this.dialog = true;
           // this.editedIndex=1;
            //console.log(me.id);
            me.email=this.$store.state.usuario.email;
            me.listarporUsuarios(me.email);
           
           // this.editItem(me.detallesUsuario);
            //this.listar();
            //this.selectProyectos();
        },
        methods: {
           
          
         
          
            
          
             listarporUsuarios(email){
                
                let me=this;
                let header={"Token" : this.$store.state.token};                      
                  
                let configuracion= {headers : header};            
                axios.get('usuarios/listmail?valor='+email,configuracion).then(function (response){
                    me.usuarios=response.data; 
                   // usuarios.map(function(x){
                      //  me.usuarios.push({text:x.nombreproyecto, value:x._idproyecto});
               // });
                    // me.usuarios.push=me.usuario; 
                    console.log(me.usuarios);                 
                }).catch(function(error){
                    console.log(error);
                });
               
            }, 
           
            limpiar(){
                this._id='';
               
                this.password=''; 
                this.repeatpassword='';
                this.proyecto='';
                this.rol='';               
                this.valida=0;
                this.detalles=[],
                this.validaMensaje=[];
                this.editedIndex=-1;
            },
            validar(){
                this.valida=0;
                this.validaMensaje=[];
               
              /*   if(this.nombre.length<1 || this.nombre.length>50){
                    this.validaMensaje.push('El nombre del usuario debe tener entre 1-50 caracteres.');
                }
                if(this.num_documento.length>20){
                    this.validaMensaje.push('El documento no debe tener más de 20 caracteres.');                              
                }
                if(this.telefono.length>20){
                    this.validaMensaje.push('El teléfono no debe tener más de 20 caracteres.');
                }
                if(this.email.length<1 || this.email.length>50){
                    this.validaMensaje.push('El email del usuario debe tener entre 1-50 caracteres.');
                } */
                if(this.password.length<8 || this.password.length>64){
                    this.validaMensaje.push('El password del usuario debe tener entre 8-64 caracteres.');
                }
                if(this.password != this.repeatpassword){
                    this.validaMensaje.push('Debe de escribir la misma contraseña para que pueda ser validada.');
                }
               /*  if (!this.validEmail(this.email)) {
                     this.validaMensaje.push('El correo electrónico debe ser válido.');
                }   */
                if (this.validaMensaje.length){
                    this.valida=1;
                }
                return this.valida;
            },
             /* validEmail(email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            }, */
           
            guardar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                if (this.validar()){
                    return;
                }
                if (this.editedIndex >-1){
                    //Código para editar
                    //console.log(me.detalles);
                    axios.put('usuarios/updatecon',{
                        '_id':this._id,
                        /* 'rol':this.rol,
                        'nombre':this.nombre,
                        'num_documento':this.num_documento,
                        'direccion':this.direccion,
                        'telefono': this.telefono, */
                        // 'email':this.email,
                        'password':this.password,
                        //'detalles':this.detalles
                        
                    },configuracion)
                    .then(function(response){
                        me.limpiar();
                       // me.close();
                        me.listarporUsuarios(me.email);
                        me.validaMensaje=['La contraseña se modificó correctamente.'];
                           me.valida=1;
                            me.dialogM=1;
                    })
                    .catch(function(error){
                        console.log(error);
                    });
                }
            },
            editItem (item) {
                this._id=item._id;
                this.rol=item.rol;
                this.nombre=item.nombre;
                this.num_documento=item.num_documento;
                this.direccion=item.direccion;
                this.telefono=item.telefono;
                this.email=item.email;
                this.password='';
                this.repeatpassword='';
                this.detalles=item.detalles;
                this.dialog = true;
                this.editedIndex=1;
            },
          /*    listarDetalles(id){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};            
                axios.get('usuario/query?_id='+id,configuracion).then(function (response){
                    me.detalles=response.data.detalles;
                    console.log(me.detalles);
                }).catch(function(error){
                    console.log(error);
                });
            }, */
         /*    activarDesactivarMostrar(accion,item){
               
                this.adModal=1;
                this.email='';
                this.password='';
                this.adNombre=item.nombre;
                this.adId=item._id;
                if (accion==1){                   
                    this.adAccion=1;
                } else if(accion==2){
                    
                    this.adAccion=2;
                } else{                    
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
            }, */
         /*    activar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                axios.put('usuario/activate',{'_id':this.adId},configuracion)
                    .then(function(response){
                        me.adModal=0;
                        me.adAccion=0;
                        me.adNombre='';
                        me.adId='';
                        me.listar();
                    })
                    .catch(function(error){
                        console.log(error);
                    });
            },
            desactivar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                axios.put('usuario/deactivate',{'_id':this.adId},configuracion)
                    .then(function(response){
                        me.adModal=0;
                        me.adAccion=0;
                        me.adNombre='';
                        me.adId='';
                        me.listar();
                    })
                    .catch(function(error){
                        console.log(error);
                    });
            }, */
            close () {
                this.limpiar();
                this.dialogM=0;
                this.dialog = false;
            }
        }
    }
</script>
