import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'
import router from '../router/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:null,
    usuario: null,
    rol:''    
  },
  mutations: {
    setToken(state, token) {
      state.token = token
      },

    setUsuario(state, usuario) {
      state.usuario = usuario      
      },

    setRol(state, rol) {
        state.rol = rol     
        }
  },
  actions: {
  /*    guardarTokenU({commit}, usuario) {
           
      commit("setUsuario", usuario)      
      sessionStorage.setItem("usuario", usuario)       
    },  */
    guardarToken({commit}, token) {
      commit("setToken", token)       
      commit("setUsuario", decode(token))
      localStorage.setItem("token", token)        
    },

     guardarTokenRol({commit}, rol) {
           
      commit("setRol", rol)      
      sessionStorage.setItem("rol", rol)       
    },
   /*  autoLogin({commit}) {
      let usuario = sessionStorage.getItem("usuario");
      if(usuario) {      
       commit("setUsuario", usuario);
     }
     router.push({name: 'home'});
    }, */
    autoLogin({commit}) {
      let token = localStorage.getItem("token");
      if(token) {
       commit("setToken", token);
       commit("setUsuario", decode(token));
     }
     router.push({name: 'home'}).catch(error => {
      if (
        error.name !== 'NavigationDuplicated' &&
        !error.message.includes('Avoided redundant navigation to current location')
      ) {
        console.log(error)
      }});
    },
   /*  salir({commit}) {
      
      commit("setUsuario");
      sessionStorage.removeItem("usuario");
      router.push({name: 'home'});
    } */
    salir({commit}){
      commit("setToken", null);
      commit("setUsuario", null);
      localStorage.removeItem("token");
      router.push({name: 'home'}).catch(error => {
        if (
          error.name !== 'NavigationDuplicated' &&
          !error.message.includes('Avoided redundant navigation to current location')
        ) {
          console.log(error)
        }});
    }
  },
  modules: {
  }
})
